import i18next from 'i18next';

i18next.addResources('es', 'TypeField', {
  client: 'Cliente',
  agent: 'Agente',
  superuser: 'Super Usuario',
  cmpc: 'Coordinador'
});

i18next.addResources('en', 'TypeField', {
  client: 'Client',
  agent: 'Agent',
  superuser: 'Super User',
  cmpc: 'Coordinator'
});
