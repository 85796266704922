import i18next from 'i18next';

i18next.addResources('es', 'Order', {
  client: 'Cliente',
  items: 'Items',
  units: 'Unidades',
  weight: 'Toneladas',
  requirementDate: 'Fec Necesidad',
  entryDate: 'Fec Ingreso',
  cycle: 'Ciclo',
  commerce: 'Negocio',
  ocAgent: 'OC agente',
  stacks: 'Pilas: {{stacks}}',
  rolls: 'Rollos: {{rolls}}',
  seeMore: 'Ver más',
  seeLess: 'Ver menos',
  certification: 'Certificación',
  priceList: 'Lista de precio',
  etaWeek: 'ETA'
});

i18next.addResources('en', 'Order', {
  client: 'Client',
  items: 'Items',
  units: 'Units',
  weight: 'Tons',
  requirementDate: 'Req Date',
  entryDate: 'Date Admision',
  cycle: 'Cycle',
  commerce: 'Commerce',
  ocAgent: 'OC agent',
  stacks: 'Stacks: {{stacks}}',
  rolls: 'Rolls: {{rolls}}',
  seeMore: 'See more',
  seeLess: 'See less',
  certification: 'certification',
  priceList: 'Price list',
  etaWeek: 'ETA'
});
