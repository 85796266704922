import i18next from 'i18next';

i18next.addResources('es', 'SearchFilters', {
  consignee: 'Consignatario',
  indicateConsignee: 'Indique consignatario',
  agent: 'Agente',
  indicateAgent: 'Indique agente',
  clientMarket: 'Mercado del cliente',
  indicateMarket: 'Indique mercado',
  productFamily: 'Familia de producto',
  indicateFamily: 'Indique familia',
  indicateCycle: 'Indique ciclo',
  indicateClientCountry: 'Indique país',
  clientCountry: 'País del cliente',
  applyFilter: 'Aplicar filtro',
  cleanFilters: 'Limpiar filtros',
  dates: 'Fechas',
  sort: 'Ordenar'
});

i18next.addResources('en', 'SearchFilters', {
  consignee: 'Consignee',
  indicateConsignee: 'Indicate consignee',
  agent: 'Agent',
  indicateAgent: 'Indicate agent',
  clientMarket: 'Customer market',
  indicateMarket: 'Indicate market',
  productFamily: 'Product',
  indicateFamily: 'Indicate family',
  indicateCycle: 'Indicate cycle',
  indicateClientCountry: 'Indicate country',
  clientCountry: 'Customer country',
  applyFilter: 'Apply filter',
  cleanFilters: 'Clean filters',
  dates: 'Dates',
  sort: 'Sort'
});
