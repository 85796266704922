import i18next from 'i18next';

i18next.addResources('es', 'NoItems', {
  thereAreNoItems: 'Aún no hay items cargados en la orden de compra',
  thereAreNoItemsPending: 'No hay items pendientes en esta orden'
});

i18next.addResources('en', 'NoItems', {
  thereAreNoItems: 'There are no items loaded in the purchase order yet',
  thereAreNoItemsPending: 'There are no items pending in this order'
});
