/* eslint-disable @typescript-eslint/naming-convention */
import i18next from 'i18next';

i18next.addResources('es', 'FileInput', {
  uploadFile: 'Cargar archivo',
  files: '{{count}} archivo',
  files_plural: '{{count}} archivos',
  downloadFile: 'Descargar',
  maxSizeExceeded: 'El tamaño del archivo no puede superar {{maxSize}}MB'
});

i18next.addResources('en', 'FileInput', {
  uploadFile: 'Upload File',
  files: '{{count}} file',
  files_plural: '{{count}} files',
  downloadFile: 'Download',
  maxSizeExceeded: 'File size can not exceed {{maxSize}}MB'
});
