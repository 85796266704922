import i18next from 'i18next';

i18next.addResources('es', 'OrderCertificate', {
  title: 'Certificados de calidad',
  format: 'Formato',
  quantity: 'Cantidad (ton)',
  unit: 'Unidad',
  numberTrack: 'Nº track',
  weight: 'Kilos',
  shipmentDate: 'Fecha Embarque',
  qualityCertificate: 'Certificado de calidad',
  qualityCertificateAVG: 'Certificado de calidad AVG'
});

i18next.addResources('en', 'OrderCertificate', {
  title: 'Quality certificates',
  format: 'Format',
  quantity: 'Quantity (ton)',
  unit: 'Unit',
  numberTrack: 'Nº track',
  weight: 'Weight',
  shipmentDate: 'Shipment date',
  qualityCertificate: 'Quality certificate',
  qualityCertificateAVG: 'Quality certificate AVG'
});
