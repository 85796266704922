import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiberLogo } from '../../assets';

import styles from './styles.module.scss';

export const FinalScreenCsat = () => {
  const { t } = useTranslation('csat');

  return (
    <div className={styles.thnxContainer}>
      <span className={styles.thankyouText}>{t('thankyou')}</span>
      <span className={styles.bxbTeam}>{t('boxboardTeam')}</span>
      <img src={FiberLogo} width={217} height={33} />
    </div>
  );
};
