import i18next from 'i18next';

i18next.addResources('es', 'OrderDetail', {
  orderDetail: 'Detalle de pedido',
  proformaError: 'No se ha podido obtener la proforma. Intenta mas tarde'
});

i18next.addResources('en', 'OrderDetail', {
  orderDetail: 'Order detail',
  proformaError: 'The proforma could not be obtained. Try later'
});
