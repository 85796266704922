import i18next from 'i18next';

i18next.addResources('es', 'HelpCenterScreenDocumentTypesSupported', {
  p1:
    'El Centralizador de Documentos es versátil y admite varios tipos de documentos. Puedes centralizar y gestionar los siguientes tipos de archivos:',
  listTitle1: 'Documentos del Pedido:',
  listItem1: 'Orden de Compra',
  listItem2: 'Proforma',
  listTitle2: 'Documentos de Embarques:',
  listItem3: 'Packing List',
  listTitle3: 'Fichas Técnicas:',
  listItem4: 'Mantén organizadas las fichas técnicas de productos de manera accesible.',
  listTitle4: 'Certificados Especiales:',
  listItem5: 'Almacena y gestiona certificados especiales asociados a tus operaciones.'
});

i18next.addResources('en', 'HelpCenterScreenDocumentTypesSupported', {
  p1:
    'The Document Centralizer is versatile and supports various types of documents. You can centralize and manage the following types of files:',
  listTitle1: 'Order Documents:',
  listItem1: 'Purchase Order',
  listItem2: 'Proforma',
  listTitle2: 'Shipment Documents: ',
  listItem3: 'Packing List ',
  listTitle3: 'Technical Sheets:',
  listItem4: 'Keep technical sheets of products organized and accessible.',
  listTitle4: 'Special Certificates:',
  listItem5: 'Store and manage special certificates associated with your products.'
});
