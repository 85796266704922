import i18next from 'i18next';

i18next.addResources('es', 'CreateOrderBasicInfo', {
  connectionError: 'Hubo un error creando la orden, por favor verifique su conexión a internet.',
  consignee: 'Consignatario*',
  destination: 'Destino final',
  destinationComments: 'Comentarios sobre el destino',
  fileRequired: 'Debes adjuntar el archivo de Orden de Compra para poder continuar',
  fileUploadError: 'Hubo un error subiendo el archivo',
  fillData: 'Complete los siguientes datos para continuar',
  ocAgentDuplicated: 'El código OC agente ya está registrado.',
  ocAgentEqualReference: 'Campo referencia y OC agente, no pueden ser iguales.',
  orderUpdated: 'Orden modificada exitosamente',
  referenceDuplicated: 'La orden de compra ya está registrada.',
  referenceEqualOcAgent: 'Los campos OC y OC Agente no pueden ser el mismo valor',
  requiredField: 'Este campo es requerido',
  selectCare: 'Seleccione un tipo de atención',
  selectCert: 'Seleccione una certificación',
  selectPriceList: 'Seleccione una lista',
  selectProductionCycle: 'Indique un ciclo',
  selectToContinue: 'Seleccione un cliente para continuar',
  somethingWrong: 'Hubo un error creando la orden, por favor revise los campos ingresados.',
  textPlaceholderReference: 'Orden de compra'
});

i18next.addResources('en', 'CreateOrderBasicInfo', {
  connectionError: 'Something went wrong, please check your internet connection.',
  consignee: 'Consignee*',
  destination: 'Final destination',
  destinationComments: 'Comments',
  fileRequired: 'You must attach the Purchase Order file to continue',
  fileUploadError: 'There was an error uploading the file',
  fillData: 'Complete the following information to continue',
  ocAgentDuplicated: 'The reference code is already registered',
  ocAgentEqualReference: 'Reference and purchase order-invoice fields cannot be the same value',
  orderUpdated: 'Successfully modified order',
  referenceDuplicated: 'The purchase order-invoice is already registered',
  referenceEqualOcAgent: 'Purchase order-invoice and Reference fields cannot be the same value',
  requiredField: 'This field is required',
  selectCare: 'Choose type of care',
  selectCert: 'Select certification',
  selectPriceList: 'Choose price list',
  selectProductionCycle: 'Select cycle',
  selectToContinue: 'Select a client to continue',
  somethingWrong: 'Something went wrong, please check all entered data.',
  textPlaceholderReference: 'Purchase order-invoice'
});
