import i18next from 'i18next';

i18next.addResources('es', 'GetFeedback', {
  orderQualification: 'Calificación de pedido',
  dearClient: 'Estimado cliente, has calificado tu',
  order: 'pedido #{{ orderId }}',
  asFollows: 'de la siguiente manera:',
  additionalComment: 'Comentario adicional',
  apiError: 'No fue posible obtener el feedback. Intenta más tarde'
});

i18next.addResources('en', 'GetFeedback', {
  orderQualification: 'Order Qualification',
  dearClient: 'Dear customer, have you rated your',
  order: 'order #{{ orderId }}',
  asFollows: 'as follows:',
  additionalComment: 'Additional comment',
  apiError: 'Feedback could not be obtained. Try later'
});
