import i18next from 'i18next';

i18next.addResources('es', 'Rating', {
  canBeBetter: 'Puede mejorar',
  wentWell: 'Todo salió bien',
  exceededExpectations: 'Superó mis expectativas'
});

i18next.addResources('en', 'Rating', {
  canBeBetter: 'It can be better',
  wentWell: 'All went well',
  exceededExpectations: 'Exceeded my expectations'
});
