import i18next from 'i18next';

i18next.addResources('es', 'UserForm', {
  client: 'Cliente',
  agent: 'Agente',
  cmpc: 'CMPC',
  agents: 'Agente/s',
  markets: 'Mercados asociados',
  cancel: 'Cancelar',
  continue: 'Continuar',
  createUser: 'Crear usuario',
  editUser: 'Editar usuario',
  notAgentFound: 'El cliente no cuenta con un agente asignado en la plataforma.',
  instructions: 'Seleccione un tipo de usuario para continuar',
  fillData: 'Complete los datos del usuario',
  associatedClients: 'Clientes asociados',
  typeAuthentication: 'Tipo de autenticación',
  name: 'Nombre',
  lastName: 'Apellido',
  username: 'Nombre de usuario',
  email: 'Correo electrónico',
  firstNamePlaceholder: 'Ingrese nombre',
  lastNamePlaceholder: 'Ingrese apellido',
  usernamePlaceholder: 'Ingrese nombre de usuario',
  emailPlaceholder: 'Ingrese correo electrónico',
  invalidEmail: 'Utilice el formato nombre@dominio.com',
  requiredField: 'Este campo es requerido',
  completeUserData: 'Complete los datos del usuario',
  completeAgentData: 'Complete los datos del usuario agente',
  maxCharacters: 'Este campo no debe tener mas de 20 caracteres.',
  invalidCharacters: 'Sólo se permiten letras, números y puntos.',
  agentCreated: '¡Usuario agente creado exitosamente!',
  clientCreated: '¡Usuario cliente creado exitosamente!',
  cmpcCreated: '¡Usuario CMPC creado exitosamente!',
  userCreated: '¡Usuario creado exitosamente!',
  apiError: 'Hubo un error al intentar crear el usuario, por favor revisa los datos o intenta mas tarde.',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  username_already_exists: 'Ya existe este nombre de usuario',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_already_exists: 'Ya existe un usuario con este correo',
  editData: 'Datos del usuario',
  editDataSuccess: 'Usuario modificado exitosamente',
  canCreateOrder: 'Permitir la creación/edición de ordenes de compra.',
  canIgnoreEmails: 'Desactivar correos de negocio',
  stockControl: 'Módulo de control de stock',
  stockControldetail: 'Selecciona la bodega para activar la visualización en el usuario.',
  canViewStockController: 'Permitir visualizar módulo Control de Stock',
  canViewCreditStatus: 'Permitir visualizar módulo Estatus Crediticio.',
  canEditProductiveCycles: 'Permitir editar cierre de Ciclos Productivos.',
  canViewTracking: 'Permitir visualizar módulo de Tracking.',
  canViewAdmin: 'Permitir visualizar Administrador Fiber Place.'
});

i18next.addResources('en', 'UserForm', {
  client: 'Client',
  agent: 'Agent',
  cmpc: 'CMPC',
  agents: 'Agents',
  markets: 'Associated Markets',
  cancel: 'Cancel',
  continue: 'Continue',
  createUser: 'Create user',
  editUser: 'Edit user',
  notAgentFound: 'The client does not have an agent assigned on the platform.',
  instructions: 'Select a user type to continue',
  fillData: 'Fill in the user data',
  associatedClients: 'Associated clients',
  typeAuthentication: 'Authentication type',
  name: 'Firstname',
  lastName: 'Lastname',
  username: 'Username',
  email: 'Email',
  firstNamePlaceholder: 'Enter firstname',
  lastNamePlaceholder: 'Enter lastname',
  usernamePlaceholder: 'Enter username',
  emailPlaceholder: 'Enter email',
  invalidEmail: 'Use name@domain.com format',
  requiredField: 'This field is required',
  completeUserData: 'Fill user data',
  completeAgentData: 'Fill in the agent user data',
  maxCharacters: 'Please enter up to 20 characters',
  invalidCharacters: 'Only letters, numbers and dots are allowed',
  agentCreated: 'Agent user created successfully!',
  clientCreated: 'Client user created successfully!',
  cmpcCreated: 'CMPC user created successfully!',
  userCreated: 'User created successfully!',
  apiError: 'There was an error trying to create the user, please check the data or try later.',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  username_already_exists: 'This username already exists',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_already_exists: 'A user with this email already exists',
  editData: 'User data',
  editDataSuccess: 'User edited successfully',
  canCreateOrder: 'Enable create/edit purchase orders.',
  canIgnoreEmails: 'Disable sending emails',
  stockControl: 'Stock control module',
  stockControldetail: 'Select the warehouse to activate the visualization on the user',
  canViewStockController: 'Allow viewing the Control Stock module',
  canViewCreditStatus: 'Allow viewing the Credit Status module. ',
  canEditProductiveCycles: 'Allow editing the Production Cycles closing.',
  canViewTracking: 'Allow viewing the Tracking module.',
  canViewAdmin: 'Allow to view Fiber Place administrator.'
});
