import i18next from 'i18next';

// i18next.addResources('es', 'StockControl', {
//   title: 'Control de Stock'
// });

// i18next.addResources('en', 'StockControl', {
//   title: 'Stock Control'
// });

i18next.addResources('es', 'CreditStatusHeaders', {
  invoice: 'Factura',
  asignation: 'Asignación',
  documentDate: 'Fecha de documento',
  expirationDate: 'Vencimiento',
  daysExpired: 'Días vencidos',
  invoicedAmount: 'Cantidad facturada',
  searchFilter: 'Filtro de búsqueda',
  dateFromFilter: 'Fecha desde',
  dateToFilter: 'Fecha hasta',
  daysExpiredFilter: 'Días vencidos',
  daysExpiredFilterPlaceholder: 'Indique días',

  expirationGreaterThan20days: 'Vencimiento mayor a 20 días',
  expirationLessThan20days: 'Vencimiento de 1 a 20 días',
  noExpirationDays: 'Días por vencer',
  applyFilter: 'Aplicar filtro'
});

i18next.addResources('en', 'CreditStatusHeaders', {
  invoice: 'Invoice',
  asignation: 'Asignation',
  documentDate: 'Document date',
  expirationDate: 'Expiration',
  daysExpired: 'Days dued',
  invoicedAmount: 'Amount billed',
  searchFilter: 'Search filters',
  dateFromFilter: 'Start date',
  dateToFilter: 'End date',
  daysExpiredFilter: 'Days dued',
  daysExpiredFilterPlaceholder: 'Select days',

  expirationGreaterThan20days: 'Expiration in over 20 days',
  expirationLessThan20days: 'Expiration in 1 to 20 days',
  noExpirationDays: 'Days till expired',
  applyFilter: 'Apply filter'
});
