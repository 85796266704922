import i18next from 'i18next';

i18next.addResources('es', 'Certificates', {
  clear: 'Limpiar filtros',
  shipmentNum: 'N˚ Embarque',
  shipmentPlaceholder: 'Buscar embarque',
  orderId: 'N˚ Pedido - Item',
  orderPlaceholder: 'N˚ de Pedido',
  itemNum: 'Item',
  unitId: 'Unidad',
  trackNum: 'N˚ track'
});

i18next.addResources('en', 'Certificates', {
  clear: 'Reset',
  shipmentNum: 'Shipment',
  shipmentPlaceholder: 'Search shipment',
  orderId: 'Order',
  orderPlaceholder: 'Order Id',
  itemNum: 'Item',
  unitId: 'Unit',
  trackNum: 'Track number'
});
