export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: `${process.env.REACT_APP_AZURE_REDIRECT_URI}`
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

export const auth0Config = {
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientId: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  redirectUri: window.location.origin,
  audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
  scope: 'read:current_user'
};

/* domain="auth-boxboard-dev.us.auth0.com"
clientId="pk6kRyQsKOBI2k8FBq7OiiQ4Q9pmFdta"
redirectUri={window.location.origin}
audience="auth-boxboard-dev-users-api"
scope="read:current_user" */
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
};
