import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  hamburger?: boolean;
  onClick: () => void;
}

function Hamburger({ onClick, hamburger, className }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'row center middle',
        styles.hamburger,
        {
          [styles.hamburgerActive]: hamburger
        },
        className
      )}
    >
      <div className={styles.hamburgerInner} />
    </button>
  );
}

export default Hamburger;
