import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import humanizeDuration from 'humanize-duration';
import { Link } from 'react-router-dom';

import { getCurrentLanguage } from 'utils/i18n';
import Circle from 'components/Circle';
import Tooltip from 'components/Tooltip';
import { ActionTypeNotifications } from 'types/Notifications';

import styles from './styles.module.scss';
import { ActionButtonText } from './constants';

interface Props {
  action?: {
    type: ActionTypeNotifications;
    url: string;
  };
  createdAt: string;
  description: string;
  fullWidth?: boolean;
  id: number;
  read: boolean;
  readNotification: (id: number) => void;
  title: string;
  type: string;
  notificationFlag?: boolean;
}

function Notification({
  action,
  createdAt,
  description,
  fullWidth = false,
  id,
  read,
  readNotification,
  title,
  type,
  notificationFlag = false
}: Props) {
  const { t } = useTranslation('Notification');
  const sinceTime: number = new Date().getTime() - new Date(createdAt).getTime();
  const textTime = humanizeDuration(sinceTime, { language: getCurrentLanguage(), largest: 1, round: true });

  const markAsRead = () => readNotification(id);

  return (
    <div
      className={cn(
        {
          [styles.notificationCenter]: fullWidth,
          [styles.notificationRead]: read,
          [styles.hoverNotification]: !notificationFlag
        },
        'column space-between wrap',
        notificationFlag ? styles.notification : styles.notificationContainer
      )}
    >
      <div className={cn('column', styles.notificationContent)}>
        <Tooltip content={read ? t('markAsUnread') : t('markAsRead')} position="top left">
          <div>
            <Circle className={styles.circle} onClick={markAsRead} read={read} type={type} />
          </div>
        </Tooltip>
        <span className={cn('full-width text m-bottom-2', styles.title)}>{title}</span>
        <p className={cn('full-width m-bottom-2', styles.description)}>{description}</p>
      </div>
      <div className={cn('column', styles.notificationActions)}>
        <p className={cn('full-width m-bottom-2', styles.notificationTime)}>{t('ago', { textTime })}</p>
        {action && (
          <Link
            className={cn('button text approve m-right-3', styles.buttonApprove)}
            to={action.url}
            type="button"
          >
            {t(ActionButtonText[action.type])}
          </Link>
        )}
      </div>
    </div>
  );
}
export default Notification;
