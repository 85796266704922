import i18next from 'i18next';

i18next.addResources('es', 'CreateOrder', {
  title: 'Nueva orden de compra',
  certification: 'Certificación',
  reference: 'Orden de compra (OC)',
  ocAgent: 'OC agente',
  orderId: 'Correlativo interno',
  typeOfCare: 'Tipo de atención',
  file: 'OC adjunta',
  basicInfo: 'Datos generales',
  loadItems: 'Cargar items',
  confirmOrder: 'Confirmar orden de compra',
  productionCycle: 'Ciclo de Producción',
  priceList: 'Lista de precios',
  cancelQuestion: '¿Estás seguro que deseas salir?',
  cancelTipFirst: 'Si sales ahora ',
  cancelTipBold: 'perderás toda la información',
  cancelTipLast: ' completada hasta el momento.',
  validationCMPCStep: 'Validar orden de compra',
  draft: 'Orden de compra borrador',
  discarded: 'Orden de compra descartada',
  validationCMPC: 'Orden de compra confirmada',
  validated: 'Orden de compra validada'
});

i18next.addResources('en', 'CreateOrder', {
  title: 'New order',
  certification: 'Certification',
  reference: 'Purchase order-Invoice',
  ocAgent: 'Reference',
  orderId: 'OC ID',
  typeOfCare: 'Attention',
  file: 'Attached invoice',
  basicInfo: 'General info',
  loadItems: 'Load items',
  confirmOrder: 'Confirm order',
  productionCycle: 'Production Cycle',
  priceList: 'Price list',
  cancelQuestion: 'Are you sure you want to quit?',
  cancelTipFirst: 'If you leave now ',
  cancelTipBold: 'you will lose all the information',
  cancelTipLast: ' completed so far.',
  validationCMPCStep: 'Purchase order validation',
  draft: 'Draft purchase order',
  discarded: 'Purchase order discarded',
  validationCMPC: 'Purchase order confirmed',
  validated: 'Purchase order validated'
});
