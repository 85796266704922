import i18next from 'i18next';

i18next.addResources('es', 'Article', {
  articles: 'Artículos',
  pending: 'Pendientes',
  articleDetails: 'Detalle del artículo',
  articleCreatedBy: 'Artículo creado por {{name}}'
});

i18next.addResources('en', 'Article', {
  articles: 'Articles',
  pending: 'Pending',
  articleDetails: 'Article details',
  articleCreatedBy: 'Article created by {{name}}'
});
