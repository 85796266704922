import i18next from 'i18next';

i18next.addResources('es', 'FamilyPaperSelector', {
  change: 'Cambiar',
  paperType: 'Tipo de papel'
});

i18next.addResources('en', 'FamilyPaperSelector', {
  change: 'Change',
  paperType: 'Paper Type'
});
