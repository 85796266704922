import i18next from 'i18next';

i18next.addResources('es', 'RequestSelector', {
  noData: 'No se encontraron resultados',
  noClients: 'No se encontraron clientes',
  noWarehouse: 'No se encontraron bodegas',
  noConsignees: 'No se encontraron consignatarios',
  selectClient: 'Seleccionar cliente',
  selectClients: 'Seleccione uno o varios clientes',
  selectWarehouse: 'Selecciona una o más bodegas',
  selectAuthenticator: 'seleccione el tipo de autenticador',
  selectConsignee: 'Seleccione consignatario',
  selectMarkets: 'Seleccione uno o varios mercados',
  noOptions: 'No se encontraron opciones',
  loading: 'Cargando'
});

i18next.addResources('en', 'RequestSelector', {
  noData: 'No data found',
  noClients: 'No customers found',
  noWarehouse: 'No warehouse found',
  noConsignees: 'No consginees found',
  selectClient: 'Choose client',
  selectAuthenticator: 'select authenticator type',
  selectClients: 'Choose one or more clients',
  selectWarehouse: 'Select one or more warehouse',
  selectConsignee: 'Choose consignee',
  selectMarkets: 'Choose one or more markets',
  noOptions: 'No options found',
  loading: 'Loading'
});
