import i18next from 'i18next';

i18next.addResources('es', 'ButtonsTable', {
  chooseArticle: 'Elegir artículo',
  changeArticle: 'Modificar artículo',
  rejectedArticle: 'Artículo rechazado',
  error:
    'Lamentamos informarle que este artículo no puede ser seleccionado debido a que infringe nuestra política de restricciones de categorías.'
});

i18next.addResources('en', 'ButtonsTable', {
  chooseArticle: 'Choose article',
  changeArticle: 'Modify article',
  rejectedArticle: 'Rejected article',
  error:
    'We regret to inform you that this article cannot be selected as it violates our policy on category restrictions.'
});
