import { UserTypeId } from 'types/User';

import { MAIN_PRIVATE_PATH, MAIN_PUBLIC_PATH, TAG } from './constants';

interface Params {
  auth?: boolean;
  roles?: UserTypeId[];
  tags: TAG[];
  path: string;
  userTypeId?: UserTypeId;
  permission: string;
  userPermissions: string[] | undefined;
}

// eslint-disable-next-line complexity
export const generateUserRedirect = ({
  auth,
  roles,
  tags,
  userTypeId,
  permission,
  userPermissions
}: Params) => {
  for (const tag of tags) {
    if (tag === TAG.PUBLIC) {
      return undefined;
    }

    if (tag === TAG.PRIVATE) {
      if (!auth) {
        return MAIN_PUBLIC_PATH;
      }

      if (roles && (!userTypeId || !roles?.includes(userTypeId))) {
        return MAIN_PRIVATE_PATH;
      }
    }

    if (tag === TAG.AUTH && auth) {
      return MAIN_PRIVATE_PATH;
    }

    if (permission && !userPermissions?.includes(permission)) {
      return MAIN_PUBLIC_PATH;
    }
  }
  return undefined;
};
