import i18next from 'i18next';

i18next.addResources('es', 'OrderDispatches', {
  shipmentNum: 'N° Embarque',
  date: 'Fecha',
  units: 'Unidades',
  weight: 'Cant. (ton)',
  eta: 'ETA',
  packingList: 'Packing list',
  acceptedReception: 'Recepción conforme',
  emptyMessage: 'Aún no hay despachos disponibles para este pedido',
  msgError: 'Ocurrió un error por favor intente nuevamente'
});

i18next.addResources('en', 'OrderDispatches', {
  shipmentNum: 'Shipment number',
  date: 'Date',
  units: 'Units',
  weight: 'Quantity (tons)',
  eta: 'ETA',
  packingList: 'Packing list',
  acceptedReception: 'Accepted reception',
  emptyMessage: "There aren't dispatches available for this order yet",
  msgError: 'An error occurred. Please try again'
});
