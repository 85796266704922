import { useWindowSize } from 'react-use';

import { SCREEN } from 'utils/screen';

const useScreenResolution = () => {
  const { width } = useWindowSize();

  return {
    isMobile: width <= SCREEN.XS,
    isTablet: width > SCREEN.XS && width <= SCREEN.SM,
    isResponsive: width <= SCREEN.SM
  };
};

export default useScreenResolution;
