import i18n from 'i18next';

import { changeDayjsLanguage } from './dayjs';

export const LANGUAGES = ['es', 'en'];
export const getCurrentLanguage = () => i18n.language.split('-')[0];
export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
  changeDayjsLanguage(lng);
};
