import i18next from 'i18next';

i18next.addResources('es', 'DetailsArticle', {
  pendingTitle: 'Artículo PENDIENTE POR VALIDAR en orden de compra # {{id}}',
  validatedTitle: 'Artículo VALIDADO en orden de compra # {{id}}',
  rejectedTitle: 'Artículo RECHAZADO en orden de compra # {{id}}',
  titleDetailsArticle: 'Detalle del artículo',
  buttonBack: 'Volver',
  diameterRollExceeded: 'El diámetro máximo permitido para este código de papel (cara abajo) es de 150 cms.',
  buttonRejectArticle: 'Rechazar artículo',
  buttonValidateArticle: 'Validar artículo',
  diameter: 'Diámetro (cm)',
  msgSuccess: '¡Se ha actualizado correctamente el estado del artículo!',
  msgError: 'Ocurrió un error por favor intente nuevamente',
  somethingWrong: 'Ocurrió un error por favor intente nuevamente',
  rollMinimumWidthError: 'Ocurrió un error en las medidas entre el diametro y ancho',
  stackRuleOfThreeError: 'No se cumple la regla de tres, revisar ancho y largo del papel.',
  categoriesError:
    'Lamentamos informarle que este artículo no puede ser seleccionado debido a que infringe nuestra política de restricciones de categorías.',
  incompatibleDiameterAndPackaging: 'No se puede validar este articulo, el embalaje no es el correcto'
});

i18next.addResources('en', 'DetailsArticle', {
  pendingTitle: 'Article PENDING TO VALIDATE in purchase order # {{id}}',
  validatedTitle: 'CREATED article in purchase order # {{id}}',
  rejectedTitle: 'REJECTED article in purchase order # {{id}}',
  titleDetailsArticle: 'Article detail',
  buttonBack: 'Go back',
  diameterRollExceeded: 'The maximum diameter allowed for this paper code (face down) is 150 cm.',
  buttonRejectArticle: 'Reject article',
  buttonValidateArticle: 'Validate article',
  diameter: 'Diameter (cm)',
  msgSuccess: 'Item status has been successfully updated!',
  msgError: 'An error occurred. Please try again',
  somethingWrong: 'An error occurred. Please try again',
  rollMinimumWidthError: 'An error occurred in the measures between the diameter and wide',
  stackRuleOfThreeError: 'The rule of three is not fulfilled, check wide and length of the paper.',
  categoriesError:
    'We regret to inform you that this article cannot be selected as it violates our policy on category restrictions.',
  incompatibleDiameterAndPackaging: 'This item cannot be validated, the packaging is not correct.'
});
