import i18next from 'i18next';

i18next.addResources('es', 'HelpCenterScreen', {
  home: 'Inicio',
  title: 'Centro de ayuda',
  orders: 'Pedidos',
  ordersText:
    'El Menú de Pedidos proporciona una visión integral del estado e información de todos los pedidos y órdenes de compra asignadas al usuario. A continuación, se describen las principales características de esta sección.',
  docs: 'Documentos',
  howCanHelpYou: '¿Cómo podemos ayudarte?',
  orderList: 'Listado de pedidos',
  orderResume: 'Resumen de pedido',
  newOC: 'Proceso de Ingreso de Nueva Orden de Compra',
  orderDetail: 'Detalle del pedido',
  documentTypesSupported: 'Tipos de Documentos Soportados',
  documentsP1:
    'Este módulo está diseñado para ofrecerte una experiencia eficiente y organizada en la gestión de documentos. En este módulo podrás encontrar 3 pestañas que corresponden a “Búsqueda por pedidos y embarques”, “Fichas Técnicas” y “Declaraciones Especiales”. ',
  documentsListTitle: 'Funcionalidades Clave:',
  documentsListUl1: 'Búsqueda por Número de Pedido o Embarque',
  documentsListSubUl1:
    'La funcionalidad de búsqueda por número de pedido y/o embarque ofrece una manera rápida y eficiente de localizar información específica dentro del sistema. Para realizar una búsqueda ingresa el número de pedido y/o embarque que deseas buscar en la barra de búsqueda. Puedes ingresar ambos tipos de información si es necesario.',
  documentsListUl2: 'Fichas Técnicas',
  documentsListSubUl2:
    'La funcionalidad de búsqueda de fichas técnicas agrupadas por familia de papel ofrece una manera eficaz de localizar información específica relacionada con las fichas técnicas de productos.',
  documentsListUl3: 'Declaraciones especiales',
  documentsListSubUl3:
    'La funcionalidad de búsqueda declaraciones especiales, permite encontrar certificados que acrediten específicamente la presencia o ausencia de algún componente de nuestros productos.'
});

i18next.addResources('en', 'HelpCenterScreen', {
  home: 'Home',
  title: 'Help Center',
  orders: 'Orders',
  ordersText:
    'The Orders Menu provides a comprehensive view of the status and information of all orders and purchase orders assigned to the user. Below are the main features of this section.',
  docs: 'Documents',
  howCanHelpYou: 'How can we help you?',
  orderList: 'Order List',
  orderResume: 'Order Summary',
  newOC: 'New Purchase Order Entry Process',
  orderDetail: 'Order Detail',
  documentTypesSupported: 'Supported Document Types',
  documentsP1:
    'This module is designed to offer you an efficient and organized experience in document management. In this module, you can find 3 tabs corresponding to "Search by Orders and Shipments", "Technical Sheets", and "Special Declarations". ',
  documentsListUl1: 'Search by Order or Shipment Number',
  documentsListSubUl1:
    ' The search functionality by order and/or shipment number offers a quick and efficient way to locate specific information within the system. To perform a search, enter the order and/or shipment number you want to search for in the search bar. You can enter both types of information if necessary. ',
  documentsListUl2: 'Technical Sheets',
  documentsListSubUl2:
    'The technical sheets search functionality grouped by paper family offers an effective way to locate specific information related to product technical sheets.',
  documentsListUl3: 'Special Declarations',
  documentsListSubUl3:
    'The special declarations search functionality allows you to find certificates specifically accrediting the presence or absence of any component of our products.'
});
