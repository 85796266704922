import React from 'react';

import { useSelector } from 'contexts/NotificationsContext';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  numberClassName?: string;
}

function Badge({ className = '', numberClassName = '' }: Props) {
  const totalCountUnread = useSelector(state => state.totalCountUnread);
  return (
    <>
      {totalCountUnread > 0 && (
        <span className={`${className} ${styles.badge}`}>
          <span className={`${styles.numberNotifications} ${numberClassName}`}>{totalCountUnread}</span>
        </span>
      )}
    </>
  );
}

export default Badge;
