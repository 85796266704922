import i18next from 'i18next';

i18next.addResources('es', 'ShipmentModal', {
  deliveryDetail: 'Detalle de despacho recibido',
  indicateDetails: 'Estimado cliente, por favor indica los siguientes detalles sobre tu despacho con',
  shipmentDetails: 'Detalles sobre despacho con',
  shipment: 'N˚ Embarque #{{shipmentNumber}}.',
  productConditions: '¿En que condiciones has recibido tus productos?',
  deliveryReceived: 'Su despacho ha sido marcado como recibido. ¡Gracias por su respuesta!'
});

i18next.addResources('en', 'ShipmentModal', {
  deliveryDetail: 'Delivery detail received',
  indicateDetails: 'Dear client, please indicate the following details about your office with',
  shipmentDetails: 'Details about dispatch with',
  shipment: 'Shipment #{{shipmentNumber}}.',
  productConditions: 'In what conditions have you received your products?',
  deliveryReceived: 'Your delivery has been marked as received. Thanks for your reply!'
});
