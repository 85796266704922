import i18next from 'i18next';

i18next.addResources('es', 'BurnoutRisk', {
  title: 'Riesgo por agotamiento de línea de crédito',
  errorData: 'Error al cargar los datos',
  middleRisk: 'Riesgo medio',
  lowRisk: 'Riesgo bajo',
  highRisk: 'Riesgo alto',
  clients: 'clientes',
  lowTooltip: 'Menor a 80% de agotamiento',
  midTooltip: 'Entre 80% y 95% de agotamiento',
  highTooltip: 'Mayor de 95% de agotamiento'
});

i18next.addResources('en', 'BurnoutRisk', {
  title: 'Credit line exhaustion risk',
  errorData: 'Something happened',
  middleRisk: 'Medium risk',
  lowRisk: 'Low risk',
  highRisk: 'High risk',
  clients: 'clients',
  lowTooltip: 'Less than 80% of exhaustion',
  midTooltip: 'Between 80% and 95% of exhaustion',
  highTooltip: 'Greater than 95% of exhaustion'
});
