import i18next from 'i18next';

i18next.addResources('es', 'Header', {
  cmpcLogo: 'Logo de CMPC',
  boxboardLogo: 'Logo de Boxboard'
});

i18next.addResources('en', 'Header', {
  cmpcLogo: 'CMPC Logo',
  boxboardLogo: 'Boxboard Logo'
});
