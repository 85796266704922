import i18next from 'i18next';

i18next.addResources('es', 'ProductionCycles', {
  productionCycles: 'Ciclos de Producción',
  cycleList: 'Información de ciclos de producción',
  plant: 'Planta',
  plantPlaceholder: 'Seleccione la planta',
  cycles: 'Ciclo de Producción',
  family: 'Familia de producto',
  product: 'Producto',
  start: 'Comienzo',
  end: 'Término',
  blockNum: 'N˚ Bloque',
  cycleTitle: 'Ciclo',
  lastUpdate: 'Última actualización: {{date}}',
  closedBlocks: 'Bloques cerrados',
  blockClosure: 'Cierre bloques: {{day}} de {{date}}',
  noMatch: 'No hay resultados que coincidan con tu busqueda',
  bimonthly: 'Bimestral',
  edit: 'Editar',
  editTitle: 'Edición Ciclos de Producción',
  editBreadcrum: 'Edición ciclos de producción',
  closeCyclesBanner: 'Cierre de papel por defecto ',
  closeFamily: 'Cierre de familia automático',
  editFamily: 'Editar cierre de familia',
  days: 'días',
  save: 'Guardar',
  errorGetEditData: 'Error al obtener la información.',
  errorPushEditedData: 'No se pudo guardar la información.',
  saveChangesSuccess: '¡Ciclo de producción guardado con éxito!',
  saveChangesSuccessBlock: 'guardado con éxito!',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  confirmEdition: 'Confirmación de la edición',
  subtitle1: 'Al confirmar, ',
  subtitleStrong: 'se guardará',
  subtitle2: ' el cambio de ciclo de producción.',
  wishConfirm: '¿Deseas confirmar?',
  back: 'Volver',
  notSavedChanges: 'Cambios no guardados',
  notSavedChangesBody:
    'Asegúrate de guardar tus cambios antes de salir, haz click en el botón guardar ubicado en la parte superior de tu pantalla, de lo contrario tus cambios no se guardarán.',
  keepEditing: 'Seguir editando',
  leave: 'Salir',
  ok: 'Ok',
  today: 'Hoy'
});

i18next.addResources('es', 'productionCyclesHeaders', {
  headers: [
    [
      'Planta',
      'Ciclo',
      'Familia',
      'Estatus',
      'Fecha de cierre de ciclo',
      'Producto',
      'Fecha de inicio',
      'Fecha de fin',
      'Número de bloque'
    ]
  ],
});

i18next.addResources('en', 'productionCyclesHeaders', {
  headers: [
    [
      'Plant',
      'Cycle',
      'Family',
      'Status',
      'Block closure',
      'Product',
      'Start date',
      'End date',
      'Block Num'
    ]
  ],
});

i18next.addResources('en', 'ProductionCycles', {
  productionCycles: 'Production Cycles',
  cycleList: 'Production cycles information',
  plant: 'Plant',
  plantPlaceholder: 'Select the plant',
  cycles: 'Production Cycle',
  family: 'Product family',
  product: 'Product',
  start: 'Start',
  end: 'End',
  blockNum: 'Block number',
  cycleTitle: 'Cycle',
  lastUpdate: 'Last update: {{date}}',
  closedBlocks: 'Closed blocks',
  blockClosure: 'Block closure: {{day}} of {{date}}',
  noMatch: 'No results matching your search',
  bimonthly: 'Bimonthly',
  edit: 'Edit',
  editTitle: 'Edition Production Cycles',
  editBreadcrum: 'Edition production cycles',
  closeCyclesBanner: 'Default paper closure ',
  closeFamily: 'Automatic family closing',
  editFamily: 'Edit family closure',
  days: 'days',
  save: 'Save',
  errorGetEditData: 'Error obtaining the information.',
  errorPushEditedData: 'The information could not be saved.',
  saveChangesSuccess: 'Production cycle saved successfully!',
  saveChangesSuccessBlock: 'saved successfully!',
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirmEdition: 'Confirmation of edition',
  subtitle1: 'By confirming, the production cycle change will ',
  subtitleStrong: 'be saved.',
  subtitle2: ' The change of production cycle.',
  wishConfirm: 'Do you want to confirm?',
  back: 'Back',
  notSavedChanges: 'Unsaved Changes',
  notSavedChangesBody:
    'Be sure to save your changes before leaving, click on the save button located at the top of your screen, otherwise your changes will not be saved.',
  keepEditing: 'Continue editing',
  leave: 'Leave',
  ok: 'Ok',
  today: 'Today'
});
