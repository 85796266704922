import i18next from 'i18next';

i18next.addResources('es', 'SuggestedArticles', {
  wantToReorder: '¿Desea volver a pedir alguno de estos artículos?',
  wantToOrderAnother: '¿Desea pedir otro artículo?',
  weHaveFound:
    'Hemos encontrado algunos artículos en tu historial de pedidos que coinciden con el papel seleccionado:',
  searchArticles: 'Buscar artículos',
  apiError: 'No ha sido posible consultar artículos sugeridos'
});

i18next.addResources('en', 'SuggestedArticles', {
  wantToReorder: 'Do you want to reorder any of these items?',
  wantToOrderAnother: 'Do you want to order another item?',
  weHaveFound: 'We have found some items in your order history which match the selected paper:',
  searchArticles: 'Search articles',
  apiError: 'It has not been possible to consult suggested articles'
});
