import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
  read?: boolean;
  type: string;
}

function Circle({ className = '', onClick, read = false, type }: Props) {
  return (
    <div
      data-testid="circleButton"
      className={cn('m-right-2', className, styles.circle, { [styles.read]: read }, styles[type])}
      onClick={onClick}
    />
  );
}

export default Circle;
