export const removeFalsyProps = <T extends Record<string, any>>(obj: T): T => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        delete obj[key];
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        removeFalsyProps(value);
      }
    }
  }
  return obj;
};
