import i18next from 'i18next';

i18next.addResources('es', 'EditUser', {
  client: 'Cliente',
  agent: 'Agente',
  cmpc: 'CMPC',
  userType: 'Tipo de usuario',
  editError: 'Hubo un error desconocido, por favor revise los datos o intente de nuevo más tarde',
  getCustomerError: 'Hubo un error intentando obtener el cliente de este usuario',
  getAgentError: 'Hubo un error intentando obtener los agentes de este cliente'
});

i18next.addResources('en', 'EditUser', {
  client: 'Client',
  agent: 'Agent',
  cmpc: 'CMPC',
  userType: 'User type',
  editError: 'There was an unknown error, please check the data or try again later',
  getCustomerError: 'There was an error trying to get this users client',
  getAgentError: 'There was an error trying to get the agents for this client'
});
