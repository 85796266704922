/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/order
import { useMsal } from '@azure/msal-react';

import { useAuth0 } from '@auth0/auth0-react';

import PATHS from 'components/Routes/paths';
import { ReactComponent as SettingsIcon } from 'components/Layout/assets/ic_settings.svg';
import { ReactComponent as LogoutIcon } from 'components/Layout/assets/ic_logout.svg';
import { getExpDate, getSavedUser, removeSavedUser } from 'utils/session';

import styles from './styles.module.scss';
import { EPOCH } from './constants';

interface Props {
  handleClose: (showComponent: boolean) => void;
  showComponent: boolean;
  username: string;
  email: string;
  userType: string;
  country: string;
  refTo: React.RefObject<HTMLInputElement> | null;
}

declare global {
  interface Window {
    dtrum?: any;
  }
}

const OptionPanel = ({ handleClose, showComponent, username, email, userType, country, refTo }: Props) => {
  const history = useHistory();
  const { instance } = useMsal();
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const savedUser = getSavedUser();
  /*  Dejar codigo para feature alertar usuario en caso de session expired
    const { time, start } = useTimer({
    initialTime: expireIn,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: () => {
      console.log(time);
    }
  }); */

  const handleLogout = () => {
    // this flag is used in the App/index.tsx
    window.localStorage.setItem('logout', 'true');
    window.localStorage.removeItem('redirectTo');
    window.sessionStorage.removeItem('csat');
    history.push('/home');
    // Remove dynatrace session
    if (window.dtrum) {
      window.dtrum.endSession();
    }
    if (savedUser?.isAzure) {
      instance.logoutRedirect();
    } else {
      removeSavedUser();
      logout({ returnTo: window.location.origin });
    }
  };

  useEffect(() => {
    const expiresFromStorage = savedUser?.expiresIn ? savedUser?.expiresIn * EPOCH : 500;
    const expireInMili = getExpDate(expiresFromStorage);
    window.setTimeout(handleLogout, expireInMili);
    if (window.dtrum && userType) {
      window.dtrum.identifyUser(email);
      // eslint-disable-next-line camelcase
      window.dtrum.sendSessionProperties(undefined, undefined, { user_type: userType, country });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <div
      data-testid="option-panel-test"
      className={cn('column', styles.optionPanel, { [styles.noDisplay]: !showComponent })}
      ref={refTo}
    >
      <span className={`text bold ${styles.username} ${styles.spacePadding} m-bottom-2`}>{username}</span>
      <hr className={styles.lineSeparator} />
      <Link
        className={`row middle text ${styles.options} ${styles.spacePadding} m-bottom-2`}
        to={PATHS.accountSettings}
        data-testid="close-button-test"
        onClick={() => handleClose(false)}
      >
        <SettingsIcon className={`m-right-2 ${styles.iconPanel}`} />
        <span>{t('OptionPanel:settings')}</span>
      </Link>
      <hr className={styles.lineSeparator} />
      <button
        type="button"
        data-testid="logout-button-test"
        className={`row middle text ${styles.options} ${styles.spacePadding}`}
        onClick={() => handleLogout()}
      >
        <LogoutIcon className={`m-right-2 ${styles.iconPanel}`} />
        <span>{t('OptionPanel:logout')}</span>
      </button>
    </div>
  );
};

export default OptionPanel;
