import React, { ReactElement } from 'react';
import { ToastProps } from 'react-toast-notifications';

import styles from './styles.module.scss';

function Toast({ appearance, children, onDismiss }: ToastProps) {
  const Component = React.cloneElement(children as ReactElement, {
    type: appearance,
    className: `${styles.alert} m-bottom-1`,
    close: onDismiss,
    canHide: false
  });
  return Component;
}

export default Toast;
