import i18next from 'i18next';

i18next.addResources('es', 'OrderCertificatePDF', {
  print: 'Imprimir',
  order: 'Pedido',
  customerRef: 'Orden Cliente',
  notes: 'Notas:',
  scannerMensuration: 'T*: Medición del escáner',
  file: 'Cc: Archivo',
  parameter: 'Parametro',
  grammage: 'Gramaje total',
  thinkness: 'Espesor',
  moisture: 'Humedad',
  stiffnessLong: 'Rigidez Taber Long',
  stiffnessTrans: 'Rigidez Taber Trans',
  brightness: 'Blancura',
  gloss: 'Brillo',
  smoothness: 'Lisura PPS',
  tappi: 'Tappi',
  standard: 'Estandar',
  realAvg: 'Real AVG',
  grammageUnits: 'g/m²',
  thinknessUnits: 'um',
  columnUnits: '%',
  stiffnessUnits: 'mN*m',
  nameQuality: 'Pablo Soto R',
  positionQuality: 'Gerente de Control de Calidad',
  nameTechnicalManager: 'Pablo Bustamante B',
  positionTechnicalManager: 'Gerente Técnico',
  qualityDataSheet: 'Certificado de calidad',
  load: 'Flete',
  unitSmoothness: ' : < 1.5 um',
  uniGloss: ' : > 40%',
  unitBrightness: ' : Std. +/- 2',
  unitMoisture: ' : 7.5 +/- 1.5% Diferenciada',
  grammageNote: 'Gramaje',
  unitGrammageNote: ' : Std. +/-5%',
  stiffnessNotes: 'Rigidez',
  unitStiffness: ' : Std. - 10%'
});

i18next.addResources('en', 'OrderCertificatePDF', {
  print: 'Print',
  order: 'Order',
  customerRef: 'Customer Ref',
  notes: 'Notes:',
  scannerMensuration: 'T*: Scanner mensuration',
  file: 'Cc: File',
  parameter: 'Parameter',
  grammage: 'Grammage',
  thinkness: 'Thickness',
  moisture: 'Moisture',
  stiffnessLong: 'Stiffness 15˚',
  stiffnessTrans: 'Stiffness 15˚',
  brightness: 'Brightness',
  gloss: 'Gloss 75˚',
  smoothness: 'Smoothness',
  tappi: 'Tappi',
  standard: 'Standard',
  realAvg: 'Real AVG',
  grammageUnits: 'g/m²',
  thinknessUnits: 'um',
  columnUnits: '%',
  stiffnessUnits: 'mN*m',
  nameQuality: 'Pablo Soto R',
  positionQuality: 'Quality Control Manager',
  nameTechnicalManager: 'Pablo Bustamante B',
  positionTechnicalManager: 'Technical Manager',
  qualityDataSheet: 'Quality Data Sheet',
  load: 'Load',
  unitSmoothness: ' : < 1.5 um',
  uniGloss: ' : > 40%',
  unitBrightness: ' : Std. +/- 2',
  unitMoisture: ' : 7.5 +/- 1.5% Diferenciada',
  grammageNote: 'Grammage',
  unitGrammageNote: 'Std. +/-5%',
  stiffnessNotes: 'Stiffness',
  unitStiffness: ' : Std. - 10%'
});
