import i18next from 'i18next';

i18next.addResources('es', 'ArticleSelectors', {
  diameter: 'Diametro (cm)',
  diameterPlaceholder: 'Ingrese el diametro',
  length: 'Largo (cm)',
  lengthPlaceholder: 'Ingrese el largo',
  width: 'Ancho (cm)',
  widthPlaceholder: 'Ingrese el ancho',
  core: 'Caño (pulg)',
  packaging: 'Embalaje',
  corePlaceholder: 'Ingrese el valor de caño',
  sheets: 'Número de hojas',
  sheetsPlaceholder: 'Ingrese el número de hojas',
  pallet: 'Tipo de pallet',
  withInserters: 'Con insertadores',
  inserters: 'Insertadores/Hojas',
  withReams: 'Enresmado',
  reams: 'Emresmado/Hojas',
  minLength: 'El largo mínimo es {{length}} cm',
  maxLength: 'El largo máximo es {{length}} cm',
  notEmptyLength: 'Debe ingresar primero el largo',
  minWidth: 'El ancho mínimo es {{width}} cm',
  maxWidth: 'El ancho máximo es {{width}} cm',
  widthLessThanLength: 'El ancho debe ser menor al largo',
  maxByPaperHeight: 'El ancho multiplicado por 3 es menor al alto de la pila',
  ruleOfThreeWidthViolation: 'El ancho multiplicado por 3 es menor al alto de la pila.',
  ruleOfThreeLenghtViolation: 'El largo multiplicado por 3 es menor al alto de la pila.',
  ruleOfThreeBothViolation: 'El ancho y largo multiplicado por 3 es menor al alto de pila.',
  maxHeighViolation: 'Se ha excedido la altura máxima de la pila.',
  maxheight: 'La altura de la pila supera el máximo permitido.',
  stackHeight: 'Altura estimada: {{height}} cm ',
  height: 'Altura (cm)',
  minSheets: 'El mínimo número de hojas para este papel es {{sheets}}',
  maxSheets: 'El máximo número de hojas para este papel es {{sheets}}',
  minHeight: 'La altura minima es de 100 cm',
  maxHeight: 'La altura máxima es de 170 cm',
  multipleOf100: 'El valor debe ser múltiplo de 100',
  minInserters: 'El número mínimo es {{inserters}}',
  maxInserters: 'El número máximo es {{inserters}}',
  sheetsMultiple: 'El número debe ser múltiplo del número de hojas',
  tierError: 'No se pudo encontrar una planta asociada. Por favor revisa la familia y papel seleccionado.',
  infoPaper: 'El largo de la pila define el sentido de la fibra'
});

i18next.addResources('en', 'ArticleSelectors', {
  diameter: 'Diameter (cm)',
  diameterPlaceholder: 'Enter the diameter',
  length: 'Length (cm)',
  lengthPlaceholder: 'Enter the length',
  width: 'Width (cm)',
  widthPlaceholder: 'Enter width',
  core: 'Core (inch)',
  packaging: 'Wrapper',
  corePlaceholder: 'Enter the core value',
  sheets: 'Sheets',
  sheetsPlaceholder: 'Enter the number of sheets',
  pallet: 'Pallet Type',
  withInserters: 'With inserters',
  inserters: 'Inserters/Sheets',
  withReams: 'With reams',
  reams: 'Reams/Sheets',
  minLength: 'The minimum length is {{length}} cm',
  maxLength: 'The maximum length is {{length}} cm',
  notEmptyLength: 'You must enter the length first',
  minWidth: 'The minimum width is {{width}} cm',
  maxWidth: 'The maximum width is {{width}} cm',
  widthLessThanLength: 'The width must be less than the length',
  maxByPaperHeight: 'The width multiplied by 3 is lower than the high pile',
  ruleOfThreeWidthViolation: 'The width multiplied by 3 is lower than the high pile.',
  ruleOfThreeLenghtViolation: 'The length multiplied by 3 is less than the high pile.',
  ruleOfThreeBothViolation: 'The width and length multiplied by 3 is lower than the high battery.',
  maxHeighViolation: 'Maximum stack height has been exceeded.',
  maxheight: 'The height of the battery exceeds the maximum allowed.',
  stackHeight: 'Estimated height: {{height}} cm ',
  height: 'Height',
  minSheets: 'The minimum number of sheets for this paper is {{sheets}}',
  maxSheets: 'The maximum number of sheets for this paper is {{sheets}}',
  minHeight: 'The minimum height is 100 cm',
  maxHeight: 'The maximum height is 170 cm',
  multipleOf100: 'The value must be a multiple of 100',
  minInserters: 'The minimum number is {{inserters}}',
  maxInserters: 'The maximum number is {{inserters}}',
  sheetsMultiple: 'The number must be a multiple of the number of sheets',
  tierError: 'Could not find a partner plant. Please check the selected family and paper.',
  infoPaper: 'The length of the pile defines the direction of the fiber'
});
