import i18next from 'i18next';

i18next.addResources('es', 'ValidateProforma', {
  proformaValidation: 'Validación proforma',
  verifyDocument:
    'Por favor descarga la proforma y verifica que los datos detallados en la misma sean correctos.',
  downloadProforma: 'Descargar proforma',
  ifAgree: 'Si estás de acuerdo haz clic en',
  validateProforma: 'Validar',
  otherwise: 'para que el pedido continúe su proceso. De lo contrario haz clic en',
  reject: 'Rechazar',
  validatedResponse: '¡Proforma validado con éxito!',
  rejectedResponse: '¡Proforma rechazada!',
  apiError: 'No fue posible enviar la validación. Intenta mas tarde',
  proformaError: 'No se ha podido obtener la proforma. Intenta mas tarde'
});

i18next.addResources('en', 'ValidateProforma', {
  proformaValidation: 'Proforma validation',
  verifyDocument: 'Please download the proforma and verify that the details in it are correct.',
  downloadProforma: 'Download proforma',
  ifAgree: 'If you agree, click',
  validateProforma: 'Validate',
  otherwise: 'for the order to continue its process. Otherwise click',
  reject: 'Reject',
  validatedResponse: 'Proforma successfully validated!',
  rejectedResponse: 'Proforma rejected!',
  apiError: 'It was not possible to send the validation. Try later',
  proformaError: 'The proforma could not be obtained. Try later'
});
