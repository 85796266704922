import i18next from 'i18next';

i18next.addResources('es', 'csat', {
  title: 'Feedback',
  hello: '¡Hola {{ userName }}!',
  dontKnow: 'No sabe',
  dontAsk: 'No quiero participar',
  close: 'cerrar',
  send: 'enviar',
  helpus: 'Ayúdanos a mejorar',
  writeComment: 'Escribe un comentario',
  thankyou: 'Gracias por participar, nos ayudas a seguir mejorando',
  boxboardTeam: 'Equipo Fiber Place Boxboard'
});
i18next.addResources('en', 'csat', {
  title: 'Feedback',
  hello: '¡Hi {{ userName }}!',
  dontKnow: "Don't know",
  dontAsk: "I don't want to participate",
  close: 'Close',
  send: 'Send',
  helpus: 'Help us to improve',
  writeComment: 'Write a comment',
  thankyou: 'Thank you for participating, you help us to keep improving',
  boxboardTeam: 'Fiber Place Team - Boxboard'
});
