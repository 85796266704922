import i18next from 'i18next';

i18next.addResources('es', 'ReportProblem', {
  reportProblem: 'Reportar problema',
  sentSuccessfully: 'Reporte de problema enviado correctamente',
  apiError: 'Ocurrió un error, por favor intente nuevamente'
});

i18next.addResources('en', 'ReportProblem', {
  reportProblem: 'Report problem',
  sentSuccessfully: 'Problem report sent successfully',
  apiError: 'An error occurred, please try again'
});
