import i18next from 'i18next';

i18next.addResources('es', 'StockControlScreen', {
  title: 'Control de Stock',
  listUnity: 'Listado de Unidades',
  errorDownloadExcel: 'Error en la descarga',
  listStock: 'Listado de Stock',
  download: 'Descargar excel'
});

i18next.addResources('en', 'StockControlScreen', {
  title: 'Stock Control',
  listUnity: 'Units',
  download: 'Download excel',
  errorDownloadExcel: 'Download failed',
  listStock: 'Stock list'
});

i18next.addResources('es', 'StockControlHeaders', {
  location: 'Ubicación',
  customer: 'Cliente',
  order: 'Pedido',
  item: 'Ítem',
  units: 'Unidades',
  tons: 'Ton',
  antiquity: 'Antigüedad',
  unityKind: 'Tipo de unidad',
  productFamily: 'Familia de producto',
  purchaseOrder: 'Orden de compra',
  country: 'País',
  format: 'Formato',
  from: 'Desde',
  to: 'Hasta',
  searchFilter: 'Filtro de búsqueda',
  applyFilter: 'Aplicar filtro'
});

i18next.addResources('en', 'StockControlHeaders', {
  location: 'Location',
  customer: 'Customer',
  order: 'Order',
  item: 'Item',
  units: 'Units',
  tons: 'Tons',
  antiquity: 'Oldest',
  unityKind: 'Unit',
  productFamily: 'Product',
  purchaseOrder: 'Purchase Order',
  country: 'Country',
  format: 'Format',
  from: 'From',
  to: 'To',
  searchFilter: 'Filter',
  applyFilter: 'Apply Filter'
});

i18next.addResources('es', 'StockExcel', {
  headers: [
    [
      'Cliente',
      'Consignatario',
      'Pedido',
      'Orden de Compra',
      'Ítem',
      'Ubicación',
      'Código de papel',
      'Descripción producto',
      'Tipo de unidad',
      'Ancho',
      'Diámetro',
      'Caño',
      'Embalaje',
      'Largo',
      'N de hojas',
      'Pallet',
      'Estado de pedido',
      'Unidades disponibles',
      'Unidades pedido',
      'Unidades gestionadas',
      'Unidades bloqueadas',
      'Ton disponible',
      'Ton pedido',
      'Ton gestionadas',
      'Ton bloqueadas',
      'Tiempo en bodega',
      'Certificación',
      'Mercado',
      'País',
      'Entrega',
      'Transporte'
    ]
  ]
});

i18next.addResources('en', 'StockExcel', {
  headers: [
    [
      'Client',
      'Consignee',
      'Order',
      'Purchase Order',
      'Item',
      'Location',
      'Paper code',
      'Product description',
      'Type of unit',
      'Width',
      'Diameter',
      'Spout',
      'Packaging',
      'Length',
      'N of leaves',
      'Pallet',
      'Unit Status',
      'Units available',
      'Ordering units',
      'Managed units',
      'Blocked units',
      'Ton available',
      'Ton request',
      'Ton managed',
      'Ton blocked',
      'Warehouse time',
      'Certification',
      'Market',
      'Country',
      'Delivery',
      'Transport'
    ]
  ]
});
