import i18next from 'i18next';

i18next.addResources('es', 'HomeScreen', {
  title: 'Inicio',
  hello: '¡Hola!',
  gretting: 'Te damos la bienvenida a nuestra Plataforma Fiber Place Boxboard.',
  instructions:
    'Por favor selecciona, el módulo que deseas consultar y descubre cómo Fiber Place Boxboard puede enriquecer tu experiencia digital.',

  ordersTitle: 'Listado de pedidos',
  ordersDescription: 'Accede al estado y detalle de todos los pedidos',
  ordersButton: 'Ver pedidos',

  usersTitle: 'Listado de usuarios',
  usersDescription: 'Consulta el listado de todos los usuarios asociados a Fiber Place',
  usersButton: 'Ver usuarios',

  articlesTitle: 'Artículos',
  articlesDescription: 'Valida los nuevos artículos creados desde las órdenes de compra',
  articlesButton: 'Ver artículos',

  cyclesTitle: 'Ciclos de producción',
  cyclesDescription: 'Conoce las fechas de comienzo y cierre de cada ciclo de producción',
  cyclesButton: 'Ver ciclos',

  certificatesTitle: 'Certificados de calidad',
  certificatesDescription: 'Ingresa y busca los certificados de tu interés',
  certificatesButton: 'Ver certificados',

  stockControlTitle: 'Control de Stock',
  stockControlDescription: 'Consulta el estado de tu stock en línea disponible en las bodegas de CMPC',
  stockControlButton: 'Ver listado de unidades',

  creditStatusTitle: 'Estatus crediticio',
  creditStatusDescription: 'Infórmate sobre el detalle del estado crediticio y los riesgos por agotamiento',
  creditStatusButton: 'Ver resumen clientes',

  documentsTitle: 'Documentos',
  documentsDescription: 'Consulta los documentos asociados a tus pedidos, embarques fichas técnicas y otros',
  documentsButton: 'Ver documentos',

  helpCenterTitle: 'Centro de ayuda',
  helpCenterDescription: 'Resuelve tus dudas, encuentra información útil y actualizada',
  helpCenterButton: 'Ir a centro de ayuda',

  trackingTitle: 'Módulo de tracking',
  trackingDescription: 'Consulta el estado de tus embarques y realiza el seguimiento en linea',
  trackingButton: 'Ver embarques',

  adminTitle: 'Administrador Fiber Place',
  adminDescription: 'Gestiona herramientas internas de Fiber Place',
  adminButton: 'Ir al admin'
});

i18next.addResources('en', 'HomeScreen', {
  title: 'Home',
  hello: '¡Hello!',
  gretting: 'Welcome to the Fiber Place Boxboard platform.',
  instructions:
    'Please select the option you wish to consult and discover how Fiber Place Boxboard can enrich your digital experience.',

  ordersTitle: 'Order list',
  ordersDescription: 'Access the status and details of all orders',
  ordersButton: 'See Order list',

  usersTitle: 'List of users',
  usersDescription: 'Consult the list of all the users associated with Fiber Place',
  usersButton: 'See list of users',

  articlesTitle: 'Articles',
  articlesDescription: 'Validates new articles created from purchase orders',
  articlesButton: 'See articles',

  cyclesTitle: 'Production cycles',
  cyclesDescription: 'Check the start and end dates of each production cycles',
  cyclesButton: 'See cycles',

  certificatesTitle: 'Certificates',
  certificatesDescription: 'Enter and search for the certificates of your interest',
  certificatesButton: 'See certificates',

  stockControlTitle: 'Stock control',
  stockControlDescription: 'Check your stock status online at CMPC warehouses.',
  stockControlButton: 'See stock control',

  creditStatusTitle: 'Credit status',
  creditStatusDescription: 'Find out the details of the credit status and exhaustion risks',
  creditStatusButton: 'See Credit status',

  documentsTitle: 'Documents',
  documentsDescription: 'View documents related to your orders, shipments, certificates, and more...',
  documentsButton: 'See documents',

  helpCenterTitle: 'Help Center',
  helpCenterDescription: 'Solve your doubts, find useful and updated information',
  helpCenterButton: 'Go to help center',

  trackingTitle: 'Tracking',
  trackingDescription: 'Check the status of your shipments and tracking online',
  trackingButton: 'See tracking',

  adminTitle: 'Fiber Place Administrator',
  adminDescription: 'Manages Fiber Place internal tools',
  adminButton: 'Go to admin'
});
