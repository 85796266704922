import React from 'react';

import { UserType } from 'types/User';

import UserTypePermissionContext from './index';

type Props = {
  userType: UserType | undefined;
  children: React.ReactNode;
};

const UserTypePermissionProvider: React.FunctionComponent<Props> = ({ userType, children }: Props) => {
  const isAllowedTo = (allowedUserTypes: Array<UserType>): Promise<boolean> =>
    new Promise(resolve => {
      resolve(userType === undefined ? false : allowedUserTypes.includes(userType));
    });
  return (
    <UserTypePermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </UserTypePermissionContext.Provider>
  );
};

export default UserTypePermissionProvider;
