import i18next from 'i18next';

i18next.addResources('es', 'OrderDestination', {
  destination: 'Destino',
  origin: 'Origen',
  destinationOrder: 'Destino del pedido'
});

i18next.addResources('en', 'OrderDestination', {
  destination: 'Destination',
  origin: 'Origin',
  destinationOrder: 'Order destination'
});
