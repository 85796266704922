import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { Nullable } from 'types/Global';
import { RateFaces } from '../RateFaces/RateFaces';

import styles from './styles.module.scss';

const CHARACTER_LIMIT = 300;

interface ICommentsComponent {
  handleSendData: () => void;
  setValueFace: (value: Nullable<number>) => void;
  setCommentaries: (value: string) => void;
  handleSkipSession: () => void;
  commentaries: string;
  valueFace: Nullable<number>;
}

export const Comments = ({
  handleSendData,
  setValueFace,
  setCommentaries,
  handleSkipSession,
  commentaries,
  valueFace = 0
}: ICommentsComponent) => {
  const { t } = useTranslation('csat');

  const handleChangeCommentaries = (value: string) => {
    setCommentaries(value);
  };

  const showSendButton = () => {
    if (valueFace !== null && valueFace >= 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={styles.contentComment}>
        <RateFaces setValue={setValueFace} />
        <span className={styles.helpUs}>{t('helpus')}</span>
        <TextField
          variant="outlined"
          placeholder={t('writeComment')}
          classes={{ root: styles.commentBox }}
          multiline
          inputProps={{
            maxLength: CHARACTER_LIMIT,
            style: { fontSize: 12 },
            'data-testid': 'comments-test'
          }}
          FormHelperTextProps={{ style: { alignSelf: 'flex-end' } }}
          helperText={`${commentaries.length}/${CHARACTER_LIMIT}`}
          rows={7}
          rowsMax={7}
          onChange={e => handleChangeCommentaries(e.target.value)}
        />
      </div>
      <div className={styles.footerStep}>
        <span className={styles.closeButton} onClick={handleSkipSession}>
          {t('close')}
        </span>
        {showSendButton() ? (
          <span className={styles.sendButton} onClick={handleSendData} data-testid="send-test">
            {t('send')}
          </span>
        ) : (
          <span className={styles.disableButton} data-testid="send-test">
            {t('send')}
          </span>
        )}
      </div>
    </>
  );
};
