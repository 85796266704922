import i18next from 'i18next';

i18next.addResources('es', 'StockControlCards', {
  skid: 'Pila',
  roll: 'Rollo',
  location: 'Ubicación',
  order: 'Pedido',
  item: 'Ítem',
  units: 'Unidades',
  tons: 'Ton',
  boxboard: 'Cartulina',
  available: 'Disponibles',
  warehouse: 'Bodega',
  transit: ' Tránsito',
  width: 'Ancho',
  length: 'Largo',
  ordered: 'Pedido',
  sheet: 'N de Hojas',
  managed: 'Gestionadas',
  edit: 'Editar',
  client: 'Cliente',
  consignee: 'Consignatario',
  oc: 'OC',
  timeInWarehouse: 'Tiempo en bodega',
  certification: 'Certificación',
  diameter: 'Diámetro',
  tube: 'Caño',
  packaging: 'Embalaje',
  noData: 'Sin datos',
  standart: 'Estándar',
  laps: 'KPK1.25 vueltas',
  cm: 'cm'
});

i18next.addResources('en', 'StockControlCards', {
  skid: 'Stack',
  roll: 'Roll',
  location: 'Location',
  order: 'Order',
  item: 'item',
  units: 'Units',
  tons: 'Tons',
  boxboard: 'Boxboard',
  available: 'Available',
  warehouse: 'Warehouse',
  transit: 'Transit',
  width: 'Width',
  length: 'Length',
  ordered: 'Order',
  sheet: 'Sheets',
  managed: 'Managed',
  edit: 'Edit',
  client: 'Customer',
  consignee: 'Consignee',
  oc: 'Purchase order',
  timeInWarehouse: 'Time in warehouse',
  certification: 'Certification',
  diameter: 'Diameter',
  tube: 'Core',
  packaging: 'Wrap',
  noData: 'Without data',
  standart: 'Standart',
  laps: 'KPK1.25 laps',
  cm: 'in'
});
