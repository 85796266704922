import i18next from 'i18next';

i18next.addResources('es', 'AccountSettings', {
  account: 'Cuenta',
  config: 'Configuraciones',
  submit: 'Guardar cambios',
  msgSuccess: 'Cambios de configuración guardados correctamente',
  msgError: 'Ocurrió un error por favor intente nuevamente'
});

i18next.addResources('en', 'AccountSettings', {
  account: 'Account',
  config: 'Settings',
  submit: 'Save changes',
  msgSuccess: 'Configuration changes successfully saved',
  msgError: 'An error occurred. Please try again'
});
