import React from 'react';
import Spinner, { SpinnerProps } from 'react-spinkit';

import SpinnerFiberPlace from 'components/SpinnerFiberPlace';

import styles from './styles.module.scss';

interface Props extends SpinnerProps {
  type?: string;
}
function Loading({ className = '', name = 'ball-beat', type = 'spinkit', color, ...rest }: Props) {
  return (
    <>
      {type === 'fiber-place' ? (
        <>
          <SpinnerFiberPlace className="row center middle full-width full-height central" />
        </>
      ) : (
        <>
          <Spinner
            className={`${styles.spinner} ${className}`}
            name={name}
            color={color}
            fadeIn="half"
            {...rest}
          />
        </>
      )}
    </>
  );
}

export default Loading;
