/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-spinkit';

import { Nullable } from 'types/Global';
import { UserTypeId } from 'types/User';
import { postCsatFace, putCsatFace } from 'services/CsatService';
import { useLazyRequest } from 'hooks/useRequest';
import useScreenResolution from 'hooks/useScreenResolution';
import { IFormatData } from 'types/Csat';
import { useLocation } from 'react-router';

import { Faces } from '../components/Faces/Faces';
import { Comments } from '../components/Comments/Comments';
import { FinalScreenCsat } from '../components/Final/FinalScreenCsat';
import { resetFaces } from '../components/utils';
import styles from './styles.module.scss';

interface ICSAT {
  userId: number;
  userType: UserTypeId;
  mail: string;
  userName: string;
  dataCsat: IFormatData[];
}
interface IQuestionSurvey {
  idQuestion: number;
  question: string;
}
const SECONDS_TO_HIDE = 5000;
const FACES_STEP = 1;
const COMMENTS_STEP = 2;
const FINAL_STEP = 3;

export const Csat = ({ userId, userType, mail, userName, dataCsat }: ICSAT) => {
  const { t } = useTranslation('csat');
  const { pathname } = useLocation();
  const [display, setDisplay] = useState<boolean>(false);
  const [openCsat, setOpenCsat] = useState<boolean>(false);
  const [valueFace, setValueFace] = useState<Nullable<number>>(null);
  const [commentaries, setCommentaries] = useState('');
  const [question, setQuestion] = useState<IQuestionSurvey>({ idQuestion: 0, question: '' });
  const [idAnswer, setIdAnswer] = useState<number>(0);
  const [idSurvey, setIdSurvey] = useState<number>(0);
  const [steps, setSteps] = useState<number>(FACES_STEP);
  const { isResponsive } = useScreenResolution();
  // eslint-disable-next-line init-declarations
  let dataSurvey: Nullable<IFormatData>;

  const handleOpenCsat = (status: boolean) => {
    setSteps(FACES_STEP);

    if (status === false) {
      setOpenCsat(true);
    } else {
      setOpenCsat(false);
      removeSurvey();
      hideCsat();
    }
  };

  const handleSendData = () => {
    putAnswer(true);
  };

  const putAnswer = (changeStep?: boolean) => {
    if (valueFace !== null && valueFace >= 0) {
      // eslint-disable-next-line babel/no-unused-expressions
      changeStep && setSteps(FINAL_STEP);
      putFace({
        idAnswer,
        body: {
          answer: String(valueFace + 1),
          comments: commentaries
        }
      });
    }
  };

  const hideCsat = () => {
    setDisplay(false);
  };

  const showCsat = () => {
    setDisplay(true);
  };

  const handleSkipSession = () => {
    hideCsat();
    if (steps === 1) {
      postFace({
        idQuestion: question.idQuestion,
        idUser: userId,
        usrName: userName,
        usrRol: String(userType),
        usrMail: mail,
        answer: '',
        comments: '',
        skipSurvey: true,
        idSurvey
      });
    }
  };

  const [, loadingPostFace, , putFace] = useLazyRequest({
    request: putCsatFace,
    withPostSuccess: () => removeSurvey()
  });

  const [, loadingPutCsat, , postFace] = useLazyRequest({
    request: postCsatFace,
    withPostSuccess: response => {
      if (response?.data) {
        const { data } = response;
        setIdAnswer(data.idAnswer);
        removeSurvey();
      }
    }
  });

  const getSurveyData = (): IFormatData | null => {
    if (pathname === '/home') {
      return dataCsat.filter(item => item.module === '/')[0];
    }
    if (dataCsat.some(item => item.module === '/')) {
      return dataCsat.filter(item => item.module === '/')[0];
    }
    if (dataCsat.some(item => item.module.split('/')[1] === pathname.split('/')[1])) {
      return dataCsat.filter(item => item.module.split('/')[1] === pathname.split('/')[1])[0];
    }
    if (
      dataCsat.some(item => item.module.split('/')[1] === 'articles') &&
      pathname.split('/')[1] === 'article'
    ) {
      return dataCsat.filter(item => item.module.split('/')[1] === 'articles')[0];
    }
    return null;
  };

  const removeSurvey = () => {
    dataSurvey = getSurveyData();
    const storageData: IFormatData[] = JSON.parse(window.sessionStorage.getItem('csat') ?? '');
    const newData = storageData.filter(item => item.idSurvey !== dataSurvey?.idSurvey);
    window.sessionStorage.setItem('csat', JSON.stringify(newData));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dataSurvey = getSurveyData();

    if (dataSurvey) {
      setQuestion({ idQuestion: dataSurvey.idQuestion, question: dataSurvey.question });
      setIdSurvey(dataSurvey.idSurvey);
      showCsat();
    } else {
      hideCsat();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (valueFace !== null && valueFace >= 0 && steps === FACES_STEP) {
      if (!isResponsive) {
        setSteps(COMMENTS_STEP);
      }
      if (idAnswer) {
        putAnswer();
      } else {
        postFace({
          idQuestion: question.idQuestion,
          idUser: userId,
          usrName: userName,
          usrRol: String(userType),
          usrMail: mail,
          answer: String(valueFace + 1),
          comments: commentaries,
          skipSurvey: false,
          idSurvey
        });
      }
    }
    if (steps === COMMENTS_STEP) {
      // eslint-disable-next-line babel/no-unused-expressions
      idAnswer !== 0 && putAnswer();
    }
    if (steps === FINAL_STEP) {
      setValueFace(null);
      resetFaces();
      setTimeout(() => {
        hideCsat();
      }, SECONDS_TO_HIDE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFace, steps]);

  const renderFirstStep = () => {
    if (isResponsive) {
      return (
        <>
          <Faces
            name={userName}
            handleSkipSession={handleSkipSession}
            setValueFace={setValueFace}
            question={question.question}
            isResponsive={isResponsive}
          />
          <Comments
            valueFace={valueFace}
            commentaries={commentaries}
            handleSendData={handleSendData}
            setCommentaries={setCommentaries}
            handleSkipSession={handleSkipSession}
            setValueFace={setValueFace}
          />
        </>
      );
    }
    return (
      <Faces
        name={userName}
        handleSkipSession={handleSkipSession}
        setValueFace={setValueFace}
        question={question.question}
        isResponsive={isResponsive}
      />
    );
  };

  // eslint-disable-next-line consistent-return
  const loadingSpinner = () => {
    if (loadingPostFace || loadingPutCsat) {
      return (
        <div className={styles.loading}>
          <Spinner className={styles.spinner} name="ball-beat" fadeIn="half" />
        </div>
      );
    }
  };

  return (
    <div className={display ? styles.container : styles.hide}>
      <div
        className={isResponsive && openCsat ? styles.headerMobile : styles.header}
        onClick={() => handleOpenCsat(openCsat)}
        data-testid="open-csat-test"
      >
        <span className={styles.text}>{t('title')}</span>
        <i className={`icon-ic-chevron ${openCsat ? styles.arrowOpen : styles.arrow}`} />
      </div>
      {openCsat && (
        <>
          <div className={styles.hiddenContent}>
            {loadingSpinner()}
            {steps === FACES_STEP && renderFirstStep()}
            {steps === COMMENTS_STEP && !isResponsive && (
              <Comments
                valueFace={valueFace}
                commentaries={commentaries}
                handleSendData={handleSendData}
                setCommentaries={setCommentaries}
                handleSkipSession={handleSkipSession}
                setValueFace={setValueFace}
              />
            )}
            {steps === FINAL_STEP && <FinalScreenCsat />}
          </div>
        </>
      )}
    </div>
  );
};
