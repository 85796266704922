import React, { useReducer, useEffect, useState, ComponentType } from 'react';
import { ToastProvider } from 'react-toast-notifications';

import Routes from 'components/Routes';
import Loading from 'components/Loading';
import Toast from 'components/Toast';
import { setMonitor, STATUS_CODES } from 'config/api';
import { Context as UserContext } from 'contexts/UserContext';
import { reducer as userReducer, INITIAL_STATE, actionCreators } from 'contexts/UserContext/reducer';
import { Context as SidebarContext } from 'contexts/SidebarContext';
import {
  reducer as sidebarReducer,
  INITIAL_STATE as SIDEBAR_INITIAL_STATE
} from 'contexts/SidebarContext/reducer';
import { Context as NotificationsContext } from 'contexts/NotificationsContext';
import {
  reducer as notificationsReducer,
  INITIAL_STATE as NOTIFICATIONS_INITIAL_STATE,
  actionCreators as notificationsActionCreators
} from 'contexts/NotificationsContext/reducer';
import { useLazyRequest } from 'hooks/useRequest';
import {
  getNotifications,
  NotificationsParams,
  UNREAD_NOTIFICATIONS_SIZE
} from 'services/NotificationService';
import { getProfile } from 'services/NewUserService';
import { logout } from 'services/UserService';
import { Profile } from 'types/User';
import { NotificationsType } from 'types/Notifications';
import { getSavedUser } from 'utils/session';
import { changeLanguage } from 'utils/i18n';
import UserTypePermissionContext from 'contexts/UserTypePermissionContext/provider';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import mixpanel from 'mixpanel-browser';
import ldClient from '../../ldClient';
// Import Mixpanel SDK

function App() {
  const [userState, userDispatch] = useReducer(userReducer, INITIAL_STATE);
  const [sidebarState, sidebarDispatch] = useReducer(sidebarReducer, SIDEBAR_INITIAL_STATE);
  const [notificationsState, notificationsDispatch] = useReducer(
    notificationsReducer,
    NOTIFICATIONS_INITIAL_STATE
  );
  const [loading, setLoading] = useState(true);

  const [, , , getNotificationsRequest] = useLazyRequest({
    request: ({ read, userIdOwner, userType, languageId }: NotificationsParams) =>
      getNotifications({
        userIdOwner,
        read,
        page: 0,
        size: UNREAD_NOTIFICATIONS_SIZE,
        userType,
        languageId
      }),
    withRawResponse: response => {
      if (response) {
        const { data, metadata } = response.data as any;
        const notificationsResponse = { ...data, ...metadata?.pagination } as NotificationsType;
        notificationsDispatch(notificationsActionCreators.setIsLoading());
        notificationsDispatch(notificationsActionCreators.setUnreadNotifications(notificationsResponse));
        notificationsDispatch(notificationsActionCreators.setError(false));
      }
    },
    withPostFailure: () => {
      notificationsDispatch(notificationsActionCreators.setIsLoading());
      notificationsDispatch(notificationsActionCreators.setError(true));
    }
  });

  const [, , , getUserMe] = useLazyRequest({
    request: getProfile,
    withRequSuccess: response => {
      if (response) {
        const profile = response as Profile;
        const lang = (profile.languageId && profile.languageId === 'ESP' ? 'es' : 'en') || '';
        changeLanguage(lang);
        const { id, userType } = profile;
        if (profile.activeFlag) {
          userDispatch(actionCreators.setProfile(profile));
          notificationsDispatch(notificationsActionCreators.setIsLoading());
          getNotificationsRequest({ read: false, userIdOwner: id, userType, languageId: lang });
        } else {
          logout();
        }
      }
    },
    withPostFailure: () => {
      setLoading(false);
      logout();
    },
    withPostFetch: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (window.localStorage.getItem('logout')) {
      window.localStorage.removeItem('logout');
      logout();
    }
    const savedUser = getSavedUser();
    if (savedUser) {
      getUserMe();
    } else {
      setLoading(false);
    }

    setMonitor(logout, STATUS_CODES.unauthorized);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Near entry of your product, init Mixpanel
  mixpanel.init(process.env.REACT_APP_MIXPANEL_SDK_CLIENT!, {
    debug: true,
    // eslint-disable-next-line camelcase
    track_pageview: true,
    persistence: 'localStorage'
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const toastProviderComponents = { Toast };
  return (
    <UserContext.Provider value={{ state: userState, dispatch: userDispatch }}>
      <UserTypePermissionContext userType={userState.user?.userType}>
        <SidebarContext.Provider value={{ state: sidebarState, dispatch: sidebarDispatch }}>
          <NotificationsContext.Provider
            value={{ state: notificationsState, dispatch: notificationsDispatch }}
          >
            <ToastProvider
              components={toastProviderComponents}
              placement="top-center"
              transitionDuration={200}
              autoDismiss
            >
              {loading ? <Loading type="fiber-place" /> : <Routes />}
            </ToastProvider>
          </NotificationsContext.Provider>
        </SidebarContext.Provider>
      </UserTypePermissionContext>
    </UserContext.Provider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT!,
  ldClient
  // eslint-disable-next-line @typescript-eslint/ban-types
})(App as ComponentType<{}>);
