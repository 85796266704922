import i18next from 'i18next';

i18next.addResources('es', 'FeedbackButton', {
  allWentWell: 'Todo salió bien',
  somethingWentWrong: 'Algo salió mal',
  detailsNotProvided: 'El cliente no ha proporcionado detalles sobre este despacho.'
});

i18next.addResources('en', 'FeedbackButton', {
  allWentWell: 'All went well',
  somethingWentWrong: 'Something went wrong',
  detailsNotProvided: 'The client has not provided details about this delivery.'
});
