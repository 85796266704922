import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface Props {
  onClick: (item: number) => (event: React.MouseEvent<HTMLElement>) => void;
  id: number;
  itemSelected: number;
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  itemComplete: boolean;
  markAsSelected?: boolean;
}

function Item({
  id,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  icon: IconComponent,
  name,
  onClick,
  itemComplete,
  itemSelected,
  markAsSelected = true
}: Props) {
  const { t } = useTranslation();
  return (
    <button
      key={id}
      type="button"
      name={name}
      className={cn('row middle', [styles.item], { [styles.itemSelected]: id === itemSelected })}
      onClick={onClick(id)}
    >
      <IconComponent
        className={cn('m-right-2', [styles.itemIcon], {
          [styles.selected]: id === itemSelected && markAsSelected
        })}
      />
      <p className={cn('text', [styles.itemText], { [styles.itemTextHover]: itemComplete })}>
        {t(`Sidebar:${name}`)}
      </p>
    </button>
  );
}

export default Item;
