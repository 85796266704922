import api, { apiBff } from 'config/api';
import { ApiError } from 'types/Global';
import { PaginationParams, UserListResponse, SelectResponse } from 'types/Pagination';
import { Profile, ClientAgentsResponse, CreateUserData, UserData } from 'types/User';
import { removeSavedUser } from 'utils/session';
import { removeFalsyProps } from '../utils/objectFn';

export const usersBaseURL = `${api.getBaseURL() || ''}`;

export const getProfile = () => api.get<Profile, ApiError>(`${usersBaseURL}/users/me`);

export const logout = () => {
  window.sessionStorage.removeItem('csat');
  removeSavedUser();
};

export const createUser = (user: CreateUserData) =>
  api.post<void, ApiError>(`${usersBaseURL}/users`, { ...user });

export const editUser = ({ id, ...user }: CreateUserData) =>
  api.put<UserData, ApiError>(`${usersBaseURL}/users/${id}`, { ...user });

export const getClients = ({ search, page }: PaginationParams) =>
  api.get<SelectResponse, ApiError>(
    `${usersBaseURL}/users/customers`,
    removeFalsyProps({ search, page, size: 5 })
  );

export const getClientAgents = (client: string) =>
  api.get<ClientAgentsResponse, ApiError>(`${usersBaseURL}/users/${client.trim()}/agents`);

export const getClientConsignees = ({ search, page }: PaginationParams, client: string) =>
  api.get<SelectResponse, ApiError>(
    `${usersBaseURL}/users/customers/${client.trim()}/consignees`,
    removeFalsyProps({
      search,
      page,
      size: 10
    })
  );

export const getUsers = ({
  search = undefined,
  size = undefined,
  page = undefined,
  sort = undefined
}: PaginationParams) =>
  api.get<UserListResponse>(`${usersBaseURL}/users`, {
    search,
    size,
    page,
    sort
  });

export const enableUser = (id: number, enable: boolean) =>
  api.put(`${usersBaseURL}/enable-users/${id}`, {
    activeFlag: enable
  });

export const getUser = (id: number) => api.get<UserData, ApiError>(`${usersBaseURL}/users/${id}`);

export const getCustomer = (id: string | string[] | undefined) =>
  api.get<UserData, ApiError>(`${usersBaseURL}/users/customers/${id}`);

export const getAgents = ({ search, page, ...userParams }: PaginationParams) =>
  api.get<SelectResponse, ApiError>(`${usersBaseURL}/users/agents`, { search, page, ...userParams });

export const getConsignees = ({ search, page }: PaginationParams) =>
  api.get<SelectResponse, ApiError>(
    `${usersBaseURL}/consignees`,
    removeFalsyProps({
      search,
      page,
      size: 10
    })
  );

export const getStockWarehouses = () => apiBff.get<SelectResponse, ApiError>('/warehouse');

export const getWarehousesByClient = (id: number | undefined) =>
  apiBff.get<any, ApiError>(`/warehouse/${id}`);

export default api;
