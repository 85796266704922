import { formatData } from 'modules/CSAT/components/utils';
import React, { useEffect, useState } from 'react';

import { IFormatData, DataCsat } from 'types/Csat';
import { User, UserTypeId } from 'types/User';
import { useLazyRequest } from 'hooks/useRequest';
import { getCsatModularized } from 'services/CsatService';
import { Csat } from '..';

interface IModuleCSAT {
  user: User;
}

export const ModuleCSAT = ({ user }: IModuleCSAT) => {
  const parseCsatInfo: string = window.sessionStorage.getItem('csat') ?? '';
  const [dataCsat, setDataCsat] = useState<IFormatData[]>();

  const [, , , requestCsatModularized] = useLazyRequest({
    request: getCsatModularized,
    withPostSuccess: response => {
      if (response?.data) {
        const { data } = response;

        setDataCsat(formatData((data as unknown) as DataCsat[]));

        window.sessionStorage.setItem('csat', JSON.stringify(response.data));
      }
    }
  });

  useEffect(() => {
    if (parseCsatInfo.length === 0) {
      requestCsatModularized({
        userId: user.id ?? 0,
        languageId: user.languageId ?? 'ENG'
      });
    } else {
      setDataCsat(
        formatData((JSON.parse(window.sessionStorage.getItem('csat') ?? '') as unknown) as DataCsat[])
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCsat = () =>
    dataCsat !== undefined && (
      <Csat
        userId={user.id ?? 0}
        userType={user?.userTypeId ?? UserTypeId.AGENT}
        mail={user?.email ?? ''}
        userName={user?.name ?? ''}
        dataCsat={dataCsat}
      />
    );

  return <div>{renderCsat()}</div>;
};
