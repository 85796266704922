import i18next from 'i18next';

i18next.addResources('es', 'Global', {
  activityCenter: 'Centro de Actividad',
  certificates: 'Certificados de calidad',
  stockControl: 'Control de Stock',
  listUnity: 'Listado de Unidades',
  creditStatus: 'Estatus Crediticio',
  documents: 'Documentos',
  search: 'Buscar',
  notifications: 'Notificaciones',
  users: 'Usuarios',
  userList: 'Listado de usuarios',
  createUser: 'Crear usuario',
  email: 'soporteboxboard@cmpc.com',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  back: 'Volver',
  continue: 'Continuar',
  roll: 'Rollo',
  rolls: 'Rollos',
  stack: 'Pila',
  stacks: 'Pilas',
  edit: 'Editar',
  validate: 'Validar',
  client: 'Cliente',
  exit: 'Salir',
  optional: 'Opcional',
  editUser: 'Editar usuario',
  yes: 'Si',
  no: 'No',
  required: 'Este campo es requerido',
  articles: 'Artículos',
  maxLength: 'Máximo número de caracteres alcanzado. ({{maxLength}})',
  open: 'Abrir',
  close: 'Cerrar',
  other: 'Otro',
  submit: 'Enviar',
  download: 'Descargar {{file}}',
  seeMore: 'Ver más',
  seeLess: 'Ver menos',
  qualityCertificates: 'Certificados de calidad',
  format: 'Formato',
  quantity: 'Cantidad (ton)',
  unit: 'Unidad',
  numberTrack: 'Nº track',
  sheets: 'Hojas',
  order: 'Pedido',
  applyFilter: 'Aplicar Filtro'
});

i18next.addResources('en', 'Global', {
  activityCenter: 'Activity Center',
  certificates: 'Certificates',
  stockControl: 'Stock Control',
  listUnity: 'Units',
  creditStatus: 'Credit Status',
  documents: 'Documents',
  search: 'Search',
  notifications: 'Notifications',
  users: 'Users',
  userList: 'User list',
  createUser: 'Create user',
  email: 'soporteboxboard@cmpc.com',
  cancel: 'Cancel',
  confirm: 'Confirm',
  back: 'Back',
  continue: 'Continue',
  roll: 'Roll',
  rolls: 'Rolls',
  stack: 'Stack',
  stacks: 'Stacks',
  edit: 'Edit',
  validate: 'Validate',
  client: 'Client',
  exit: 'Exit',
  optional: 'Optional',
  editUser: 'Edit user',
  yes: 'Yes',
  no: 'No',
  required: 'This field is required',
  articles: 'Articles',
  maxLength: 'Maximum number of characters reached. ({{maxLength}})',
  open: 'Open',
  close: 'Close',
  other: 'Other',
  submit: 'Submit',
  download: 'Download {{file}}',
  seeMore: 'See more',
  seeLess: 'See less',
  qualityCertificates: 'Quality certificates',
  format: 'Format',
  quantity: 'Quantity (ton)',
  unit: 'Unit',
  numberTrack: 'Nº track',
  sheets: 'Sheets',
  order: 'Order'
});
