import i18next from 'i18next';

i18next.addResources('es', 'Articles', {
  pending: 'Pendientes',
  validated: 'Validados',
  title: 'Nuevo artículo {{paperType}} creado por {{user}}',
  description: 'Un artículo {{paperType}} ha sido creado en la orden de compra #{{orderId}}',
  seeArticle: 'Ver artículo',
  loadMore: 'Cargar más'
});

i18next.addResources('en', 'Articles', {
  pending: 'Pending',
  validated: 'Validated',
  title: 'New article {{paperType}} created by {{user}}',
  description: 'An article {{paperType}} has been created in the purchase order #{{orderId}}',
  seeArticle: 'See article',
  loadMore: 'Load more'
});
