import i18next from 'i18next';

i18next.addResources('es', 'StockControl', {
  title: 'Control de Stock'
});

i18next.addResources('en', 'StockControl', {
  title: 'Stock Control'
});

i18next.addResources('es', 'StockControlHeaders', {
  location: 'Ubicación',
  client: 'Cliente',
  order: 'Pedido',
  item: 'Ítem',
  units: 'Unidades',
  tons: 'Ton',
  antiquity: 'Antigüedad',
  type: 'Tipo de unidad',
  productFamily: 'Familia de producto',
  oc: 'Orden de compra',
  country: 'País',
  format: 'Formato',
  since: 'Desde',
  until: 'Hasta',
  searchFilter: 'Filtro de búsqueda',
  applyFilter: 'Aplicar filtro',
  typePlaceholder: 'Indique Unidad',
  clientPlaceholder: 'Indique Cliente',
  productFamilyPlaceholder: 'Indique Familia',
  ocPlaceholder: 'Indique OC',
  countryPlaceholder: 'Indique País',
  formatPlaceholder: 'Indique Formato',
  sincePlaceholder: 'Indique Desde',
  untilPlaceholder: 'Indique Hasta',
  countryNotFound: 'No se encontró el país',
  typeNotFound: 'No se encontró el tipo de unidad',
  formatNotFound: 'No se encontró el formato',
  productFamilyNotFound: 'No se encontró familia',
  roll: 'Rollo',
  stack: 'Pila',
  width: 'Ancho',
  diameter: 'Diámetro',
  length: 'Largo',
  sheets: 'Número de Hojas'
});

i18next.addResources('en', 'StockControlHeaders', {
  location: 'Warehouse',
  client: 'Customer',
  order: 'Order',
  item: 'Item',
  units: 'Units',
  tons: 'Tons',
  antiquity: 'Oldest',
  type: 'Unit',
  productFamily: 'Product',
  oc: 'Purchase Order',
  country: 'Country',
  format: 'Format',
  since: 'From',
  until: 'To',
  searchFilter: 'Filter',
  applyFilter: 'Apply Filter',
  typePlaceholder: 'Indicate Unit',
  clientPlaceholder: 'Indicate Client',
  productFamilyPlaceholder: 'Indicate Product',
  ocPlaceholder: 'Indicate Purchase Order',
  countryPlaceholder: 'Indicate Country',
  formatPlaceholder: 'Indicate Format',
  sincePlaceholder: 'Indicate From',
  untilPlaceholder: 'Indicate To',
  countryNotFound: 'Country not found',
  typeNotFound: 'Unit kind not found',
  formatNotFound: 'Format not found',
  productFamilyNotFound: 'Family not found',
  roll: 'Roll',
  stack: 'Stack',
  width: 'Width',
  diameter: 'Diameter',
  length: 'Length',
  sheets: 'Sheets'
});
