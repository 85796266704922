import api from 'config/api';
import {
  ICsatPost,
  ICsatStatus,
  IPostCsat,
  IAnswerPut,
  ICsatCommentariesGet,
  IPostNewCsat
} from 'types/Csat';
import { ApiError } from 'types/Global';

export const csatBaseURL = `${api?.getBaseURL() || ''}`;
// export const csatBaseURL = 'http://localhost:3001/bff';
// export const csatBaseURL = 'http://localhost:3009/ms';

export interface IResponse {
  config: any;
  data: any;
  ok: boolean;
  originalError?: any;
  problem?: any;
  status?: number;
}

interface IGetCsat {
  userId: number;
  languageId: string;
}

export const getCsatStatus = ({ userId, languageId }: IGetCsat) =>
  api.get<ICsatStatus, ApiError>(`${csatBaseURL}/survey/status?userId=${userId}&lang=${languageId}`);

export const postCsatFace = (answerPost: IPostCsat) =>
  api.post<ICsatPost, ApiError>(`${csatBaseURL}/answer`, { ...answerPost });

export const putCsatFace = ({ idAnswer, body }: IAnswerPut) =>
  api.put<void, ApiError>(`${csatBaseURL}/answer/${idAnswer}`, { ...body });

export const getCsatModularized = ({ userId, languageId }: IGetCsat) =>
  api.get<ICsatStatus, ApiError>(`${csatBaseURL}/survey/surveysbyuser?userId=${userId}&lang=${languageId}`);

export const getCsatList = (queryParam: string) =>
  api.get<ICsatStatus, ApiError>(`${csatBaseURL}/survey/list?${queryParam}`);

export const setCsatStatus = (id: number) =>
  api.get<ICsatStatus, ApiError>(`${csatBaseURL}/survey/set-status?id=${id}`);

export const getCsatById = (id: number) => api.get<ICsatStatus, ApiError>(`${csatBaseURL}/survey/${id}`);

export const getCsatComments = ({ id, offset }: { id: number; offset: number }) =>
  api.get<ICsatCommentariesGet, ApiError>(`${csatBaseURL}/survey/commentaries/${id}?offset=${offset}`);

export const postCreateCsat = (body: IPostNewCsat) =>
  api.post<ICsatCommentariesGet, ApiError>(`${csatBaseURL}/survey/`, { ...body });
