import i18next from 'i18next';

i18next.addResources('es', 'Help', {
  areaM: 'Área: M',
  areaMSF: 'Área: MSF',
  imperial: 'Imperiales',
  lengthMilimeter: 'Longitud: MM',
  metrics: 'Métricas',
  lengthInch: 'Longitud: Pulgada',
  sizeDot: 'Calibre: Puntos',
  sizeMilimeter: 'Calibre: Milímetro',
  unitSystem: 'Sistema de unidades',
  weigthKG: 'Peso: KG',
  weigthPound: 'Peso: Libra'
});

i18next.addResources('en', 'Help', {
  areaM: 'Area: M',
  areaMSF: 'Area: MSF',
  imperial: 'Imperial',
  lengthMilimeter: 'Length: MM',
  metrics: 'Metrics',
  lengthInch: 'Length: Inch',
  sizeDot: 'Caliber: Dots',
  sizeMilimeter: 'Caliber: Milimeter',
  unitSystem: 'Unit system',
  weigthKG: 'Weight: KG',
  weigthPound: 'Weigth: Pound'
});
