import i18next from 'i18next';

i18next.addResources('es', 'Input', {
  valid: 'Válido',
  invalid: 'Inválido'
});

i18next.addResources('en', 'Input', {
  valid: 'Valid',
  invalid: 'Invalid'
});
