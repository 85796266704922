import i18next from 'i18next';

i18next.addResources('es', 'OrderInfo', {
  orderItems: 'Items del pedido',
  deliveries: 'Despachos',
  activity: 'Actividad',
  orderReadyToClose: '¡Tu pedido está listo para cerrar!',
  thanksForNotify:
    'Estimado cliente, gracias por notificar que has recibido todos los despachos de tu pedido # {{id}}.',
  askingFeedback: 'A continuación te solicitamos nos dejes tu feedback para cerrar el pedido.'
});

i18next.addResources('en', 'OrderInfo', {
  orderItems: 'Order items',
  deliveries: 'Deliveries',
  activity: 'Activity',
  orderReadyToClose: 'Your order is ready to close!',
  thanksForNotify:
    'Dear customer, thank you for notifying that you have received all the shipments of your order # {{id}}.',
  askingFeedback: 'Next, we ask you to leave us your feedback to close the order.'
});
