import i18next from 'i18next';

i18next.addResources('es', 'ActionButtons', {
  apiError: 'Ocurrió un error al guardar los cambios',
  apiOk: 'Cambios guardados con éxito',
  areYouSureDelete: '¿Estás seguro que deseas eliminar la orden?',
  areYouSureExit: '¿Estás seguro que deseas salir?',
  articleDuplicatedOrder: 'Tienes un item duplicado, debes modificarlo para poder guardar la orden.',
  beforeConfirming: 'Antes de confirmar...',
  beforeValidate: 'Antes de validar la orden...',
  categoriesError:
    'Lamentamos informarle que hay artículo(s) en su orden que no pueden ser seleccionados debido a que infringe nuestra política de restricciones de categorías.',
  confirmOrder: 'Confirmar orden',
  confirmOrderText:
    'Ten en cuenta que al confirmar la orden ya no podrás a hacer cambios y la misma será enviada a CMPC para su validación.',
  confirmTextFirst: 'Si estás seguro que todos los datos ingresados son correctos haz click en ',
  confirmTextLast: ' para continuar trabajando.',
  confirmTextMiddle: ', de lo contrario haz click en ',
  deleteIfAreSureFirst: 'Si estás seguro haz click en ',
  deleteIfAreSureLast: ' para continuar trabajando en ella.',
  deleteIfAreSureMiddle: ', de lo contrario haz click en ',
  deleteOrder: 'Eliminar orden',
  deleteOrderText:
    'Ten en cuenta que al eliminar la orden ya no podrás a hacer cambios y la misma será eliminada del sistema.',
  duplicatedReferenceOcAgent: 'Los campos OC y OC Agente no pueden ser el mismo valor',
  exit: 'Salir',
  keepEditing: 'Seguir editando',
  loseInformation: 'Si sales ahora perderás toda la información completada hasta el momento.',
  ocAgentDuplicated: 'El código OC agente ya está registrado.',
  referenceDuplicated: 'La orden de compra ya está registrada.',
  saveChanges: 'Guardar cambios',
  thereAreUnsavedChanges: 'Existen cambios sin guardar en la orden de compra.',
  validateOrder: 'Validar orden',
  validateOrderText:
    'Ten en cuenta que al validar la orden ya no podrás a hacer cambios. Esta acción no puede revertirse.',
  yesConfirmDelete: 'Si, eliminar orden',
  incompatibleDiameterAndPackaging: 'No se puede validar este articulo, el embalaje no es el correcto',
  cannotSaveDiameterAndPackaging:
    'No se puede guardar esta orden de compra, embalaje de artículos no corresponde',
  cannotConfirmDiameterAndPackaging:
    'No se puede confirmar esta orden de compra, embalaje de artículos no corresponde',
  cannotValidateDiameterAndPackaging:
    'No se puede validar esta orden de compra, embalaje de artículos no corresponde'
});

i18next.addResources('en', 'ActionButtons', {
  apiError: 'There was an error saving changes',
  apiOk: 'Changes were successfully saved',
  areYouSureDelete: 'Are you sure you want to delete the order?',
  areYouSureExit: 'Are you sure you want to exit?',
  articleDuplicatedOrder: 'You have an item duplicate, you must modify it to be able to save the order.',
  beforeConfirming: 'Before confirming...',
  beforeValidate: 'Before validating...',
  categoriesError:
    'We regret to inform you that there is an article or articles in your order that cannot be selected because they violate our policy on category restrictions.',
  confirmOrder: 'Confirm order',
  confirmOrderText:
    'Keep in mind that by confirming the order you will no longer be able to make changes and it will be sent to CMPC for validation.',
  confirmTextFirst: 'If you are sure that all the data entered are correct, click on ',
  confirmTextLast: ' to continue working.',
  confirmTextMiddle: ', otherwise click on ',
  deleteIfAreSureFirst: 'If  are sure click ',
  deleteIfAreSureLast: ' to continue working on it.',
  deleteIfAreSureMiddle: ', otherwise click ',
  deleteOrder: 'Delete order',
  deleteOrderText:
    'Keep in mind that when you delete the order you will no longer be able to make changes and it will be removed from the system.',
  duplicatedReferenceOcAgent: 'Purchase order-invoice and Reference fields cannot be the same value.',
  exit: 'Exit',
  keepEditing: 'Keep editing',
  loseInformation: 'If you leave now you will lose all the information completed so far.',
  ocAgentDuplicated: 'The reference code is already registered',
  referenceDuplicated: 'The purchase order-invoice is already registered',
  saveChanges: 'Save changes',
  thereAreUnsavedChanges: 'There are unsaved changes to the purchase order.',
  validateOrder: 'Validate order',
  validateOrderText:
    'Keep in mind that when you validate the order you will no longer be able to make changes. This action cannot be reversed.',
  yesConfirmDelete: 'Yes, delete order',
  incompatibleDiameterAndPackaging: 'This item cannot be validated, the packaging is not correct.',
  cannotSaveDiameterAndPackaging: 'Cannot save this purchase order, item packaging does not match ',
  cannotConfirmDiameterAndPackaging: 'Cannot confirm this purchase order, item packaging does not match',
  cannotValidateDiameterAndPackaging: 'Cannot validate this purchase order, item packaging does not match'
});
