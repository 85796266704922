import i18next from 'i18next';

i18next.addResources('es', 'Week', {
  etaWeek: 'Semana ETA',
  dispatchWeek: 'Semana Despacho',
  to: 'a',
  nextPartialDelivery: 'Próxima entrega parcial',
  week: 'Sem {{week}}',
  noInformationAvailable: 'No hay información disponible'
});

i18next.addResources('en', 'Week', {
  etaWeek: 'ETA Week',
  dispatchWeek: 'Dispatch Week',
  to: 'to',
  nextPartialDelivery: 'Next partial delivery',
  week: 'Week {{week}}',
  noInformationAvailable: 'No information available'
});
