import i18next from 'i18next';

i18next.addResources('es', 'CreateArticle', {
  alreadyExits: 'No se pudo crear el artículo debido a que ya existe uno con caracteristicas similares.',
  articleWithWrongPaper: 'No se puede crear un articulo con ese papel',
  creationError: 'Hubo un error creando el artículo, por favor revisa los datos o intenta más tarde.',
  diameterRollExceeded: 'El diámetro máximo permitido para este código de papel (cara abajo) es de 150 cms.',
  error:
    'Lamentamos informarle que este artículo no puede ser seleccionado debido a que infringe nuestra política de restricciones de categorías.',
  rollMinimumWidthError: 'Ocurrió un error en las medidas entre diametro y ancho',
  stackRuleOfThreeError: 'No se cumple la regla de tres, revisar ancho y largo del papel.',
  subTitle: 'Ingrese los datos a continuación para crear un nuevo artículo',
  title: 'Crear artículo'
});

i18next.addResources('en', 'CreateArticle', {
  alreadyExits: 'The article could not be created because one with similar characteristics already exists.',
  articleWithWrongPaper: 'You cannot create an article with that role',
  creationError: 'There was an error creating the article, please check the form or retry later.',
  diameterRollExceeded: 'The maximum diameter allowed for this paper code (face down) is 150 cm.',
  error:
    'We regret to inform you that this article cannot be selected as it violates our policy on category restrictions.',
  rollMinimumWidthError: 'An error occurred in the measures between diameter and wide',
  stackRuleOfThreeError: 'The rule of three is not fulfilled, check wide and length of the paper.',
  subTitle: 'Enter the data below to create a new article',
  title: 'Create article'
});
