import i18next from 'i18next';

i18next.addResources('es', 'HelpCenterScreenNewOC', {
  accessNSelection: 'Acceso y Selección',
  buttonLocation: 'Ubicación del Botón:',
  p1:
    'Para iniciar el proceso de ingreso de una nueva orden de compra, selecciona el botón correspondiente que se encuentra en el menú principal del listado de pedidos.',
  emergentPopup: 'Nueva Pantalla Emergente:',
  p2:
    'Al hacer clic en "Nueva Orden de Compra", se desplegará una nueva pantalla donde deberás ingresar todos los datos generales asociados a la orden de compra.',
  generalData: 'Ingreso de Datos Generales',
  ol1: '1. Ingresar Cliente y Consignatario Final:',
  ul1: 'Ingresar Cliente:',
  subul1: 'Selecciona el cliente asociado a la orden de compra desde la lista proporcionada.',
  ul2: 'Consignatario Final:',
  subul2: 'Ingresa la información del consignatario final, si es aplicable.',
  ol2: '2. Ingresar N° de OC Cliente y OC Agente (Referencia de Búsqueda Libre):',
  ul3: 'N° de OC Cliente:',
  subul3:
    'Proporciona el número de orden de compra asignado por el cliente para identificar la transacción de manera única.',
  ul4: 'OC Agente (Referencia de Búsqueda Libre):',
  subul4:
    'Ingresa la Orden de Compra (OC) del agente, permitiendo una referencia de búsqueda libre para facilitar la identificación de la orden.',
  ol3: '3. Adjuntar OC Cliente:',
  ul5: 'Adjuntar OC Cliente:',
  subul5:
    'Agrega el archivo de la Orden de Compra proporcionado por el cliente para respaldar la transacción.',
  ol4: '4. Agregar Detalles de la OC:',
  ul6: 'Tipo de Certificación:',
  subul6: 'Selecciona el tipo de certificación aplicable para la orden de compra.',
  ul7: 'Ciclo de Producción Esperado',
  subul7: 'Indica el ciclo de producción esperado para la orden.',
  ol5: '5. Dar Click en Continuar:',
  ul8: 'Finalizar Ingreso de Datos:',
  subul8:
    'Una vez que todos los campos obligatorios han sido completados, haz clic en "Continuar" para avanzar al siguiente paso del proceso de ingreso de la orden de compra.',
  articles: 'Ingreso de artículos',
  p3:
    'Luego de ingresar los datos generales, se desplegará el segundo paso que es cargar ítems. En esta pantalla, se permite editar todos los datos generales ya ingresados. Para cargar ítems se debe hacer click en el botón agregar items y se desplegará la nueva pestaña de carga.',
  ol6: '1. Selección de Unidad Tipo: Pila o Rollo',
  ul9: 'Selección de Unidad Tipo:',
  subul9:
    'El primer paso consiste en elegir la unidad tipo para los ítems a incluir en la orden de compra. Puedes seleccionar entre las opciones de Pila o Rollo, según las características y necesidades específicas de los productos que estás solicitando.',
  ol7: '2. Ingresar Familia y Tipo de Papel:',
  ul10: 'Ingresar Familia y Tipo de Papel:  ',
  subul10:
    'Especifica la familia de productos y el tipo de papel asociado a los ítems que estás agregando a la orden de compra.',
  ol8: '3. Propuesta de Últimos Artículos/Formatos Solicitados por el Cliente: ',
  ul11: 'Propuesta de Últimos Artículos:',
  subul11:
    'La plataforma puede ofrecer sugerencias basadas en los últimos artículos o formatos solicitados por el cliente, simplificando la selección y agilizando el proceso.',
  ol9: '4. Opción de Buscar Otros Formatos:',
  ul12: 'Buscar Otros Formatos:',
  subul12:
    'Si necesitas explorar opciones adicionales, utiliza la opción de búsqueda para encontrar otros formatos disponibles.',
  ol10: '5. Opción Crear Nuevos Artículos:',
  ul13: 'Crear Nuevos Artículos:',
  subul13:
    'Si los artículos deseados no existen en las opciones predefinidas, tienes la opción de crear nuevos artículos según tus necesidades específicas.',
  ol11: '6. Ingresar Cantidad a Solicitar:',
  ul14: 'Ingresar Cantidad:',
  subul14:
    'Para cada artículo seleccionado, ingresa la cantidad específica que deseas solicitar en esta orden de compra.',
  ol12: '7. Seleccionar "Continuar":',
  ul15: 'Finalizar la Carga de Ítems:',
  subul15:
    'Después de ingresar las cantidades deseadas, seleccione "Continuar" para avanzar al siguiente paso del proceso de carga de ítems.',
  confirmOc: 'Confirmar Orden de compra',
  p4:
    'Después de agregar al menos un ítem a la orden de compra, el proceso de confirmación ofrece varias opciones para gestionar y finalizar la orden de compra:',
  ol13: '1. Continuar Agregando Ítems:',
  ul16: 'Cargar Más Ítems:',
  subul16:
    'Si deseas agregar más ítems a la orden de compra, selecciona la opción "Cargar Ítems" para continuar con la carga.',
  ol14: '2. Gestión de Ítems Cargados:',
  ul17: 'Editar, Duplicar o Eliminar:',
  subul17:
    'Utiliza el botón "Acciones" para editar, duplicar o eliminar ítems previamente cargados, brindando flexibilidad en la gestión de la orden.',
  ol15: '3. Guardar y Continuar Más Tarde:',
  ul18: 'Guardar:',
  subul18:
    'Mientras estás completando la orden de compra, puedes seleccionar "Guardar" para almacenar la información actual y volver más tarde para su finalización.',
  ol16: '4. Confirmar Orden:',
  ul19: 'Confirmar Orden:',
  subul19:
    'Una vez que la orden de compra esté completa y revisada, selecciona "Confirmar" para enviar la orden a CMPC para su validación.',
  ol17: '5. Validación Final:',
  ul20: 'Última Validación',
  subul20:
    'Antes de enviar la orden, se realizará una última validación para asegurar la precisión de los datos. Ten en cuenta que una vez que la orden se envíe a CMPC, no se podrá corregir directamente en la plataforma.',
  proformConfirmation: 'Confirmación de Proforma',
  p5:
    'La plataforma notificará cuando uno de tus pedidos tenga una proforma disponible para su validación. A continuación, se presenta un paso a paso para la confirmación de proformas:',
  stepToStep: 'Paso a Paso:',
  ul21: 'Recepción de Notificación:',
  subul21:
    'Cuando haya una proforma disponible para validación, recibirás una notificación en la plataforma.',
  ul22: 'Ingreso a la Plataforma:',
  subul22:
    'Accede a la plataforma y navega a la sección de pedidos para revisar las proformas pendientes de validación.',
  ul23: 'Botón "Validar":',
  subul23:
    'Al revisar un pedido con proforma disponible, encontrarás el botón "Validar" asociado al documento.',
  ul24: 'Ingreso al Documento:',
  subul24: 'Haz clic en el botón "Validar" para acceder al documento de la proforma.',
  ul25: 'Exportación del Documento:',
  subul25:
    'Una vez dentro del documento, tendrás la opción de exportarlo. Esto te permitirá revisar detalladamente la información, incluyendo el detalle del pedido, tonelajes, cantidad de unidades definitivas y fechas estimadas de despacho.',
  ul26: 'Validación de la Proforma:',
  subul26:
    'Revisa cuidadosamente la información y, si todo es correcto, selecciona la opción de validar la proforma.',
  ul27: 'Continuación del Flujo Normal:',
  subul27: 'Una vez validada la proforma, tu pedido continuará su flujo normal dentro del sistema.',
  ul28: 'Acceso al Documento Validado:',
  subul28:
    'El documento de la proforma permanecerá disponible en el detalle de tu pedido, proporcionando una referencia fácil para futuras consultas.'
});

i18next.addResources('en', 'HelpCenterScreen', {
  home: 'Home',
  title: 'Help Center',
  orders: 'Orders',
  docs: 'Documents',
  howCanHelpYou: 'How can we help you?',
  orderList: 'Order list',
  orderDetail: 'Order detail'
});
