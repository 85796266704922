import i18next from 'i18next';

i18next.addResources('es', 'Notification', {
  day: 'día',
  minute: 'minuto',
  notifications: 'Notificaciones',
  ago: 'Hace'
});

i18next.addResources('en', 'Notification', {
  day: 'day',
  minute: 'minute',
  notifications: 'Notifications',
  ago: 'ago'
});
