import i18next from 'i18next';

i18next.addResources('es', 'Chart', {
  reels: 'Rollos',
  sheets: 'Pilas'
});

i18next.addResources('es', 'product', {
  title: 'Familia',
  yaxis: 'Cartulina',
  xaxis: 'Toneladas'
});

i18next.addResources('es', 'location', {
  title: 'Ubicación',
  yaxis: 'Centros',
  xaxis: 'Toneladas'
});

i18next.addResources('es', 'date', {
  title: 'Antigüedad',
  yaxis: 'Tiempo',
  xaxis: 'Toneladas',
  days: 'días'
});

i18next.addResources('en', 'Chart', {
  reels: 'Reels',
  sheets: 'Sheets'
});

i18next.addResources('en', 'product', {
  title: 'Product',
  yaxis: 'Boxboard',
  xaxis: 'Tons'
});

i18next.addResources('en', 'location', {
  title: 'Location',
  yaxis: 'Centers',
  xaxis: 'Tons'
});

i18next.addResources('en', 'date', {
  title: 'Date',
  yaxis: 'Time',
  xaxis: 'Tons',
  days: 'days'
});
