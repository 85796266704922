import api, { AUTH_TOKEN_HEADER_NAME, apiBff } from 'config/api';
import { Nullable } from 'types/Global';
import { User } from 'types/User';
import { LocalStorageService, SessionStorageService } from 'services/StorageService';
import { MESSAGE_INFO } from 'components/ModalInfo/constants';

const TOKEN_FIELD_NAME = 'userTokens';

/**
 * It saves the values taken from the response after login from azure or auth0
 * It will store the values in the localStorage and
 * It will set the headers for validate user request
 *
 * @remarks
 * This method is part of the utils-session library.
 * Use it only after validate recieve token from azure or auth0.
 * Then you will have to overwrite the values using saveUserAuthenticated
 *
 * @param session - Object of type User
 * @returns void
 */
export const saveUser = (session: User) => {
  const { accessToken, isAzure, idToken, expiresIn } = session;
  // const now = new Date();
  // eslint-disable-next-line no-magic-numbers
  // const expiresIn = now.getTime() + 3600000 * 8;
  api.setHeaders({
    [AUTH_TOKEN_HEADER_NAME]: `Bearer ${accessToken}`
  });
  apiBff.setHeaders(api.headers);
  LocalStorageService.setValue(
    TOKEN_FIELD_NAME,
    JSON.stringify({ accessToken, isAzure, idToken, expiresIn })
  );
  window.localStorage.setItem('logged', 'true');
};

/**
 * It saves the values taken from the response after calling user validate.
 * It will store the values in the localStorage and
 * It will set the headers for next request to the backend
 *
 * @remarks
 * This method is part of the utils-session library.
 * Use it only after validate user an obtain the newToken from backend
 *
 * @param session - Object of type User
 * @returns void
 */

/* export const saveUserAuthenticated = (session: User) => {
  const { accessToken, isAzure, newToken, expiresNewToken } = session;
  api.setHeaders({
    [AUTH_TOKEN_HEADER_NAME]: `Bearer ${newToken}`,
    [AUTH_TOKENID_HEADER_NAME]: '',
    ...(isAzure && { [AUTH_IGNORE]: `${isAzure}` })
  });
  apiBff.setHeaders(api.headers);
  LocalStorageService.setValue(
    TOKEN_FIELD_NAME,
    JSON.stringify({
      accessToken,
      isAzure,
      newToken,
      expiresNewToken
    })
  );
}; */

export const listenSessionChange = () => LocalStorageService.reloadOnMissingKey(TOKEN_FIELD_NAME);

/**
 * Returns the saved user from localStorage
 *
 * @remarks
 * This method is part of the utils-session library.
 * Use it only after using saveUserAuthenticated
 * @returns Object - An object containing the current tokenType, idToken and newToken stored in local Storage
 */
/* export const getSavedUserAuthenticated = (): Nullable<User> => {
  const savedUserString = LocalStorageService.getValue(TOKEN_FIELD_NAME) || null;
  const savedUser = JSON.parse(savedUserString);
  if (savedUser) {
    listenSessionChange();
    const { tokenType, isAzure, newToken } = savedUser;
    api.setHeaders({
      [AUTH_TOKEN_HEADER_NAME]: `${tokenType} ${newToken}`,
      [AUTH_TOKENID_HEADER_NAME]: '',
      ...(isAzure && { [AUTH_IGNORE]: `${isAzure}` })
    });
    apiBff.setHeaders(api.headers);
    return savedUser;
  }
  return null;
}; */

/**
 * Returns the saved user from localStorage
 *
 * @remarks
 * This method is part of the utils-session library.
 * Use it only after using savedUser
 *
 * @returns Object - An object containing the current tokenType, accessToken, idToken and isAzure flag stored in local Storage
 */
export const getSavedUser = (): Nullable<User> => {
  const savedUserString = LocalStorageService.getValue(TOKEN_FIELD_NAME) || null;
  const savedUser = JSON.parse(savedUserString);
  if (savedUser) {
    listenSessionChange();
    const { accessToken } = savedUser;
    api.setHeaders({
      [AUTH_TOKEN_HEADER_NAME]: `Bearer ${accessToken}`
    });
    apiBff.setHeaders(api.headers);
    return savedUser;
  }
  return null;
};

/**
 * Delete values stored in the local storage
 *
 * @see services/LocalStorageService
 *
 * @remarks
 * This method is part of the utils-session library.
 * These values are encrypted, it's important to use functions exposed
 * LocalStorageService
 *
 * @returns void
 */
export const removeSavedUser = () => {
  LocalStorageService.removeValue(TOKEN_FIELD_NAME);
  LocalStorageService.removeMissingKeyListener(TOKEN_FIELD_NAME);
  SessionStorageService.removeValue('skipCsat');
  SessionStorageService.removeValue('csat');
  SessionStorageService.removeValue(MESSAGE_INFO);
};

/**
 * Returns the expiration Date
 *
 * @remarks
 * This method is part of the utils-session library.
 *
 * @param expirationTime - Any date in number format
 * @returns number - subtraction between the actual date vs entered expirationTime
 */
export const getExpDate = (expirationTime: number) => {
  const DateNow = Date.now();
  const diffTime = expirationTime - DateNow;
  const expDate = diffTime > 0 ? diffTime : diffTime * -1;
  return expDate;
};
