import i18next from 'i18next';

i18next.addResources('es', 'OrderItems', {
  item: 'Ítem',
  product: 'Producto',
  units: 'Unidades',
  quantity: 'Cantidad',
  stack: 'Pila',
  stacks: 'Pilas',
  roll: 'Rollo',
  rolls: 'Rollos',
  produced: 'Producidos',
  inTransit: 'En tránsito',
  invoiced: 'Facturados',
  format: 'Formato',
  width: 'Ancho',
  lengthOrDiameter: 'Largo/Diam',
  axisOrSheets: 'Eje/N° hojas',
  weigth: 'Cant (Ton)',
  entryDate: 'Fec Ingreso',
  readyDate: 'Fec Ready',
  state: 'Estado',
  certificates: 'Certificados'
});

i18next.addResources('en', 'OrderItems', {
  item: 'Item',
  product: 'Product',
  units: 'Units',
  quantity: 'Quantity',
  stack: 'Stack',
  stacks: 'Stacks',
  roll: 'Roll',
  rolls: 'Rolls',
  produced: 'Produced',
  inTransit: 'In transit',
  invoiced: 'Invoiced',
  format: 'Format',
  width: 'Width',
  lengthOrDiameter: 'Length/Diam',
  axisOrSheets: 'Aix/N° Sheets',
  weigth: 'Quant (Ton)',
  entryDate: 'Adm Date',
  readyDate: 'Ready Date',
  state: 'Status',
  certificates: 'Certificates'
});
