import i18next from 'i18next';

i18next.addResources('es', 'UserData', {
  title: 'Datos del usuario',
  marketList: 'Lista de mercados',
  clientList: 'Lista de clientes'
});

i18next.addResources('en', 'UserData', {
  title: 'User data',
  marketList: 'Market list',
  clientList: 'Client list'
});
