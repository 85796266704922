import i18next from 'i18next';

i18next.addResources('es', 'PackingList', {
  commodityDetail: 'Detalle de la mercadería',
  seeMore: 'Ver mas',
  seeLess: 'Ver menos',
  packingTitle: 'Items del pedido',
  client: 'Cliente',
  country: 'País',
  shipmentNumber: 'N° de embarque',
  shipmentRequest: 'N° solic. embarque',
  certification: 'Certificación',
  ship: 'Nave',
  portDate: 'Fecha puerto',
  containerId: 'ID Contenedor',
  order: 'Pedido',
  product: 'Producto',
  unitNumber: 'N° de unidad',
  units: 'Unidades',
  type: 'Tipo',
  gsm: 'Gramaje',
  widthMm: 'Ancho (mm)',
  widthIn: 'Ancho (in)',
  lengthOrDiameterMm: 'Largo/Diam (mm)',
  lengthOrDiameterIn: 'Largo/Diam (in)',
  axisOrSheetsMm: 'N° Hojas/ Caño',
  axisOrSheetsIn: 'N° Hojas/ Caño (in)',
  grossWeightTon: 'Peso bruto (Ton)',
  grossWeightLbs: 'Peso bruto (Lbs)',
  netWeightTon: 'Peso neto (Ton)',
  netWeightLbs: 'Peso neto (Lbs)',
  squareMeters: 'Metros cuadrados (m2)',
  squareFeet: 'Pies cuadrados',
  linearLength: 'Longitud lineal (feet)',
  coordinatorName: 'Nombre Coordinador/a',
  container: 'Contenedor',
  shipment: 'Embarque',
  invoice: 'Factura',
  summaryVal: 'Resumen {{entity}}',
  downloadExcel: 'Descargar Excel',
  errorExcel: 'Ocurrió un error al descargar el archivo Excel',
  msgError: 'Ocurrió un error. Por favor intente nuevamente'
});

i18next.addResources('en', 'PackingList', {
  commodityDetail: 'Commodity detail',
  seeMore: 'See more',
  seeLess: 'See less',
  packingTitle: 'Order items',
  client: 'Client',
  country: 'Country',
  shipmentNumber: 'Shipment num',
  shipmentRequest: 'Shipment req num',
  certification: 'Certification',
  ship: 'Ship',
  portDate: 'Port date',
  containerId: 'Container ID',
  order: 'Order',
  product: 'Product',
  unitNumber: 'Unity num',
  units: 'Units',
  type: 'Type',
  gsm: 'Grammage',
  widthMm: 'Width (mm)',
  widthIn: 'Width (in)',
  lengthOrDiameterMm: 'Long/Diam (mm)',
  lengthOrDiameterIn: 'Long/Diam (in)',
  axisOrSheetsMm: 'Sheets num/Spout',
  axisOrSheetsIn: 'Sheets num/Spout (in)',
  grossWeightTon: 'Gross weight (Ton)',
  grossWeightLbs: 'Gross weight (Lbs)',
  netWeightTon: 'Net weight (Ton)',
  netWeightLbs: 'Net weight (Lbs)',
  squareMeters: 'Square meters (m2)',
  squareFeet: 'Square feet',
  linearLength: 'Linear length (feet)',
  coordinatorName: 'Coordinator name',
  container: 'Container',
  shipment: 'Shipment',
  invoice: 'Invoice',
  summaryVal: '{{entity}} summary',
  downloadExcel: 'Download Excel',
  errorExcel: 'An error occurred while downloading the Excel file',
  msgError: 'An error occurred. Please try again'
});
