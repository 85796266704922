import i18next from 'i18next';

i18next.addResources('es', 'DocumentShipment', {
  shipmentDocuments: 'Documentos de Embarque',
  shipmentNumber: 'N° de Embarque',
  deliveryNumber: 'N° de Pedido',
  orderId: 'N° de Pedido',
  deliveryDocuments: 'Documentos de Pedido',
  clearFilters: 'Limpiar filtros',
  searchDeliveries: 'Buscar pedidos',
  date: 'Fecha',
  documents: 'Documentos',
  seeDocuments: 'Ver documentos',
  noFilter: 'Comienza buscando tu pedido en el buscador',
  client: 'Cliente',
  country: 'País'
});
i18next.addResources('en', 'DocumentShipment', {
  shipmentDocuments: 'Shipment Documents',
  shipmentNumber: 'N° Shipment',
  deliveryNumber: 'N° Delivery',
  orderId: 'N° Delivery',
  deliveryDocuments: 'Delivery Documents',
  clearFilters: 'Clear filters',
  searchDeliveries: 'Search deliveries',
  documents: 'Documents',
  seeDocuments: 'See documents',
  noFilter: 'Start looking for your order in the search engine',
  client: 'Client',
  country: 'Country'
});
