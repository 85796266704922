import i18next from 'i18next';

i18next.addResources('es', 'QuantitySelector', {
  quantity: 'Cantidad',
  ton: 'Toneladas',
  shortTon: 'Toneladas cortas',
  equals: 'Equivale a {{value}} {{unit}}',
  labelMarks: 'Marca especial'
});

i18next.addResources('en', 'QuantitySelector', {
  quantity: 'Quantity',
  ton: 'Tons',
  shortTon: 'Short Tons',
  equals: 'Equals {{value}} {{unit}}',
  labelMarks: 'Label marks'
});
