import React from 'react';

import { UserType } from 'types/User';

type UserTypePermissionContextType = {
  isAllowedTo: (userTypes: Array<UserType>) => Promise<boolean>;
};

const defaultBehaviour: UserTypePermissionContextType = {
  isAllowedTo: () => Promise.resolve(false)
};

const UserTypePermissionContext = React.createContext<UserTypePermissionContextType>(defaultBehaviour);

export default UserTypePermissionContext;
