import { PaginationProps } from './Pagination';

/* eslint-disable no-magic-numbers */
export interface ICsatStatus {
  data: Data;
  metadata: Metadata;
}

export interface Data {
  idSurvey: number;
  idQuestion: number;
  question: string;
  state: boolean;
}

export interface Metadata {
  pagination: PaginationProps;
}

export interface IPostCsat {
  idQuestion: number;
  idUser: number;
  usrName: string;
  usrRol: string;
  usrMail: string;
  answer: string;
  comments: string;
  skipSurvey: boolean;
  idSurvey: number;
}

export interface ICsatPost {
  data: DataPost;
  metadata: Metadata;
}
export interface ICsatCommentariesGet {
  data: ICommentsData[];
  metadata: Metadata;
}

export interface DataPost {
  idAnswer: number;
  idQuestion: number;
  idUser: string;
  usrName: string;
  usrRol: string;
  usrMail: string;
  answer: string;
  comments: string;
  createdAt: string;
  updateAt: string;
}

export interface IAnswerPut {
  idAnswer: number;
  body: { answer: string; comments: string };
}

export enum ModulesCsatTranslated {
  'General' = 1,
  'Pedidos' = 2,
  'Usuarios' = 3,
  'Centro de actividad' = 4,
  'Artículos' = 5,
  'Ciclos de producción' = 6,
  'Certificados' = 7,
  'Control de stock' = 8,
  'Estatus Crediticio' = 9,
  'Documentos' = 10,
  'Tracking' = 11
}

export enum ModulesCsatIds {
  GENERAL = 1,
  ORDERS = 2,
  USERS = 3,
  ACTIVITY_CENTER = 4,
  ARTICLES = 5,
  PRODUCTION_CYCLE = 6,
  CERTIFICATES = 7,
  STOCK_CONTROL = 8,
  CREDIT_STATUS = 9,
  DOCUMENTS = 10,
  TRACKING = 11
}

export enum ModulesCsat {
  GENERAL = '/',
  ORDERS = '/orders',
  USERS = '/users',
  ACTIVITY_CENTER = '/activity_center',
  ARTICLES = '/articles',
  PRODUCTION_CYCLE = '/cycles',
  CERTIFICATES = '/certificates',
  STOCK_CONTROL = '/stockControl',
  CREDIT_STATUS = '/creditStatus',
  DOCUMENTS = '/documents',
  TRACKING = '/tracking'
}

export interface ModuleCsatResponse {
  data: DataCsat[];
  metadata: Metadata;
}

export interface ICsatResults {
  survey: DataCsat;
  answers: IAnswer[];
}

export interface DataCsat {
  idSurvey: number;
  title: string;
  description: string;
  owner: string;
  moduleId: ModulesCsatIds;
  state: boolean;
  questions: Question[];
  createdAt: string;
  updatedAt: string;
}

export interface Question {
  idQuestion: number;
  idSurvey: number;
  idQuestionType: number;
  minScaleRange: number;
  maxScaleRange: number;
  createdAt: string;
  updatedAt: string;
  contentQuestion: ContentQuestion[];
}

export interface ContentQuestion {
  language: string;
  description: string;
  value: string;
}

export interface IFormatData {
  module: ModulesCsat;
  state: boolean;
  question: string;
  idSurvey: number;
  idQuestion: number;
}

export interface IAnswer {
  idAnswer: number;
  idQuestion: number;
  idUser: number;
  usrName: string;
  usrRol: string;
  usrMail: string;
  answer: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICSATFormatData {
  count: number;
  veryUnsatisfied: number;
  unsatisfied: number;
  content: number;
  satisfied: number;
  verySatisfied: number;
  completed: number;
  noParticipate: number;
  skipped: number;
}

export interface ICommentsCSAT {
  commentary: string;
  user: string;
  date: string;
}

export interface ICommentsData {
  idAnswer: number;
  idQuestion: number;
  idUser: number;
  usrName: string;
  usrRol: string;
  usrMail: string;
  answer: string;
  comments: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IPostNewCsat {
  title: string;
  moduleId: number;
  state: boolean;
  owner: string;
  description: string;
  questions: QuestionNewCsat[];
}

export interface QuestionNewCsat {
  idQuestionType: number;
  minScaleRange: number;
  maxScaleRange: number;
  contentQuestion: ContentQuestionNewCsat[];
}

export interface ContentQuestionNewCsat {
  language: string;
  description: string;
  value: string;
}
