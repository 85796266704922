import { Nullable } from 'types/Global';
import { Profile, User } from 'types/User';

export interface UserState {
  user: Nullable<User>;
}

export const INITIAL_STATE = {
  user: null
};

export enum ActionTypes {
  SET_CONFIG = 'SET_CONFIG',
  SET_USER = 'SET_USER',
  RESET_USER = 'RESET_USER',
  SET_PROFILE = 'SET_PROFILE'
}

interface UserConfig {
  languageId?: string;
  unitsMeassure?: string;
}

interface SetConfig {
  type: ActionTypes.SET_CONFIG;
  payload: UserConfig;
}

interface SetUser {
  type: ActionTypes.SET_USER;
  payload: User;
}

interface ResetUser {
  type: ActionTypes.RESET_USER;
}

interface SetProfile {
  type: ActionTypes.SET_PROFILE;
  payload: Profile;
}

export type Action = SetConfig | SetUser | ResetUser | SetProfile;

export const actionCreators = {
  setConfig: ({ languageId, unitsMeassure }: UserConfig): SetConfig => ({
    type: ActionTypes.SET_CONFIG,
    payload: { languageId, unitsMeassure }
  }),
  setUser: (user: User): SetUser => ({ type: ActionTypes.SET_USER, payload: user }),
  resetUser: (): ResetUser => ({ type: ActionTypes.RESET_USER }),
  setProfile: (profile: Profile): SetProfile => ({ type: ActionTypes.SET_PROFILE, payload: profile }),
  logout: (): ResetUser => ({ type: ActionTypes.RESET_USER })
};

export const reducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case 'SET_CONFIG': {
      const { languageId, unitsMeassure } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          languageId: languageId || state.user?.languageId,
          unitsMeassure: unitsMeassure || state.user?.unitsMeassure
        } as User
      };
    }
    case 'SET_USER': {
      return { ...state, user: action.payload };
    }
    case 'RESET_USER': {
      return { ...state, user: null };
    }
    case 'SET_PROFILE': {
      const userWithProfile = {
        ...state.user,
        ...action.payload
      };
      return { ...state, user: userWithProfile as User };
    }
    default: {
      return state;
    }
  }
};
