import i18next from 'i18next';

i18next.addResources('es', 'CreditStatusScreen', {
  title: 'Estatus Crediticio',
  titleDetail: 'Detalle Cliente',
  download: 'Descargar excel',
  errorDownloadExcel: 'Error en la descarga',
  listCreditStatus: 'Listado de facturas',
  crediStatus: 'Estatus crediticio',
  summaryClients: 'Resumen clientes',
  paginationItem: 'facturas',
  back: 'Volver',
  lastUpdate: 'Última actualización'
});

i18next.addResources('en', 'CreditStatusScreen', {
  title: 'Credit Status',
  titleDetail: 'Customer Detail',
  download: 'Download excel',
  errorDownloadExcel: 'Download failed',
  listCreditStatus: 'Invoices list',
  crediStatus: 'Credit Status',
  summaryClients: 'Customer summary',
  paginationItem: 'invoices',
  back: 'Back',
  lastUpdate: 'Last update'
});

i18next.addResources('es', 'CreditStatusExcel', {
  headers: [
    [
      'País',
      'Mercado',
      'Cliente',
      'Cod. Cliente Sap',
      'Factura',
      'Factura SAP',
      'Fecha de documento',
      'Vencimiento',
      'Días por vencer',
      'Días Vencidos',
      'Toneladas',
      'Importe Factura',
      'Pagos',
      'moneda',
      'Saldo'
    ]
  ]
});

i18next.addResources('en', 'CreditStatusExcel', {
  headers: [
    [
      'Country',
      'Market',
      'Customer',
      'Cod. Customer Sap',
      'Invoice',
      'SAP Invoice',
      'Document Date',
      'Expiration',
      'Days till expired',
      'Days expired',
      'Tons',
      'Invoice amount',
      'Payment',
      'Currency',
      'Balance'
    ]
  ]
});

i18next.addResources('es', 'CreditStatusKpiScreen', {
  title: 'Consumo línea de crédito'
});

i18next.addResources('en', 'CreditStatusKpiScreen', {
  title: 'Credit Consumption Line'
});

i18next.addResources('es', 'CreditStatusKpiFactureScreen', {
  title1: 'Facturas por vencer',
  subtitle11: '> a 7 días',
  subtitle12: '≤ 7 días',
  title2: 'Facturas vencidas',
  subtitle21: '≤ 20 días',
  subtitle22: '> a 20 días'
});

i18next.addResources('en', 'CreditStatusKpiFactureScreen', {
  title1: 'Invoice to due',
  subtitle11: '> 7 days',
  subtitle12: '≤ 7 days',
  title2: 'Invoice dued',
  subtitle21: '≤ 20 days',
  subtitle22: '> to 20 days'
});

i18next.addResources('es', 'CreditStatusKpiConsumptionScreen', {
  consumption: 'Consumo',
  total: 'Total cuentas',
  limit: 'Límite de crédito'
});

i18next.addResources('en', 'CreditStatusKpiConsumptionScreen', {
  consumption: 'Consumption',
  total: 'Account total',
  limit: 'Credit limit'
});

i18next.addResources('es', 'CreditStatusMasterDataScreen', {
  title: 'Datos Maestros Cliente',
  client: 'Nombre del Cliente',
  country: 'País',
  account: 'Número de cuenta',
  currency: 'Moneda de línea de Crédito',
  limit: 'Límite de Crédito',
  status: 'Estatus Fiber Place'
});

i18next.addResources('en', 'CreditStatusMasterDataScreen', {
  title: 'Master customer data',
  client: 'Customer name',
  country: 'Country',
  account: 'Account number',
  currency: 'Credit line currency',
  limit: 'Credit limit',
  status: 'Fiber Place Status'
});

i18next.addResources('es', 'CreditStatusMasterDataStatusScreen', {
  available: 'Habilitado',
  blocked: 'Bloqueado',
  blockedDispatch: 'Despacho bloqueado',
  blockedOrder: 'Ingreso de pedido bloqueado'
});

i18next.addResources('en', 'CreditStatusMasterDataStatusScreen', {
  available: 'Available',
  blocked: 'Blocked',
  blockedDispatch: 'Blocked dispatch',
  blockedOrder: 'Order login blocked'
});

i18next.addResources('es', 'CreditStatusCurrency', {
  gbp: 'Libra esterlina',
  clp: 'Peso chileno',
  eur: 'Euro',
  usd: 'Dólar estadounidense'
});

i18next.addResources('en', 'CreditStatusCurrency', {
  gbp: 'Pound sterling',
  clp: 'Chilean peso',
  eur: 'Euro',
  usd: 'United States dollar'
});

i18next.addResources('es', 'SummaryListCreditStatus', {
  riskByExhaustionOfCreditLine: 'Riesgo por agotamiento de línea de crédito',
  pageTitle: 'Resumen clientes',
  construction: 'En construcción',
  paginationItem: 'clientes',
  noResultsText: 'No tiene clientes para mostrar',
  lastUpdate: 'Última actualización'
});

i18next.addResources('en', 'SummaryListCreditStatus', {
  riskByExhaustionOfCreditLine: 'Risk by exhaustion of credit line',
  construction: 'Under construction',
  pageTitle: 'Customer summary',
  paginationItem: 'customers',
  noResultsText: 'No customers found',
  lastUpdate: 'Last update'
});

i18next.addResources('es', 'ResumeHeadersCreditStatus', {
  country: 'País',
  customer: 'Cliente',
  creditLine: 'Agotamiento de línea',
  overdueDebt: 'Deuda Vencida',
  status: 'Estatus',
  risk: 'Riesgo',
  noData: 'No hay datos',
  highRisk: 'Riesgo alto',
  mediumRisk: 'Riesgo medio',
  lowRisk: 'Riesgo bajo',
  market: 'Mercado'
});

i18next.addResources('en', 'ResumeHeadersCreditStatus', {
  country: 'Country',
  customer: 'Customer',
  creditLine: 'Credit line exhaustion',
  overdueDebt: 'Overdue debt',
  status: 'Status',
  risk: 'Risk',
  noData: 'No data',
  highRisk: 'High risk',
  mediumRisk: 'Medium risk',
  lowRisk: 'Low risk',
  market: 'Market'
});

i18next.addResources('es', 'SummaryClientsExcel', {
  filename: 'lista clientes',
  headers: [
    [
      'Cliente',
      'Mercado',
      'País',
      'Número de cuenta',
      'Moneda',
      'Riesgo',
      'Límite de crédito',
      'Consumo de crédito',
      'Agotamiento de línea %',
      'Facturas vencidas',
      'Días de facturas vencidas',
      'Monto de facturas vencidas',
      'Facturas por vencer',
      'Días de facturas por vencer',
      'Monto de facturas por vencer'
    ]
  ]
});

i18next.addResources('en', 'SummaryClientsExcel', {
  filename: 'customer list',
  headers: [
    [
      'Customer',
      'Market',
      'Country',
      'Account',
      'Currency',
      'Risk',
      'Credit limit',
      'Consumption credit',
      'Exhaustion %',
      'Invoices due',
      'Days of invoices due',
      'Amount of invoices due',
      'Invoices to due',
      'Days of invoices to due',
      'Amount of invoices to due'
    ]
  ]
});
