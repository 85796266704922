import React from 'react';

import spinner from './assets/spinner.gif';

interface Props {
  className: string;
}
function SpinnerFiberPlace({ className }: Props) {
  return (
    <div className={className}>
      <img src={spinner} alt="loading-fiberPlace" />
    </div>
  );
}

export default SpinnerFiberPlace;
