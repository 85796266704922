import i18next from 'i18next';

i18next.addResources('es', 'Sidebar', {
  cmpcIcon: 'Icono CMPC',
  orders: 'Pedidos',
  activityCenter: 'Centro de Actividad',
  users: 'Usuarios',
  es: 'Español',
  en: 'English',
  accountSettings: 'Configuraciones',
  logout: 'Cerrar sesión',
  articles: 'Artículos',
  productionCycle: 'Ciclos de Producción',
  certificates: 'Certificados',
  stockControl: 'Control de Stock',
  home: 'Inicio',
  helpCenter: 'Centro de ayuda',
  creditStatus: 'Estatus Crediticio',
  documents: 'Documentos',
  tracking: 'Módulo de tracking',
  admin: 'Administrador Fiber Place'
});

i18next.addResources('en', 'Sidebar', {
  cmpcIcon: 'CMPC icon',
  orders: 'Orders',
  activityCenter: 'Activity Center',
  users: 'Users',
  es: 'Español',
  en: 'English',
  accountSettings: 'Settings',
  logout: 'Logout',
  articles: 'Articles',
  productionCycle: 'Production Cycles',
  certificates: 'Certificates',
  stockControl: 'Stock Control',
  helpCenter: 'Help Center',
  home: 'Home',
  creditStatus: 'Credit Status',
  documents: 'Documents',
  tracking: 'Tracking',
  admin: 'Fiber Place Administrator'
});
