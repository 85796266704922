import i18next from 'i18next';

i18next.addResources('es', 'OptionPanelUser', {
  activeUser: 'Activar usuario',
  disableUser: 'Deshabilitar usuario',
  editUser: 'Editar usuario',
  questionEnable: '¿Está seguro de que desea habilitar el usuario ',
  questionDisable: '¿Está seguro de que desea deshabilitar  ',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  msgError: 'Ocurrió un error',
  msgSuccessEnabled: 'Usuario activado correctamente',
  msgSuccessDisabled: 'Usuario desactivado correctamente',
  msgErrorRejected: 'Usuario a rechazado la proforma',
  msgSucessAccepted: 'Usuario a Aceptado la proforma'
});

i18next.addResources('en', 'OptionPanelUser', {
  activeUser: 'Active user',
  disableUser: 'Disable user',
  editUser: 'Edit user',
  questionEnable: 'Are you sure you want to enable the ',
  questionDisable: 'Are you sure you want to disable the ',
  questionEnd: ' user',
  confirm: 'Confirm',
  cancel: 'Cancel',
  msgError: 'An error ocurred',
  msgSuccessEnabled: 'User successfully activated',
  msgSuccessDisabled: 'User successfully disabled'
});
