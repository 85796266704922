import { History } from 'history';

import { Nullable } from 'types/Global';
import { ReactComponent as SpanishFlag } from 'assets/flag-spanish.svg';
import { ReactComponent as EnglishFlag } from 'assets/flag-english.svg';
import { LANGUAGES, changeLanguage, getCurrentLanguage } from 'utils/i18n';
import { SCREENS } from 'components/Routes/constants';
import paths from 'components/Routes/paths';
import { UserTypeId } from 'types/User';

import { ReactComponent as HomeIcon } from './assets/ic_home.svg';
import { ReactComponent as OrdersIcon } from './assets/orders.svg';
import { ReactComponent as UsersIcon } from './assets/users.svg';
import { ReactComponent as ActivityCenterIcon } from './assets/activity-center.svg';
import { ReactComponent as AccountSettingsIcon } from './assets/ic_settings.svg';
import { ReactComponent as ItemIcon } from './assets/ic_item.svg';
import { ReactComponent as LogoutIcon } from './assets/ic_logout.svg';
import { ReactComponent as ProductionCycle } from './assets/ic_history.svg';
import { ReactComponent as Certificates } from './assets/ic_certificate.svg';
import { ReactComponent as StockControl } from './assets/ic_control_stock.svg';
import { ReactComponent as CreditStatus } from './assets/ic-invoice.svg';
import { ReactComponent as Documents } from './assets/ic-proforma.svg';
import { ReactComponent as HelpCenter } from './assets/ic-question.svg';
import { ReactComponent as Tracking } from './assets/ic-delivery-ship.svg';
import { trackEventMixPanel } from '../../utils/mixpanelTrack';

export interface ItemProps {
  id: number;
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  markAsSelected?: boolean;
  showOnlyMobile: boolean;
  roles?: UserTypeId[];
}
export interface SidebarItems {
  items: ItemProps[];
  onClickItem: (item: number, history: History) => void;
  defaultItemSelected: Nullable<number>;
}

export const HOME_SIDEBAR_PATHS = [
  paths.home,
  paths.orders,
  paths.users,
  paths.activityCenter,
  paths.accountSettings,
  paths.articles,
  paths.cycles,
  paths.certificates,
  paths.login,
  paths.stockControl,
  paths.creditStatus,
  paths.documents,
  paths.helpCenter,
  paths.tracking,
  paths.admin
];

const sidebarItems = (screen = '', logout: () => void): SidebarItems => {
  let behaviorItems: SidebarItems = {
    items: [
      { id: 0, name: 'home', icon: HomeIcon, showOnlyMobile: false },
      { id: 1, name: 'orders', icon: OrdersIcon, showOnlyMobile: false },
      { id: 2, name: 'users', icon: UsersIcon, showOnlyMobile: false, roles: [UserTypeId.SUPERUSER] },
      { id: 3, name: 'activityCenter', icon: ActivityCenterIcon, showOnlyMobile: false },
      { id: 13, name: 'tracking', icon: Tracking, showOnlyMobile: false },
      { id: 4, name: 'accountSettings', icon: AccountSettingsIcon, showOnlyMobile: true },
      {
        id: 5,
        name: 'articles',
        icon: ItemIcon,
        showOnlyMobile: false,
        roles: [UserTypeId.SUPERUSER, UserTypeId.CMPC]
      },
      { id: 6, name: 'productionCycle', icon: ProductionCycle, showOnlyMobile: false },
      { id: 7, name: 'certificates', icon: Certificates, showOnlyMobile: false },
      { id: 8, name: 'logout', icon: LogoutIcon, showOnlyMobile: true },
      { id: 9, name: 'stockControl', icon: StockControl, showOnlyMobile: false },
      { id: 10, name: 'creditStatus', icon: CreditStatus, showOnlyMobile: false },
      { id: 11, name: 'documents', icon: Documents, showOnlyMobile: false },
      { id: 12, name: 'helpCenter', icon: HelpCenter, showOnlyMobile: false },
      { id: 14, name: 'admin', icon: AccountSettingsIcon, showOnlyMobile: false }
    ],
    onClickItem: (item, history) => {
      if (behaviorItems.items.find(sideBarItem => sideBarItem.id === item)?.name === 'logout') {
        logout();
      } else {
        trackEventMixPanel(HOME_SIDEBAR_PATHS[item]);
        history.push(HOME_SIDEBAR_PATHS[item]);
      }
    },
    defaultItemSelected: null
  };
  switch (screen) {
    case SCREENS.LOGIN:
      behaviorItems = {
        items: [
          { id: 0, name: 'es', icon: SpanishFlag, markAsSelected: false, showOnlyMobile: false },
          { id: 1, name: 'en', icon: EnglishFlag, markAsSelected: false, showOnlyMobile: false }
        ],
        onClickItem: item => changeLanguage(LANGUAGES[item]),
        defaultItemSelected: getCurrentLanguage() === LANGUAGES[0] ? 0 : 1
      };
      break;
    default:
      break;
  }
  return behaviorItems;
};

export { sidebarItems };

export const isLoginScreens = (screen: string) => screen === SCREENS.LOGIN;
