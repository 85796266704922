import i18next from 'i18next';

i18next.addResources('es', 'ConfirmOrder', {
  orderDiscarded: 'Orden de compra descartada',
  orderValidated: '¡Orden de compra validada con éxito!',
  orderCreated: '¡Pedido creado con éxito!',
  goToOrders: 'Ir a listado de pedidos'
});

i18next.addResources('en', 'ConfirmOrder', {
  orderDiscarded: 'Purchase order discarded',
  orderValidated: '¡Purchase order validated successfully!',
  orderCreated: 'Order successfully created!',
  goToOrders: 'Go to orders list'
});
