/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as BoxboardLogo } from 'assets/logo-boxboard.svg';
import { ReactComponent as CMPCLogo } from 'assets/logo-cmpc.svg';
import { SidebarItems } from 'components/Layout/constants';
import { useDispatch } from 'contexts/SidebarContext';
import { actionCreators } from 'contexts/SidebarContext/reducer';
import { Context } from 'contexts/UserContext';
import { Nullable } from 'types/Global';
import { User } from 'types/User';
import usePermission from 'hooks/usePermission';
import FiberIconSidebar from 'assets/logo-fiber.svg';

import FiberIconOff from './assets/fiber-icon.svg';
import Item from './components/Item';
import SidebarArrow from './components/SidebarArrow';
import CmpcIconOff from './assets/cmpc-icon.svg';
import { filterItemsAuth, getItemSelected } from './utils';
import styles from './styles.module.scss';

interface Props {
  onClickSidebarMobile: () => void;
  sidebarMobileIsOpen: boolean;
  sidebarIsOpen: boolean;
  withSidebarTabletAndMobile: boolean;
  user: Nullable<User>;
  sidebarItems: SidebarItems;
}

function Sidebar({
  onClickSidebarMobile,
  sidebarIsOpen,
  sidebarMobileIsOpen,
  withSidebarTabletAndMobile,
  user,
  sidebarItems: { defaultItemSelected, onClickItem, items }
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const { hasRole } = usePermission();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showComplete, setShowComplete] = useState(false);
  const [showBackgroundComplete, setShowBackgroundComplete] = useState(false);
  const [itemSelected, setItemSelected] = useState<number>(
    getItemSelected(defaultItemSelected, location.pathname)
  );
  const { centroDeAyuda } = useFlags();

  const context = useContext(Context);
  const { state } = context;
  const { user: userData } = state;

  const onSelectArrow = () => {
    setShowComplete(false);
    dispatch(actionCreators.showSidebar());
    setShowBackgroundComplete(false);
  };

  const handleMouseOver = () => {
    setShowComplete(true);
    setShowBackgroundComplete(true);
  };

  const handleBackgroundClick = () => {
    setShowBackgroundComplete(false);
    if (sidebarMobileIsOpen) {
      onClickSidebarMobile();
    }
  };

  const handleMouseLeave = () => setShowComplete(false);

  const onClick = (item: number) => () => {
    setItemSelected(item);
    onClickItem(item, history);
  };

  const sidebarComplete = showComplete || sidebarIsOpen || sidebarMobileIsOpen;

  let rawAvailableItems = filterItemsAuth(items, hasRole);

  if (!userData?.permissions?.includes('can_view_credit_status')) {
    rawAvailableItems = rawAvailableItems.filter(item => item.name !== 'creditStatus');
  }

  if (!userData?.permissions?.includes('can_view_stock_controller')) {
    rawAvailableItems = rawAvailableItems.filter(item => item.name !== 'stockControl');
  }

  if (!userData?.permissions?.includes('can_view_tracking')) {
    rawAvailableItems = rawAvailableItems.filter(item => item.name !== 'tracking');
  }

  if (!userData?.permissions?.includes('can_view_admin')) {
    rawAvailableItems = rawAvailableItems.filter(item => item.name !== 'admin');
  }

  if (!centroDeAyuda) {
    rawAvailableItems = rawAvailableItems.filter(item => item.name !== 'helpCenter');
  }

  const availableItems = rawAvailableItems;

  const itemsOnlyMobile = availableItems.filter(item => item.showOnlyMobile === true);

  const itemsNotOnlyMobile = availableItems.filter(item => item.showOnlyMobile === false);

  return (
    <>
      <div
        className={cn([styles.background], {
          [styles.backgroundComplete]: showBackgroundComplete || sidebarMobileIsOpen
        })}
        onClick={handleBackgroundClick}
      />
      <div
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        className={cn(
          'column space-between',
          [styles.sidebar],
          { [styles.complete]: sidebarComplete },
          { [styles.sidebarTabletAndMobile]: sidebarMobileIsOpen },
          { [styles.onlyTabletAndMobile]: withSidebarTabletAndMobile }
        )}
      >
        <div>
          <div className={`row middle center ${styles.cmpcIcon}`}>
            <img
              src={sidebarComplete ? FiberIconSidebar : FiberIconOff}
              alt={t('Sidebar:cmpcIcon')}
              className={cn([styles.cmpcIconOff], {
                [styles.cmpcIconOn]: sidebarComplete
              })}
            />
          </div>
          {!withSidebarTabletAndMobile && (
            <SidebarArrow
              onClick={onSelectArrow}
              showComplete={showComplete}
              sidebarIsOpen={sidebarIsOpen}
              sidebarMobileIsOpen={sidebarMobileIsOpen}
              username={user?.name || ''}
            />
          )}
          <div className={styles.itemsContainer}>
            {itemsNotOnlyMobile.map(({ id, name, icon, markAsSelected }) => (
              <Item
                key={id}
                id={id}
                name={name}
                icon={icon}
                onClick={onClick}
                itemSelected={itemSelected}
                itemComplete={sidebarComplete}
                markAsSelected={markAsSelected}
              />
            ))}
          </div>
        </div>
        <div>
          {sidebarMobileIsOpen &&
            itemsOnlyMobile.map(({ id, name, icon, markAsSelected }) => (
              <Item
                key={id}
                id={id}
                name={name}
                icon={icon}
                onClick={onClick}
                itemSelected={itemSelected}
                itemComplete={sidebarComplete}
                markAsSelected={markAsSelected}
              />
            ))}
          <div className="column">
            {sidebarComplete ? (
              <>
                <CMPCLogo className="self-center m-bottom-4" />
                <BoxboardLogo className="self-center" />
              </>
            ) : (
              <>
                <img src={CmpcIconOff} alt={t('Sidebar:cmpcIcon')} className="self-center" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
