import i18next from 'i18next';

i18next.addResources('es', 'ActionField', {
  isValidated: 'Validado',
  actions: 'Acciones'
});

i18next.addResources('en', 'ActionField', {
  isValidated: 'Validated',
  actions: 'Actions'
});
