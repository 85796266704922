export interface SidebarState {
  showSidebar: boolean;
  fixedHeader: boolean;
}

export const INITIAL_STATE = {
  showSidebar: false,
  fixedHeader: false
};

export enum ActionTypes {
  SHOW_SIDEBAR = 'SHOW_SIDEBAR',
  FIXED_HEADER = 'FIXED_HEADER'
}

interface ShowSidebar {
  type: ActionTypes.SHOW_SIDEBAR;
}

interface FixedHeader {
  type: ActionTypes.FIXED_HEADER;
}

export type Action = ShowSidebar | FixedHeader;

export const actionCreators = {
  showSidebar: (): ShowSidebar => ({ type: ActionTypes.SHOW_SIDEBAR }),
  fixedHeader: (): FixedHeader => ({ type: ActionTypes.FIXED_HEADER })
};

export const reducer = (state: SidebarState, action: Action): SidebarState => {
  switch (action.type) {
    case ActionTypes.SHOW_SIDEBAR: {
      return { ...state, showSidebar: !state.showSidebar };
    }
    case ActionTypes.FIXED_HEADER: {
      return { ...state, fixedHeader: !state.fixedHeader };
    }
    default: {
      return state;
    }
  }
};
