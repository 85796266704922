import i18next from 'i18next';

i18next.addResources('es', 'LoadItems', {
  loadedItems: 'Items ({{loadedItems}})',
  pending: 'Pendientes ({{pendingItems}})',
  activity: 'Actividad ({{activityItems}})',
  loadedItemsSingle: 'Items',
  pendingSingle: 'Pendientes',
  activitySingle: 'Actividad',
  addItem: '+ Cargar item',
  validateAllItems: 'Validar todos los items',
  doYouWantValidate: '¿Confirmas que deseas validar todos los items de la orden de compra?',
  itemsValidated: '¡Los items han sido validados en la orden de compra!',
  validatedItems: 'Items validados {{validated}}/{{total}}',
  incompatibleDiameterAndPackaging: 'No se puede validar este articulo, el embalaje no es el correcto'
});

i18next.addResources('en', 'LoadItems', {
  loadedItems: 'Items ({{loadedItems}})',
  pending: 'Pending ({{pendingItems}})',
  activity: 'Activity ({{activityItems}})',
  loadedItemsSingle: 'Items',
  pendingSingle: 'Pending',
  activitySingle: 'Activity',
  addItem: '+ Add item',
  validateAllItems: 'Validate all items',
  doYouWantValidate: 'Do you confirm that you want validate all the items of the purchase order?',
  itemsValidated: 'The items have been validated in the purchase order!',
  validatedItems: 'Validated items {{validated}}/{{total}}',
  incompatibleDiameterAndPackaging: 'This item cannot be validated, the packaging is not correct.'
});
