import i18next from 'i18next';

i18next.addResources('es', 'HelpCenterScreenOrderDetail', {
  orderResume: 'Resumen de pedido',
  p1:
    'Lo primero que se puede encontrar en la vista de detalle del pedido, es el resumen de este mismo, tal como se muestra en la Lista de pedidos.',
  p2:
    'En la sección inferior del Pedido, encontrarás tres pestañas que contienen información detallada relacionada con el pedido.',
  ol1: 'Pestañas Disponibles:',
  ul1:
    'Ítems del Pedido: Visualiza la totalidad de ítems asociados al pedido y el detalle específico de cada uno de ellos.',
  ul2: 'Despachos: Accede a la información detallada sobre los despachos asociados al pedido.',
  ul3: 'Actividad: Revisa la actividad reciente y relevante relacionada con el pedido.',
  ol2: 'Ítems del pedido',
  p3:
    'En la pestaña "Ítems del Pedido", se presenta información detallada que facilita la comprensión y gestión de los productos incluidos en el pedido. A continuación, se detallan los elementos mostrados:',
  ul4: 'N° de Ítem: Número único asignado a cada ítem del pedido.',
  ul5: 'Tipo de Producto: Indica el tipo de producto asociado al ítem.',
  ul6: 'Cantidad de Unidades: Muestra la cantidad total de unidades del ítem.',
  ul7: 'Formato: Especifica el formato del producto.',
  ul8: 'Toneladas Totales: Indica el peso total del ítem en toneladas.',
  ul9: 'Fecha de Ingreso: Informa la fecha en que el ítem fue ingresado al sistema.',
  ul10: 'Estado del Ítem: Indica el estado actual del ítem en el proceso.',
  ul11: 'Certificados: Proporciona información relevante sobre los certificados asociados al ítem.',
  ol4: 'Funcionalidad Adicional:',
  p4:
    'La pestaña "Ítems del Pedido" ofrece una funcionalidad adicional que permite descargar directamente los Certificados de Calidad Average del ítem. También, tienes la opción de descargar certificados de cada unidad por separado.',
  p5:
    'Esta característica simplifica el proceso de obtener información detallada y certificaciones asociadas a los ítems del pedido.',
  p6:
    'Recuerda explorar todas las pestañas disponibles para obtener una vista completa y detallada de la información relacionada con el pedido. La pestaña "Ítems del Pedido" se presenta como una herramienta valiosa para gestionar eficientemente la información detallada de los productos incluidos en cada pedido.',
  ol5: 'Despachos',
  p7:
    'La pestaña "Despachos" ofrece una visión completa de todos los despachos vinculados al pedido. Aquí se presenta la siguiente información:',
  ul12: 'Número de Embarque: Identifica de manera única cada despacho asociado al pedido.',
  ul13: 'Fecha de Despacho: Indica la fecha en que se realizó el despacho.',
  ul14: 'N° de Unidades: Muestra la cantidad total de unidades despachadas.',
  ul15: 'Tonelaje: Indica el peso total despachado en toneladas.',
  ul16: 'Semana ETA: Especifica la semana estimada de llegada (ETA, por sus siglas en inglés) del despacho.',
  ol6: 'Funcionalidad Adicional:',
  p8:
    'Además de proporcionar información detallada sobre los despachos, la pestaña "Despachos" incluye las siguientes funcionalidades:',
  ul17:
    'Descarga de Packing List: Permite descargar el Packing List asociado al Embarque para obtener una lista detallada de los productos despachados.',
  ul18:
    'Confirmación de Recepción: Facilita la indicación de la recepción conforme del despacho, proporcionando una manera rápida y efectiva de registrar la recepción de los productos despachados.',
  p9:
    'Esta pestaña sirve como una herramienta valiosa para seguir y gestionar eficientemente los despachos asociados a cada pedido. Aprovecha las funcionalidades adicionales para simplificar el proceso de documentación y gestión logística.',
  p10:
    'Recuerda explorar todas las pestañas disponibles para obtener una visión completa de la información relacionada con el pedido.',
  orderStatus: 'Estados del pedido',
  p11: 'El pedido va cambiando su estado según las actividades que se van generando ',
  draft: 'Borrador',
  draftDescription: 'Se ingresa orden, es guardada para continuar mas tarde',
  discarded: 'Descartada',
  discardedDescription: 'Cliente elimina orden de compra',
  cmpcValidation: 'Validacion Cmpc',
  cmpcValidationDescription: 'Ordeningresada en espera de validacion Cmpc',
  validated: 'Validada',
  validatedDescription: 'Orden validada por Cmpc',
  orderEntered: 'Pedido Ingresado',
  orderEnteredDescription: 'Pedido ingresado en sistemas Cmpc',
  proformaValidation: 'Validacion Proforma',
  proformaValidationDescription: 'Proforma generada a la espera de validacion de Cliente',
  proformaRejected: 'Proforma Rechazada',
  proformaRejectedDescription: 'Proforma Rechazada por Cliente',
  schedulingOrder: 'Programando Pedido',
  schedulingOrderDescription: 'Se programa pedido en sistema',
  orderSchedule: 'Pedido Programado',
  orderScheduleDescription: 'Se programa pedido en maquina papelera',
  inProcess: 'En Proceso',
  inProcessDescription: 'Pedido esta en produccion a la espera de su despacho',
  orderInvoiced: 'Facturado Total',
  orderInvoicedDescription: 'Pedido Facturado',
  orderClosed: 'Pedido Cerrado',
  orderClosedDescription: 'Pedido completamente despachado'
});

i18next.addResources('en', 'HelpCenterScreenOrderDetail', {
  orderResume: 'Order Summary',
  p1:
    'The first thing you can find in the order detail view is the summary of the order, as shown in the Order List.',
  p2:
    'In the lower section of the Order, you will find three tabs containing detailed information related to the order.',
  ol1: 'Available Tabs:',
  ul1: 'Order Items: View all items associated with the order and the specific detail of each of them.',
  ul2: 'Dispatches: Access detailed information about the dispatches associated with the order.',
  ul3: 'Activity: Review recent and relevant activity related to the order. ',
  ol2: 'Order Items',
  p3:
    'In the "Order Items" tab, detailed information is presented to facilitate the understanding and management of the products included in the order. The displayed elements are detailed below:',
  ul4: 'Item Number: Unique number assigned to each item of the order.',
  ul5: 'Product Type: Indicates the product type associated with the item.',
  ul6: 'Quantity of Units: Displays the total quantity of units for the item. ',
  ul7: 'Format: Specifies the format of the product. ',
  ul8: 'Total Tons: Indicates the total weight of the item in tons.',
  ul9: 'Entry Date: Informs the date the item was entered into the system.',
  ul10: 'Item Status: Indicates the current status of the item in the process.',
  ul11: 'Certificates: Provides relevant information about certificates associated with the item.',
  ol4: 'Additional Functionality:',
  p4:
    'The "Order Items" tab offers additional functionality that allows you to directly download the Average Quality Certificates of the item. Also, you have the option to download certificates for each unit separately.',
  p5:
    'This feature simplifies the process of obtaining detailed information and certifications associated with the items of the order.',
  p6:
    'Remember to explore all available tabs to obtain a complete and detailed view of the information related to the order. The "Order Items" tab serves as a valuable tool for efficiently managing the detailed information of the products included in each order.',
  ol5: 'Dispatches',
  p7:
    'The "Dispatches" tab offers a complete view of all dispatches linked to the order. Here is the following information presented:',
  ul12: 'Shipment Number: Uniquely identifies each dispatch associated with the order.',
  ul13: 'Dispatch Date: Indicates the date the dispatch was made.',
  ul14: 'Number of Units: Displays the total number of units dispatched.',
  ul15: 'Tonnage: Indicates the total weight dispatched in tons.',
  ul16: 'ETA Week: Specifies the estimated arrival week (ETA) of the dispatch. ',
  ol6: 'Additional Functionalities:',
  p8:
    'In addition to providing detailed information about dispatches, the "Dispatches" tab includes the following functionalities:',
  ul17:
    'Packing List Download: Allows downloading the Packing List associated with the Shipment to obtain a detailed list of the dispatched products.',
  ul18:
    'Receipt Confirmation: Facilitates indicating the receipt confirmation of the dispatch, providing a quick and effective way to record the receipt of the dispatched products. ',
  p9:
    'This tab serves as a valuable tool for tracking and efficiently managing the dispatches associated with each order. Take advantage of the additional functionalities to simplify the documentation and logistical management process.',
  p10:
    'Remember to explore all available tabs to obtain a complete view of the information related to the order.',
  orderStatus: 'Order Statuses',
  p11: 'The order changes its status according to the activities generated.',
  draft: 'Draft',
  draftDescription: 'Order is entered, saved for continuation at a later date',
  discarded: 'Discarded',
  discardedDescription: 'Customer deletes purchase order',
  cmpcValidation: 'Cmpc validation',
  cmpcValidationDescription: 'Order entered awaiting Cmpc validation',
  validated: 'Validated',
  validatedDescription: 'Order validated by Cmpc',
  orderEntered: 'Order Entered',
  orderEnteredDescription: 'Order entered in Cmpc systems',
  proformaValidation: 'Proforma Validation',
  proformaValidationDescription: 'Proforma generated awaiting validation by Client',
  proformaRejected: 'Proforma Rejected',
  proformaRejectedDescription: 'Proforma Rejected by Client',
  schedulingOrder: 'Scheduling Order',
  schedulingOrderDescription: 'Order is programmed in system',
  orderSchedule: 'Order Schedule',
  orderScheduleDescription: 'Order is programmed on paper machine',
  inProcess: 'In Process',
  inProcessDescription: 'Order is in production awaiting shipment',
  orderInvoiced: 'Order Invoiced',
  orderInvoicedDescription: 'Total Invoiced Order',
  orderClosed: 'Order Closed',
  orderClosedDescription: 'Order completely shipped'
});
