import { initialize, LDOptions } from 'launchdarkly-react-client-sdk';

const user = {
  key: 'help-center-module',
  name: 'Help center'
};

const options: LDOptions = { bootstrap: 'localStorage' };
const ldClient = initialize(process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT!, user, options);

export default ldClient;
