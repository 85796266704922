import i18next from 'i18next';

i18next.addResources('es', 'DatePicker', {
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre',
  month: 'Mes',
  year: 'Año',
  dateFrom: 'Fecha desde',
  dateTo: 'Fecha hasta'
});

i18next.addResources('en', 'DatePicker', {
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  month: 'Month',
  year: 'Year',
  dateFrom: 'Date from',
  dateTo: 'Date to'
});
