/* eslint-disable babel/no-unused-expressions */
import React, { useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Suspense from 'components/Suspense';
import { useSelector } from 'contexts/UserContext';

import { MAIN_PUBLIC_PATH, ROUTES } from './constants';
import RouteItem from './components/RouteItem';
import styles from './styles.module.scss';
import { generateUserRedirect } from './utils';
import paths from './paths';

function Routes() {
  const { centroDeAyuda } = useFlags();
  let RoutesToShow = ROUTES;
  if (!centroDeAyuda) {
    RoutesToShow = ROUTES.filter(route => route.path !== '/helpCenter');
  }

  const user = useSelector(state => state.user);
  const routes = useMemo(
    () =>
      RoutesToShow.map(({ tags, roles, path, permission, showCsat, ...config }) => {
        const redirect = generateUserRedirect({
          auth: user !== null,
          tags,
          roles,
          path,
          userTypeId: user?.userTypeId,
          permission,
          userPermissions: user?.permissions
        });
        const storage = window.localStorage.getItem('redirectTo');
        const loggedStatus = window.localStorage.getItem('logged');
        if (!storage && !Object.values(paths).includes(window.location.pathname) && !loggedStatus) {
          window.localStorage.setItem('redirectTo', window.location.pathname);
        }
        if (loggedStatus) {
          window.localStorage.removeItem('redirectTo');
          window.localStorage.removeItem('logged');
        }
        return (
          <RouteItem
            key={path}
            path={path}
            redirectTo={redirect === path ? undefined : redirect}
            showCsat={showCsat}
            {...config}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <Router>
      <div className={styles.container}>
        <Suspense>
          <Switch>
            {routes}
            <Route path={paths.home}>
              <Redirect to={MAIN_PUBLIC_PATH} />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default Routes;
