import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useClickAway } from 'react-use';

import { useSelector as useSelectorUser } from 'contexts/UserContext';
import { useDispatch, useSelector as useSelectorSidebar } from 'contexts/SidebarContext';
import { actionCreators } from 'contexts/SidebarContext/reducer';
import { useHistory } from 'react-router';

import { ReactComponent as FiberLogo } from '../../assets/logo-fiber-cmpc.svg';

import Hamburger from './components/Hamburger';
import Badge from './components/Badge';
import OptionPanel from './components/OptionPanel';
import styles from './styles.module.scss';
import NotificationPanel from './components/NotificationPanel';

interface Props {
  onClick: () => void;
  hamburger: boolean;
  transparentHeader?: boolean;
}
function Header({ onClick, hamburger, transparentHeader = false }: Props) {
  const history = useHistory();
  const [showOptionPanel, setShowOptionPanel] = useState(false);
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const dispatch = useDispatch();
  const refNotificationPanel = useRef(null);
  const refOptionPanel = useRef(null);

  const user = useSelectorUser(state => state.user!);

  const { name, lastName, email, userType, countryId } = user;
  const isFixed = useSelectorSidebar(state => state.fixedHeader);

  const handleNotificationsPanel = () => {
    dispatch(actionCreators.fixedHeader());
    setShowNotificationsPanel(!showNotificationsPanel);
  };

  const handleRedirecToHelpCenter = () => {
    history.push('/helpCenter');
  };

  useClickAway(refNotificationPanel, () => {
    if (showNotificationsPanel) {
      dispatch(actionCreators.fixedHeader());
      setShowNotificationsPanel(false);
    }
  });

  useClickAway(refOptionPanel, () => {
    if (showOptionPanel) {
      setShowOptionPanel(false);
    }
  });

  const handleOptionPanel = () => setShowOptionPanel(!showOptionPanel);

  return (
    <div
      className={cn(styles.container, {
        [styles.containerTransparent]: transparentHeader,
        [styles.fixed]: isFixed
      })}
    >
      <Hamburger onClick={onClick} hamburger={hamburger} />
      <FiberLogo className={styles.imageHeader} />
      <div
        className={cn('row center middle', styles.oval, {
          [styles.ovalWhite]: transparentHeader
        })}
        onClick={handleRedirecToHelpCenter}
      >
        <div
          className={cn(styles.inputIcon, {
            [styles.iconActive]: showNotificationsPanel
          })}
        >
          ?
        </div>
      </div>
      <div
        className={cn('row center middle', styles.oval, {
          [styles.ovalWhite]: transparentHeader
        })}
      >
        <i
          className={cn('icon-ic-notification', styles.inputIcon, {
            [styles.iconActive]: showNotificationsPanel
          })}
          onClick={handleNotificationsPanel}
        />
        <Badge
          className={cn(styles.badge, {
            [styles.badgeWhite]: transparentHeader
          })}
          numberClassName={styles.numberNotifications}
        />
        <div className={styles.notificationsPanel}>
          <NotificationPanel
            refTo={refNotificationPanel}
            showComponent={showNotificationsPanel}
            setShowComponent={setShowNotificationsPanel}
          />
        </div>
      </div>
      <div
        className={cn('row center middle', styles.oval, styles.optionPanel, {
          [styles.ovalWhite]: transparentHeader
        })}
      >
        <i
          className={cn('icon-ic-user', styles.inputIcon, { [styles.iconActive]: showOptionPanel })}
          onClick={handleOptionPanel}
        />
        <OptionPanel
          showComponent={showOptionPanel}
          handleClose={setShowOptionPanel}
          username={`${name} ${lastName}`}
          email={`${email}`}
          userType={userType ? `${userType}` : ''}
          country={countryId || ''}
          refTo={refOptionPanel}
        />
      </div>
    </div>
  );
}

export default Header;
