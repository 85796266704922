import i18next from 'i18next';

i18next.addResources('es', 'UserList', {
  status: 'Estado',
  client: 'Cliente / Agente',
  user: 'Usuario',
  type: 'Tipo',
  assignedAgent: 'Agente asignado',
  mail: 'Email',
  market: 'Mercado'
});

i18next.addResources('en', 'UserList', {
  status: 'Status',
  client: 'Client / Agent',
  user: 'User',
  type: 'Type',
  assignedAgent: 'Assigned Agent',
  mail: 'Email',
  market: 'Market'
});
