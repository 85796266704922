import i18next from 'i18next';

i18next.addResources('es', 'ProformaPDF', {
  proformaValidation: 'Validación proforma',
  please: 'Por favor',
  verifyDocumentDownload: 'Descarga',
  verifyDocumentComplement: 'la proforma y verifica que los datos detallados en la misma sean correctos.',
  ifAgree: 'Si estás de acuerdo haz clic en',
  otherwise: 'para que el pedido continúe su proceso. De lo contrario haz clic en',
  validateProforma: 'Validar',
  reject: 'Rechazar',
  validate: 'Validar',
  download: 'Descargar Proforma',
  close: 'Cerrar',
  proformaError: 'No se ha podido obtener la proforma. Intenta mas tarde',
  proforma: 'proforma',
  downloadProforma: 'Descargar'
});

i18next.addResources('en', 'ProformaPDF', {
  proformaValidation: 'Proforma validation',
  please: 'Please',
  verifyDocumentDownload: 'Download',
  verifyDocumentComplement: 'the proforma and verify that the details in it are correct.',
  ifAgree: 'If you agree, click',
  otherwise: 'for the order to continue its process. Otherwise click',
  validateProforma: 'Validate',
  reject: 'Reject',
  validate: 'Validate',
  download: 'Download Proforma',
  close: 'Close',
  proformaError: 'The proforma could not be obtained. Try later',
  proforma: 'proforma',
  downloadProforma: 'Download'
});
