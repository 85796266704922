/* eslint-disable */
import es from 'dayjs/locale/es';
import en from 'dayjs/locale/en';
import dayjs from 'dayjs';
import { IGetMMYYYY } from 'types/Global';
import { addHours, format, parse, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

const DEFAULT_ESP = 'ESP';

export const changeDayjsLanguage = (language: string) => dayjs.locale(language === 'es' ? es : en);

export const getMMYYYY = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  const month = dayjs(date).locale(lang).format('MMMM');
  const year = dayjs(date).locale(lang).format('YYYY');
  return `${month}, ${year}`;
};

export const getDDMMYYYY = ({ date, language = DEFAULT_ESP, spacer = '-' }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format(`DD${spacer}MM${spacer}YYYY`);
};

export const getYYYYMMDD = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format('YYYY-MM-DD');
};

export const getCompleteInfo = ({ date, language = DEFAULT_ESP }: IGetMMYYYY): string => {
  const lang = language === 'ESP' ? 'es' : 'en';
  return dayjs(date).locale(lang).format('YYYY-MM-DD HH:mm');
};

export const adjustDateTime = (dateString: string, targetHour: number) => {
  const date = new Date(dateString);
  date.setUTCHours(targetHour, 0, 0, 0);
  const adjustedDate = addHours(date, -3);
  const finalDate = setMilliseconds(setSeconds(setMinutes(setHours(adjustedDate, targetHour), 0), 0), 0);
  return format(finalDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
};
