import i18next from 'i18next';

i18next.addResources('es', 'Documents', {
  title: 'Documentos',
  documentsManager: 'Documentos',
  ordersNshipments: 'Pedidos y embarques',
  techSheets: 'Fichas técnicas',
  specdeclarationsNcertificates: 'Declaraciones y certificados especiales',
  placeholderStatements: 'Buscar Certificados Especiales',
  show: 'Mostrar',
  docs: 'documentos',
  search: 'Buscar',
  productSheet: 'Ficha de Producto',
  notFound: 'No se encontraron resultados para esta búsqueda',
  documentsBy: 'Documentos de {{title}}',
  problemsWithOC: 'Problemas con la orden de compra',
  packingList: 'Packing List',
  certificates: 'Certificados',
  proforma: 'Proforma',
  purchaseOrder: 'Orden de compra',
  footerDocList:
    'Si en nuestro listado no encuentras el documento que estas buscando, ponte en contacto con nosotros escribiendo a <strong>fiberplace@cmpc.com</strong>'
});

i18next.addResources('en', 'Documents', {
  title: 'Documents',
  documentsManager: 'Documents',
  ordersNshipments: 'Orders and shipments',
  techSheets: 'Technical Sheets',
  specdeclarationsNcertificates: 'Special statements and certificates',
  placeholderStatements: 'Find special certificates',
  show: 'Show',
  docs: 'Docs',
  search: 'Search',
  productSheet: 'Product Sheets',
  notFound: 'No results were found for this search',
  problemsWithOC: 'Purchase order problems',
  packingList: 'Packing List',
  certificates: 'Certificates',
  proforma: 'Proforma',
  purchaseOrder: 'Purchase order',
  footerDocList:
    'If in our list you do not find the document you are looking for, get in touch with us writing to <strong>fiberplace@cmpc.com</strong>'
});
