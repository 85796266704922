import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/Global';
import { RateFaces } from '../RateFaces/RateFaces';

import styles from './styles.module.scss';

interface IFacesComponents {
  setValueFace: (value: Nullable<number>) => void;
  handleSkipSession: () => void;
  question: string;
  name: string;
  isResponsive: boolean;
}

export const Faces = ({
  setValueFace,
  handleSkipSession,
  question,
  name,
  isResponsive
}: IFacesComponents) => {
  const { t } = useTranslation('csat');
  const userName = name;

  return (
    <>
      <div className={styles.content}>
        <span className={styles.contentTitle}>{t('hello', { userName })}</span>
        <span className={styles.contentQuestion} data-testid="question-csat-test">
          {question}
        </span>
        {!isResponsive && <RateFaces setValue={setValueFace} />}
      </div>
      <div className={styles.footer}>
        <span>{t('dontAsk')}</span>
        <span className={styles.closeButton} onClick={handleSkipSession}>
          {t('close')}
        </span>
      </div>
    </>
  );
};
