import i18next from 'i18next';

i18next.addResources('es', 'OrderActivity', {
  loadMore: 'Ver registros anteriores',
  seeArticle: 'Ver artículo',
  seeProblem: 'Ver problema',
  errorOccurred: 'Ocurrio un error al traer la información, por favor intente nuevamente.'
});

i18next.addResources('en', 'OrderActivity', {
  loadMore: 'Load previous records',
  seeArticle: 'See article',
  seeProblem: 'See problem',
  errorOccurred: 'There was an error bringing the information, please try again.'
});
