import i18next from 'i18next';

i18next.addResources('es', 'SystemConfiguration', {
  imperial: 'Imperial',
  language: 'Idioma',
  metric: 'Métrico',
  placeholderSelect: 'Seleccione...',
  systemSettings: 'Configuración del sistema',
  unitSystem: 'Sistema de unidades'
});

i18next.addResources('en', 'SystemConfiguration', {
  imperial: 'Imperial',
  language: 'Language',
  metric: 'Metric',
  placeholderSelect: 'Select...',
  systemSettings: 'System configuration',
  unitSystem: 'Unit system'
});
