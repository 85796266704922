import i18next from 'i18next';

i18next.addResources('es', 'Notification', {
  approve: 'Aprobar pedido',
  ago: 'Hace {{ textTime }}',
  markAsRead: 'Marcar como leída',
  markAsUnread: 'Marcar como no leída',
  seeIssue: 'Ver problema',
  seeFeedback: 'Ver feedback'
});

i18next.addResources('en', 'Notification', {
  approve: 'Order approve',
  ago: '{{ textTime }} ago',
  markAsRead: 'Mark as read',
  markAsUnread: 'Mark as unread',
  seeIssue: 'See issue',
  seeFeedback: 'See feedback'
});
