import { useSelector } from 'contexts/UserContext';
import { RoleMap, UserTypeId } from 'types/User';

const usePermission = () => {
  const { permissions, userTypeId } = useSelector(state => ({
    permissions: state.user?.permissions!,
    userTypeId: state.user?.userTypeId!
  }));

  const hasPermission = (permissionToCheck: string): boolean => permissions?.includes(permissionToCheck);

  const hasRole = (rolesToCheck?: UserTypeId[]): boolean =>
    userTypeId && (!rolesToCheck || rolesToCheck.includes(userTypeId));

  const filterByRole = <D>(array: RoleMap<D>): D | undefined =>
    array[userTypeId] ? array[userTypeId] : undefined;

  return { hasPermission, hasRole, filterByRole };
};

export default usePermission;
