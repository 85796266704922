/* eslint-disable no-magic-numbers */
import { AuthTokens } from 'types/Global';
import { OptionType } from 'types/Select';

export enum UserTypeId {
  SUPERUSER = 1,
  AGENT = 2,
  CLIENT = 3,
  CMPC = 4
}

export enum UserType {
  SUPERUSER = 'superuser',
  AGENT = 'agent',
  CLIENT = 'client',
  CMPC = 'cmpc'
}

export interface UserTypeParams {
  id?: number;
  userType?: UserType;
}

export interface RequestUserParams {
  userId?: number;
  userType?: UserType;
  loginType?: number;
}
export interface Profile extends UserTypeParams {
  expiresIn?: number;
  activeFlag?: boolean;
  address?: string;
  agentId?: string;
  auth0Id?: string;
  countryId?: string;
  createdAt?: string;
  customerId?: string;
  dateOfBirth?: string;
  email?: string;
  faxNum?: number;
  languageId?: string;
  lastName?: string;
  name?: string;
  needChangePassword?: boolean;
  phoneNum?: number;
  permissions?: string[];
  unitsMeassure?: string;
  updatedAt?: string;
  market?: string;
  username?: string;
  clientAgent?: string;
  assignedAgent?: string | null;
  userTypeId?: UserTypeId;
  clients?: ListClientsOrMarkets;
  markets?: ListClientsOrMarkets;
  loginType?: string;
  newToken?: string;
  typeAuthentication?: number;
}

export interface User extends AuthTokens, Profile {}

export interface CreateUserData
  extends Pick<
    User,
    | 'name'
    | 'lastName'
    | 'email'
    | 'username'
    | 'userTypeId'
    | 'activeFlag'
    | 'countryId'
    | 'languageId'
    | 'unitsMeassure'
    | 'customerId'
  > {
  id?: number;
  customersIds?: string[];
  marketsIds?: string[];
  warehousesIds?: string[];
  canCreateOrder: boolean;
  canIgnoreEmails: boolean;
  canViewAdmin: boolean;
  canViewStockController: boolean;
  canViewTracking: boolean;
  canViewCreditStatus: boolean;
  canEditProductiveCycles: boolean;
  loginType?: number;
  typeAuthentication?: number;
}

export type CustomerInfo = {
  id: string;
  name: string;
};

export type ClientAgentsResponse = User[];

export type UserListData = Pick<Profile, 'id' | 'email' | 'activeFlag' | 'username'> & {
  userTypeDesc?: UserType;
  clientAgent?: string;
  market?: string;
  assignedAgent?: string;
};

export type UserData = Omit<Profile, 'id' | 'userType' | 'userTypeId' | 'customerId'> & {
  id: number;
  userType: UserType;
  userTypeId: UserTypeId;
  clients?: OptionType[];
  markets?: OptionType[];
  warehouses?: OptionType[];
  loginType?: OptionType[];
  customerId?: string;
};
export interface CustomerData {
  customerId: string;
  custName: string;
}

export type RoleMap<D> = Record<UserTypeId, D>;

export interface ListClientsOrMarkets {
  label: string;
  value: string;
}
