import i18next from 'i18next';

i18next.addResources('es', 'OptionPanel', {
  settings: 'Configuraciones',
  notifications: 'Notificaciones',
  noNotifications: 'No tienes notificaciones sin leer',
  noNotificationsText: 'Ir al centro de actividad',
  viewMoreNotifications: 'Ver más notificaciones',
  close: 'Cerrar',
  error: 'Hubo un error al cargar las notificaciones. Por favor vuelve a intentarlo en unos minutos.',
  unread: '(no leídas)',
  seeAll: 'Ver todas'
});

i18next.addResources('en', 'OptionPanel', {
  settings: 'Settings',
  notifications: 'Notifications',
  noNotifications: 'You have no unread notifications',
  noNotificationsText: 'Go to the activity center',
  viewMoreNotifications: 'View more',
  close: 'Close',
  error: 'Something went wrong at notifications. Please try again later.',
  unread: '(unread)',
  seeAll: 'See all'
});
