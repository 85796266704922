import { HOME_SIDEBAR_PATHS, ItemProps } from 'components/Layout/constants';
import { Nullable } from 'types/Global';
import { UserTypeId } from 'types/User';

export const getItemSelected = (defaultItemSelected: Nullable<number>, location: string) => {
  if (defaultItemSelected === null) {
    if (HOME_SIDEBAR_PATHS.indexOf(location) !== -1) {
      return HOME_SIDEBAR_PATHS.indexOf(location);
    }
    return HOME_SIDEBAR_PATHS.indexOf(`/${location.split('/')[1]}`);
  }
  return defaultItemSelected;
};

export const filterItemsAuth = (items: ItemProps[], hasRole: (rolesToCheck?: UserTypeId[]) => boolean) =>
  items.filter(
    item =>
      !item.roles ||
      (item.roles.includes(UserTypeId.SUPERUSER) && hasRole([UserTypeId.SUPERUSER])) ||
      (item.roles.includes(UserTypeId.CMPC) && hasRole([UserTypeId.CMPC])) ||
      (item.roles.includes(UserTypeId.AGENT) && hasRole([UserTypeId.AGENT])) ||
      (item.roles.includes(UserTypeId.CLIENT) && hasRole([UserTypeId.CLIENT]))
  );

// export const filterItemsByPermission = (permissions = []) => ()
