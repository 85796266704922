import i18next from 'i18next';

i18next.addResources('es', 'FormatField', {
  roll: 'Rollo',
  stack: 'Pila',
  diameter: 'Diámetro (cm)',
  core: 'Caño (pulg)',
  packaging: 'Embalaje',
  length: 'Largo (cm)',
  nOfSheets: 'N˚ de hojas',
  width: 'Ancho (cm)',
  height: 'Altura estimada (cm)',
  labelMarks: 'Marca especial'
});

i18next.addResources('en', 'FormatField', {
  roll: 'Roll',
  stack: 'Stack',
  diameter: 'Diameter (cm)',
  core: 'Core (inches)',
  packaging: 'Wrapper',
  length: 'Length (cm)',
  nOfSheets: 'Nº of sheets',
  width: 'Width (cm)',
  height: 'Estimated height (cm)',
  labelMarks: 'Label marks'
});
