import i18next from 'i18next';

i18next.addResources('es', 'StatusField', {
  active: 'Activo',
  disabled: 'No vigente'
});

i18next.addResources('en', 'StatusField', {
  active: 'Active',
  disabled: 'Disabled'
});
