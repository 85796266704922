import i18next from 'i18next';

i18next.addResources('es', 'TableDetailsItem', {
  idArticle: 'ID artículo',
  diameter: 'Diámetro',
  width: 'Ancho (cm)',
  core: 'Caño (pulgadas)',
  packaging: 'Embalaje',
  long: 'Largo (cm)',
  sheets: 'Nº de hojas',
  pallet: 'Pallet',
  insertSheets: 'Con insertadores',
  inserters: 'Insertadores/Hojas',
  height: 'Altura (cm)',
  withReams: 'Con enresmado',
  reams: 'Enresmado/Hojas',
  titleStack: '¿Desea volver a pedir alguno de estos artículos?'
});

i18next.addResources('en', 'TableDetailsItem', {
  idArticle: 'ID Item',
  diameter: 'Diameter',
  width: 'Width (cm)',
  core: 'Core (inches)',
  packaging: 'Wrapper',
  long: 'Long (cm)',
  sheets: 'Nº of sheets',
  pallet: 'Pallet',
  insertSheets: 'With inserters?',
  inserters: 'Inserters/sheets',
  withReams: 'With reams?',
  reams: 'Reams/sheets',
  height: 'Height (cm)',
  titleStack: '¿Do you want to reorder any of these items?'
});
