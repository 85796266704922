import i18next from 'i18next';

i18next.addResources('es', 'SelectPaper', {
  choosePaper: 'Seleccione papel',
  paper: 'Papel',
  family: 'Familia',
  priceList: 'Lista de precios',
  paperCode: 'Código de papel',
  selectFamily: 'Seleccionar familia'
});

i18next.addResources('en', 'SelectPaper', {
  choosePaper: 'Choose paper',
  paper: 'Paper',
  family: 'Family',
  priceList: 'Price List',
  paperCode: 'Paper Code',
  selectFamily: 'Select Family'
});
