import React from 'react';
import { render } from 'react-dom';
import './scss/application.scss';
import './config/i18n';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Auth0Provider } from '@auth0/auth0-react';

import App from 'components/App';

import { auth0Config, msalConfig } from './config/authConfig';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);
render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={window.location.origin}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
    >
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
