export enum UserPermissions {
  CAN_CREATE_ORDER = 'can_create_order',
  CAN_IGNORE_EMAILS = 'can_ignore_emails',
  CAN_VIEW_CREDIT_STATUS = 'can_view_credit_status',
  CAN_VIEW_TRACKING = 'can_view_tracking',
  CAN_VIEW_STOCK_CONTROLLER = 'can_view_stock_controller',
  CAN_EDIT_PRODUCTIVE_CYCLES = 'can_edit_productive_cycles',
  CAN_VIEW_DOCUMENTS = 'can_view_documents',
  CAN_VIEW_ADMIN = 'can_view_admin'
}
