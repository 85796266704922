import api from 'config/api';
import { Nullable } from 'types/Global';
import { UserType, UserTypeId } from 'types/User';
import { getCurrentLanguage, LANGUAGES } from 'utils/i18n';

export const notificationsBaseURL = `${api.getBaseURL() || ''}`;
// export const notificationsBaseURL = 'http://localhost:3002';

export interface NotificationsParams {
  userIdOwner?: UserTypeId;
  userType?: UserType;
  read?: Nullable<boolean>;
  page?: number;
  size?: number;
  languageId?: string;
  activityCenter?: boolean;
}

export const getLanguage = (languageId: string | undefined) => {
  if (languageId) {
    return languageId === LANGUAGES[0] ? 'ESP' : 'ENG';
  }
  return getCurrentLanguage() === LANGUAGES[0] ? 'ESP' : 'ENG';
};
// TODO: Remove this when we added a LoadMore button in ActivityCenter screen
export const UNREAD_NOTIFICATIONS_SIZE = 500;

/**
 * It will return all the notifications
 *
 * @remarks
 * By default activity center it's set on true.
 *
 * @param userIdOwner - Number which represent the user's ID rol
 * @param userType - String which represent the user's ID rol
 * @param read - Boolean for read(true) or unread(false) notification
 * @param page - Number
 * @param size - Number
 * @param languageId - String could be 'es' or 'en' which affect the language of the notifications
 * @param activityCenter - Boolean if the service is called by the activityCenter it should be true
 * @returns notifications
 */
export const getNotifications = ({
  userIdOwner,
  userType,
  read,
  page,
  size,
  languageId,
  activityCenter = true
}: NotificationsParams) =>
  api.get(`${notificationsBaseURL}/notifications`, {
    userIdOwner,
    userType,
    read,
    page,
    size,
    language: getLanguage(languageId),
    activityCenter
  });

export const readNotification = (id: number, read: boolean) =>
  api.put(`${notificationsBaseURL}/notifications/${id}`, { read });
