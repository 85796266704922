import React, { useState } from 'react';
import styles from './styles.module.scss';
import { FacesList } from '../constants';

interface IRateFaces {
  setValue: (value: number | null) => void;
}

export const RateFaces = ({ setValue }: IRateFaces) => {
  const [faces, setFaces] = useState(FacesList);

  const handleChangeValue = (faceIndex: number | null) => {
    setValue(faceIndex);
    const newFaces = faces.map((face, index) => {
      if (index === faceIndex) {
        face.isSelected = true;
      } else {
        face.isSelected = false;
      }
      return face;
    });
    setFaces(newFaces);
  };

  return (
    <div className={styles.container}>
      {faces.map(({ face, selected, isSelected }, index: number) => {
        if (isSelected) {
          return <img src={selected} onClick={() => handleChangeValue(null)} key={face} />;
          // eslint-disable-next-line no-else-return
        } else {
          return (
            <img
              key={face}
              src={face}
              onMouseOver={e => (e.currentTarget.src = selected)}
              onMouseLeave={e => (e.currentTarget.src = face)}
              onClick={() => handleChangeValue(index)}
              data-testid={`${face}-test`}
            />
          );
        }
      })}
    </div>
  );
};
