/* eslint-disable @typescript-eslint/naming-convention */
import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  welcome: 'Bienvenido/a',
  welcomeText: 'Completa los datos de usuario para ingresar al sistema',
  username: 'Usuario',
  usernamePlaceholder: 'Ingresa tu nombre de usuario',
  email: 'Email',
  emailPlaceholder: 'Ingresa email de usuario',
  password: 'Contraseña',
  passwordPlaceholder: 'Ingresa tu contraseña',
  login: 'Iniciar sesión',
  inputRequired: 'Este campo es requerido',
  inputEmailNotValid: 'El formato de mail no es correcto',
  missingFields: 'El usuario y la contraseña son requeridos para poder ingresar.',
  fieldError: 'El valor ingresado es incorrecto',
  landingText:
    'Bienvenidos, en nuestra plataforma Fiber Place Pulp, encontrarás todo lo necesario para informarte sobre el tracking de tus productos, tus solicitudes y todas las novedades de CMPC Pulp',
  userCmpc: 'Si eres usuario interno de CMPC',
  userInternal: 'Acceso usuarios internos',
  client: 'Plataforma clientes'
});

i18next.addResources('en', 'Login', {
  fieldError: 'Wrong field value',
  inputEmailNotValid: 'The email is not valid',
  inputRequired: 'This field is required',
  login: 'Log in',
  missingFields: 'There are some errors in your data',
  password: 'Password',
  passwordPlaceholder: 'Enter your password',
  username: 'Username',
  usernamePlaceholder: 'user@boxboard.cmpc.cl',
  email: 'Email',
  emailPlaceholder: 'Enter your email',
  welcome: 'Welcome',
  welcomeText: 'Please, enter the user information to get into the platform',
  userCmpc: 'If you are an internal user of CMPC',
  userInternal: 'Internal user access',
  client: 'Client Plataform'
});

i18next.addResources('es', 'LoginResponse', {
  error: 'En estos momentos el servidor no responde. Por favor vuelve a intentarlo en unos minutos.',
  errorAttempts: 'El usuario y/o la contraseña ingresada no son correctos, por favor inténtalo nuevamente.',
  invalid_user: 'El usuario y/o la contraseña ingresada no son correctos, por favor inténtalo nuevamente.',
  invalid_email:
    'El correo y/o la contraseña ingresada no son correctos, por favor inténtalo nuevamente o restablece tu contraseña.',
  leftAttemps: 'Te queda {{ count }} intento antes que tu cuenta sea bloqueada.',
  leftAttemps_plural: 'Te quedan {{ count }} intentos antes que tu cuenta sea bloqueada.',
  resource_not_found:
    'El correo y/o la contraseña ingresada no son correctos, por favor inténtalo nuevamente o restablece tu contraseña.',
  /* user_disable_error:
    'La cuenta ha sido bloqueada por máximo número de intentos. Revise su correo electrónico para desbloquearla.', */
  user_disable_error: 'Usuario deshabilitado. Por favor contactar a soporte soporteboxboard@cmpc.com',
  profile_error: 'Hubo un error desconocido al intentar cargar la información del perfil del usuario.',
  notifications_error: 'Hubo un error desconocido al intentar cargar las notificaciones del usuario.',
  database_error: 'Hubo un error desconocido al intentar conectarse con la base de datos.'
});

i18next.addResources('en', 'LoginResponse', {
  error: 'Something went wrong. Please try again later.',
  errorAttempts: 'The username and / or password entered are not correct, please try again.',
  invalid_user: 'The email and/or password entered are not correct, please try again or reset your password.',
  leftAttemps: 'You have {{count}} attempt left before your account is blocked.',
  leftAttemps_plural: 'You have {{count}} attempts left before your account is blocked.',
  resource_not_found:
    'The email and/or password entered are not correct, please try again or reset your password.',
  /* user_disable_error: 'Account blocked. Check your email for more information', */
  user_disable_error: 'User disabled. Please contact support soporteboxboard@cmpc.com',
  profile_error: 'There was an unknown error trying to load the user profile information.',
  notifications_error: 'There was an unknown error trying to load user notifications.',
  database_error: 'There was an unknown error trying to connect to the database.'
});
