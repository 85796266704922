import i18next from 'i18next';

i18next.addResources('es', 'Notifications', {
  notifications: 'Notificaciones',
  unread: 'No leídas',
  old: 'Leídas',
  loadMore: 'Ver más notificaciones',
  noUnreadNotifications: 'No tienes notificaciones sin leer',
  noReadNotifications: 'No tienes notificaciones leídas',
  readError: 'Hubo un error al marcar la notificación. Por favor, espere unos minutos y vuelva a intentarlo.',
  notificationsError:
    'Hubo un error al cargar las notificaciones. Por favor vuelve a intentarlo en unos minutos.'
});

i18next.addResources('en', 'Notifications', {
  notifications: 'Notifications',
  unread: 'New',
  old: 'Old',
  loadMore: 'Load more notifications',
  noUnreadNotifications: "You don't have unread notifications",
  noReadNotifications: "You don't have read notifications",
  readError: 'There was an error marking the notification. Please wait a few minutes and try again.',
  notificationsError: 'Something went wrong at notifications. Please try again later.'
});
