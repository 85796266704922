import i18next from 'i18next';

i18next.addResources('es', 'FindItem', {
  title: 'Buscar artículos',
  subTitle: 'Ingrese los datos a continuación para buscar artículos',
  buttonSearch: 'Buscar',
  textHelp: '¿No encuentras el artículo que quieres?',
  buttonCreateArticle: '+ Crear artículo',
  subTitleTable: 'Hemos encontrado los siguientes artículos que coinciden con tu búsqueda:'
});

i18next.addResources('en', 'FindItem', {
  title: 'Search articles',
  subTitle: 'Complete the following information to start searching articles',
  buttonSearch: 'Search',
  textHelp: 'Can not find the item you want?',
  buttonCreateArticle: '+ Create article',
  subTitleTable: 'We have found the following articles that match your search:'
});
