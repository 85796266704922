import i18next from 'i18next';

i18next.addResources('es', 'SendFeedback', {
  beforeLeaving: 'Antes que te vayas...',
  askingOpinion: '¿Cómo fue tu experiencia con este pedido?',
  description: 'Estimado cliente, le pedimos que califique la experiencia con su',
  order: 'pedido #{{ orderId }}',
  send: 'Enviar',
  additionalComment: 'Comentario adicional',
  ratingError: 'Debe seleccionar una calificación para continuar',
  apiError: 'Ha ocurrido un error enviando el feedback. Intenta más tarde',
  commentHint: 'Escriba un comentario adicional para ayudarnos a mejorar su próxima experiencia',
  closedOrder: 'Su pedido ha sido cerrado con éxito. ¡Gracias por su feedback!'
});

i18next.addResources('en', 'SendFeedback', {
  beforeLeaving: 'Before you go...',
  askingOpinion: 'How was your experience with this order?',
  description: 'Dear customer, we ask you to rate the experience with your',
  order: 'order #{{ orderId }}',
  additionalComment: 'Additional comment',
  send: 'Submit',
  ratingError: 'You must select a rating to continue',
  apiError: 'An error has occurred sending the feedback. Try later',
  commentHint: 'Write an additional comment to help us improve your next experience',
  closedOrder: 'Your order has been successfully closed. Thanks for your feedback!'
});
