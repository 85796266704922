import i18next from 'i18next';

i18next.addResources('es', 'Status', {
  // ITEMS
  proformaValidation: 'Validación proforma',
  proformaValidated: 'Proforma validada',
  rejected: 'Rechazado',
  schedule: 'Programado',
  inPreparation: 'En preparación',
  partialProduction: 'Producción parcial',
  totalProduction: 'Producción total',
  inInternalTransit: 'En tránsito interno',
  inConsolidation: 'En consolidación',
  inTransitToCustomer: 'En tránsito {{toCustomer}}',
  toCustomer: 'a cliente',
  invoicedPending: 'Facturado pendiente',
  invoicedTotal: 'Facturado total',
  invoice: 'Facturado',
  invoiced: 'Facturado',
  received: 'Recibido',
  rejectedByCustomer: 'Rechazado por cliente',
  // ORDERS
  orderEntered: 'Pedido ingresado',
  rejectedOptivision: 'Revisar en Optivision',
  rejectedByCredit: 'Rechazado por crédito',
  showProforma: 'Ver Proforma',
  proformaRejected: 'Proforma rechazada',
  schedulingOrder: 'Programando pedido',
  orderSchedule: 'Pedido programado',
  inProcess: 'En proceso',
  inProduction: 'En producción',
  inTransit: 'En tránsito',
  orderClosedPending: 'Pedido por cerrar',
  orderClosed: 'Pedido cerrado',
  closeOrder: 'Cerrar pedido',
  showFeedback: 'Ver feedback',
  orderInvoice: 'Facturado total',
  noInformationAvailable: 'No hay información disponible',
  draft: 'Borrador',
  discarded: 'Descartada',
  validated: 'Validada',
  validationCMPC: 'Validación CMPC',
  purchaseOrder: 'Orden de compra'
});

i18next.addResources('en', 'Status', {
  // ITEMS
  proformaValidation: 'Proforma validation',
  proformaValidated: 'Proforma validated',
  rejected: 'Rejected',
  schedule: 'Schedule',
  inPreparation: 'In preparation',
  partialProduction: 'Partial production',
  totalProduction: 'Total production',
  inInternalTransit: 'In internal transit',
  inConsolidation: 'In consolidation',
  inTransitToCustomer: 'In transit {{toCustomer}}',
  toCustomer: 'to customer',
  invoicedPending: 'Invoiced pending',
  invoicedTotal: 'Total invoiced',
  invoice: 'Invoice',
  invoiced: 'Invoiced',
  received: 'Received',
  rejectedByCustomer: 'Rejected by customer',
  // ORDERS
  orderEntered: 'Order entered',
  rejectedOptivision: 'Review in Optivision',
  rejectedByCredit: 'Rejected by credit',
  showProforma: 'Show Proforma',
  proformaRejected: 'Proforma rejected',
  schedulingOrder: 'Scheduling order',
  orderSchedule: 'Order schedule',
  inProcess: 'In process',
  inProduction: 'In production',
  inTransit: 'In transit',
  orderClosedPending: 'Pending to close',
  orderClosed: 'Order closed',
  closeOrder: 'Close order',
  showFeedback: 'Show feedback',
  orderInvoice: 'Order invoiced',
  noInformationAvailable: 'No information available',
  draft: 'Draft',
  discarded: 'Discarded',
  validated: 'Validated',
  validationCMPC: 'Validation CMPC',
  purchaseOrder: 'Purchase order'
});
