import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { TIMEOUT_SHOW_MESSAGES } from './constants';

interface Props {
  className?: string;
  children: React.ReactNode;
  type?: 'error' | 'info' | 'warning' | 'success';
  icon?: boolean;
  autohide?: boolean;
  customIcon?: string;
  canHide?: boolean;
  close?: (id?: string) => void;
}

const iconClassname = {
  error: 'icon-ic-error',
  info: 'icon-ic-checkmark',
  warning: 'icon-ic-question',
  success: 'icon-ic-checkmark'
};

function Alert({
  className = '',
  children,
  type = 'info',
  icon = true,
  autohide,
  customIcon,
  canHide = false,
  close
}: Props) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (autohide) {
        setHidden(true);
      }
    }, TIMEOUT_SHOW_MESSAGES);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHidden(false);
  }, [children]);

  const onClick = () => {
    if (canHide) {
      setHidden(true);
    }
    close?.();
  };

  return (
    <div
      className={cn(
        'text',
        styles.alert,
        { [styles.hidden]: hidden },
        { [styles.error]: type === 'error' },
        { [styles.info]: type === 'info' },
        { [styles.warning]: type === 'warning' },
        { [styles.withIcon]: icon },
        className
      )}
      onClick={onClick}
    >
      {icon && !customIcon && <i className={`${styles.icon} ${iconClassname[type]}`} />}
      {customIcon && <i className={`${styles.icon} ${customIcon}`} />}
      {children}
    </div>
  );
}

export default Alert;
