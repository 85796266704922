import api from 'config/api';
import { ApiError } from 'types/Global';
import { Profile } from 'types/User';

export const usersBaseURL = `${api.getBaseURL() || ''}`;

export const getProfile = () => api.get<Profile, ApiError>(`${usersBaseURL}/newusers/me`);

export const enableUser = (id: number, enable: boolean) =>
  api.put(`${usersBaseURL}/enable-users/${id}`, {
    activeFlag: enable
  });

export default api;
