import i18next from 'i18next';

i18next.addResources('es', 'SetItem', {
  loadItemToOrder: 'Cargar item al pedido',
  articleDetail: 'Detalle del artículo',
  itemAdded: 'Item agregado exitosamente',
  itemEdited: 'Item editado exitosamente',
  notValidArticle: 'El artículo que has seleccionado es inválido',
  itemDuplicated:
    'Esté artículo ya ha sido ingresado en esta Orden de Compra, debes agregar uno diferente o editar el ya existente',
  error:
    'Lamentamos informarle que este artículo no puede ser seleccionado debido a que infringe nuestra política de restricciones de categorías.'
});

i18next.addResources('en', 'SetItem', {
  loadItemToOrder: 'Load item to order',
  articleDetail: 'Article detail',
  itemAdded: 'Item added successfully',
  itemEdited: 'Item edited successfully',
  notValidArticle: 'The item you have selected is invalid',
  itemDuplicated:
    'This item has already been entered on this Purchase Order, you must add a different one or edit the existing one',
  error:
    'We regret to inform you that this article cannot be selected as it violates our policy on category restrictions.'
});
