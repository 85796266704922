import i18next from 'i18next';

i18next.addResources('es', 'ProductField', {
  pendingCreation: 'Artículo nuevo',
  rejected: 'Artículo rechazado',
  pendingCreationTooltip: 'Este item contiene un artículo nuevo que necesita ser validado.',
  pendingCreationCanTooltip: 'Puedes validarlo en el botón Acciones / Ver artículo.',
  rejectedTooltip: 'Este item contiene un artículo rechazado. ',
  rejectedCanTooltip:
    'Debes eliminar este item en el botón Acciones / Eliminar para poder validar la orden de compra.'
});

i18next.addResources('en', 'ProductField', {
  pendingCreation: 'New article',
  rejected: 'Rejected article',
  pendingCreationTooltip: 'This item contains a new article that needs to be validated. ',
  pendingCreationCanTooltip: 'You can validate it in the Actions / View article button.',
  rejectedTooltip: 'This item contains a rejected article. ',
  rejectedCanTooltip:
    'You must delete this item in the Actions / Delete button to be able to validate the purchase order.'
});
