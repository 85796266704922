import i18next from 'i18next';

i18next.addResources('es', 'TotalUnits', {
  title: 'Unidades Totales Stock',
  available: 'Disponible',
  gestion: 'Gestionado',
  bloqued: 'Bloqueado',
  planned: 'Programado',
  inReview: 'Pendientes'
});

i18next.addResources('en', 'TotalUnits', {
  title: 'Units Total Stock',
  available: 'Available',
  gestion: 'Managed',
  bloqued: 'Blocked',
  planned: 'Schedule',
  inReview: 'Pendient'
});
