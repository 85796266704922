/* eslint-disable */
import i18next from 'i18next';

i18next.addResources('es', 'CreditStatusCards', {
  client: 'Cliente',
  amountInvoices: 'Importe facturas',
  exhaustion: '% agotamiento',
  expiredDebt: 'Deuda vencida',
  country: 'País',
  invoice: 'Factura',
  invoiceSAP: 'Factura SAP',
  documentDate: 'Fecha de documento',
  expiration: 'Vencimiento',
  expiredDays: 'Días vencidos',
  daysToExpire: 'Días por vencer',
  tons: 'Toneladas',
  invoiceAmount: 'Importe facturas',
  payments: 'Pagos',
  balance: 'Saldo',
  noDocuments: 'No tiene documentos para mostrar',
  seeDetail: 'Ver detalle',
  paginationItem: 'facturas',
  asignation: 'Asignación'
});

i18next.addResources('en', 'CreditStatusCards', {
  client: 'Customer',
  amountInvoices: 'Amount invoices',
  exhaustion: 'Exhaustion % ',
  expiredDebt: 'Overdue debt',
  country: 'Country',
  invoice: 'Invoice',
  invoiceSAP: 'SAP Invoice',
  documentDate: 'Document date',
  expiration: 'Expiration',
  expiredDays: 'Days expired',
  daysToExpire: 'Days until expired',
  tons: 'Tons',
  invoiceAmount: 'Invoice amount',
  payments: 'Payment',
  balance: 'Balance',
  noDocuments: 'No tiene documentos para mostrar',
  seeDetail: 'See detail',
  paginationItem: 'invoices',
  asignation: 'Asignation'
});
