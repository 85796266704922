import i18next from 'i18next';

i18next.addResources('es', 'Filters', {
  status: 'Estado',
  indicateState: 'Indique estado',
  client: 'Cliente',
  searchClient: 'Buscar cliente',
  orderId: 'Nº Pedido',
  refClient: 'Ref Cliente',
  dispatchWeek: 'Semana Despacho',
  searchFilter: 'Filtros de búsqueda'
});

i18next.addResources('en', 'Filters', {
  status: 'Status',
  indicateState: 'Indicate state',
  client: 'Client',
  searchClient: 'Search client',
  orderId: 'Order ID',
  refClient: 'PO',
  dispatchWeek: 'Dispatch Week',
  searchFilter: 'Search filters'
});
