import React, { Suspense } from 'react';

import Loading from 'components/Loading';

interface Props {
  fallback?: JSX.Element;
  children: React.ReactNode;
}
function CustomSuspense({ fallback = <Loading type="fiber-place" />, children }: Props) {
  return <Suspense fallback={fallback}>{children}</Suspense>;
}

export default CustomSuspense;
