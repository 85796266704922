import i18next from 'i18next';

i18next.addResources('es', 'ModalReportProblem', {
  problemWithYourOrder: '¿Problemas con tu pedido #{{id}}?',
  pleaseWriteProblem: 'Por favor describe el problema para poder ayudarte',
  writeComment: 'Escribe un comentario',
  requiredField: 'Este campo es obligatorio',
  problemReportedOn: 'Problema reportado en el pedido #{{id}}',
  descriptionProblem: 'Descripción del problema'
});

i18next.addResources('en', 'ModalReportProblem', {
  problemWithYourOrder: 'Problems with your order #{{id}}?',
  pleaseWriteProblem: 'Please, describe your problem so we can help you',
  writeComment: 'Write a comment',
  requiredField: 'This field is required',
  problemReportedOn: 'Problem reported in the order #{{id}}',
  descriptionProblem: 'Description of the problem'
});
