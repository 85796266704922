import i18next from 'i18next';

i18next.addResources('es', 'OptionPanelAction', {
  validateItem: 'Validar item',
  duplicate: 'Duplicar',
  delete: 'Eliminar',
  itemDuplicated: '¡Item {{id}} duplicado con exito!',
  questionItemDelete: '¿Estás seguro que deseas eliminar el item de la orden de compra?',
  noRecoverItem: 'Al eliminar el item no podrás recuperarlo',
  yesDeleteItem: 'Si, eliminar item',
  itemDeleted: '¡El item {{id}} ha sido eliminado de la orden de compra!',
  itemValidated: '¡El item {{id}} ha sido validado en la orden de compra!',
  viewArticle: 'Ver artículo',
  incompatibleDiameterAndPackaging: 'No se puede validar este articulo, el embalaje no es el correcto'
});

i18next.addResources('en', 'OptionPanelAction', {
  validateItem: 'Validate item',
  duplicate: 'Duplicate',
  delete: 'Delete',
  itemDuplicated: 'Item {{id}} duplicated successfully!',
  questionItemDelete: 'Are you sure you want to remove the item from the purchase order?',
  noRecoverItem: 'When deleting the item you will not able to recover it',
  yesDeleteItem: 'Yes, remove item',
  itemDeleted: 'The item {{id}} has been removed from the purchase order!',
  itemValidated: 'The item {{id}} has been validated in the purchase order!',
  viewArticle: 'View article',
  incompatibleDiameterAndPackaging: 'This item cannot be validated, the packaging is not correct.'
});
