import i18next from 'i18next';

i18next.addResources('es', 'ReportStatus', {
  whatWentWrong: '¿Qué salió mal?',
  anotherReason: 'Otro motivo:',
  arrivedLate: 'No llegó a tiempo',
  inBadCondition: 'Llegó en mal estado'
});

i18next.addResources('en', 'ReportStatus', {
  whatWentWrong: 'What went wrong?',
  anotherReason: 'Another reason:',
  arrivedLate: 'Did not arrive in time',
  inBadCondition: 'Arrived in bad condition'
});
