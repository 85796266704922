import i18next from 'i18next';

i18next.addResources('es', 'ReceptionField', {
  markAsReceived: 'Marcar como recibido',
  seeDetails: 'Ver detalles'
});

i18next.addResources('en', 'ReceptionField', {
  markAsReceived: 'Mark as received',
  seeDetails: 'See details'
});
