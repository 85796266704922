import i18next from 'i18next';

i18next.addResources('es', 'OptionPanel', {
  settings: 'Configuraciones',
  logout: 'Cerrar sesión'
});

i18next.addResources('en', 'OptionPanel', {
  settings: 'Settings',
  logout: 'Logout'
});
