import i18next from 'i18next';

i18next.addResources('es', 'authorizedDeliveriesBlocked', {
  authorizedDeliveries: 'Entregas Autorizadas',
  blockedDeliveries: 'Entregas Bloqueadas',
  overdraft: '% Sobregiro',
  potentialOverdraft: '% Sobregiro potencial',
  errorGetEditData: 'Error al obtener la información.'
});

i18next.addResources('en', 'authorizedDeliveriesBlocked', {
  authorizedDeliveries: 'Authorized delivers',
  blockedDeliveries: 'Blocked delivers',
  overdraft: 'Overdraft %',
  potentialOverdraft: 'Potencial overdraft %',
  productionCycles: 'Production Cycles'
});
