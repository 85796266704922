import i18next from 'i18next';

i18next.addResources('es', 'HelpCenterScreenOrderList', {
  orderResume: 'Resumen de pedido',
  p1:
    'Este resumen proporciona una vista rápida y detallada de la información esencial relacionada con cada pedido, facilitando la gestión eficiente de las órdenes y su seguimiento.',
  p2: 'La información en el resumen del pedido está organizada en dos secciones distintas:',
  upperSection: 'Sección Superior',
  p3: 'En la parte superior, se presenta un resumen general que incluye:',
  ol1: 'Estado del pedido: Indica el estado actual del pedido.',
  ol2: 'Cliente: Muestra el nombre del cliente asociado al pedido.',
  ol3: 'N° de Pedido: Proporciona el número único asignado al pedido.',
  ol4: 'Ref Cliente: Muestra la referencia del cliente asociada al pedido.',
  ol5: 'Semana de Despacho: Indica la semana programada para el despacho del pedido.',
  ol6: 'Semana Estimada de Entrega: Muestra la semana estimada de entrega del pedido.',
  lowerSection: 'Sección Inferior',
  p4: 'En la parte inferior del resumen, se encuentran detalles adicionales del pedido:',
  ol7: 'Número de Ítems: Indica la cantidad total de ítems incluidos en el pedido.',
  ol8: 'Unidades: Muestra la cantidad total de unidades solicitadas en el pedido.',
  ol9: 'Toneladas: Indica el peso total del pedido en toneladas.',
  ol10: 'Fecha de Ingreso: Muestra la fecha en que fue ingresado.',
  ol11: 'Ciclo: Indica el ciclo de producción asociado al pedido.',
  ol12: 'Tipo de Negocio: Especifica el tipo de negocio relacionado con el pedido.',
  ol13: 'OC Agente: Muestra la Orden de Compra (OC) asociada al agente del pedido.',
  ol14: 'Lista de Precios: Indica la lista de precios aplicada al pedido.',
  ol15: 'Certificación: Muestra información relacionada con la certificación del pedido.',
  filtering: 'Filtrado',
  p6:
    'En la sección superior del Menú de Pedidos, se encuentran las opciones de filtrado que permiten personalizar la visualización de la información.',
  p7: 'Estos filtros incluyen:',
  ol16: 'Estado: Permite filtrar los pedidos por su estado actual.',
  ol17: 'Cliente: Facilita la búsqueda específica de pedidos relacionados con un cliente en particular.',
  ol18:
    'N° Pedido: Permite ingresar el número de pedido para acceder directamente a la información correspondiente.',
  ol19: 'Ref Cliente: Filtra los pedidos según la referencia del cliente asociado.',
  ol20: 'Semana Despacho: Facilita la búsqueda de pedidos programados para una semana específica.',
  fileDownload: 'Descarga de archivo',
  p8:
    'En la sección superior derecha del Menú de Pedidos, encontrarás la opción de "Descargar Excel". Haz clic en esta opción para iniciar el proceso de descarga.',
  p9:
    'El archivo descargado contendrá información detallada de los pedidos, similar al resumen visualizado en el Menú de Pedidos. Esto puede incluir detalles como el estado del pedido, información del cliente, número de pedido, referencias, semanas de despacho, cantidades de ítems, fechas relevantes, y otros detalles específicos de cada pedido.',
  newOC: 'Proceso de Ingreso de Nueva Orden de Compra',
  orderDetail: 'Detalle del pedido'
});

i18next.addResources('en', 'HelpCenterScreenOrderList', {
  orderResume: 'Order Summary',
  p1:
    'This summary provides a quick and detailed view of essential information related to each order, facilitating efficient order management and tracking.',
  p2: 'The information in the order summary is organized into two distinct sections:',
  upperSection: 'Upper Section',
  p3: 'At the top, a general summary is presented, including:',
  ol1: 'Order Status: Indicates the current status of the order.',
  ol2: 'Customer: Displays the name of the customer associated with the order. ',
  ol3: 'Order Number: Provides the unique number assigned to the order.',
  ol4: 'Customer Ref: Shows the customer reference associated with the order.',
  ol5: 'Dispatch Week: Indicates the scheduled week for order dispatch.',
  ol6: 'Estimated Delivery Week: Displays the estimated delivery week of the order.',
  lowerSection: 'Lower Section',
  p4: 'At the bottom of the summary, additional order details are found:',
  ol7: 'Number of Items: Indicates the total number of items included in the order.',
  ol8: 'Units: Displays the total quantity of units requested in the order. ',
  ol9: 'Tons: Indicates the total weight of the order in tons.',
  ol10: 'Entry Date: Shows the date it was entered.',
  ol11: 'Cycle: Indicates the production cycle associated with the order.',
  ol12: 'Business Type: Specifies the business type related to the order.',
  ol13: "PO Agent: Displays the Purchase Order (PO) associated with the order's agent.",
  ol14: 'Price List: Indicates the price list applied to the order.',
  ol15: 'Certification: Displays information related to the certification of the order.',
  filtering: 'Filtering',
  p6:
    'At the top of the Orders Menu section, there are filtering options that allow you to customize the display of information.',
  p7: 'These filters include:',
  ol16: 'Status: Allows filtering orders by their current status.',
  ol17: 'Customer: Facilitates specific search for orders related to a particular customer. ',
  ol18: 'Order Number: Allows entering the order number to directly access the corresponding information.',
  ol19: 'Customer Ref: Filters orders according to the associated customer reference.',
  ol20: 'Dispatch Week: Facilitates searching for orders scheduled for a specific week.',
  fileDownload: 'File Download',
  p8:
    'In the top right section of the Orders Menu, you\'ll find the option to "Download Excel". Click on this option to initiate the download process.',
  p9:
    'The downloaded file will contain detailed information about the orders, similar to the summary viewed in the Orders Menu. This may include details such as order status, customer information, order number, references, dispatch weeks, item quantities, relevant dates, and other specific order details.',
  newOC: 'New Purchase Order Entry Process',
  orderDetail: 'Order Detail'
});
