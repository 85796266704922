import i18next from 'i18next';

i18next.addResources('es', 'PurchaseOrderActivity', {
  noActivity: 'No se encontraron actividades para esta orden',
  ago: 'Hace {{sinceTime}}',
  loadMore: 'Ver mas',
  changedBy: 'El usuario {{changedBy}} {{status}}',
  draft: 'Nueva orden de compra creada',
  draftAction: 'ha creado la orden de compra en el sistema',
  validationCMPC: 'Orden de compra confirmada',
  validationCMPCAction: 'ha confirmado la orden de compra en el sistema',
  validated: 'Orden de compra validada',
  validatedAction: 'ha validado la orden de compra en el sistema',
  discarded: 'Orden de compra descartada',
  discardedAction: 'ha descartado la orden de compra en el sistema'
});

i18next.addResources('en', 'PurchaseOrderActivity', {
  noActivity: 'No activities found for this order',
  loadMore: 'Load more',
  changedBy: 'The user {{changedBy}} {{status}}',
  draft: 'New purchase order created',
  draftAction: 'has created the purchase order in the system',
  validationCMPC: 'Purchase order confirmed',
  validationCMPCAction: 'has confirmed the purchase order in the system',
  validated: 'Purchase order validated',
  validatedAction: 'has validated the purchase order in the system',
  discarded: 'Purchase order discarded',
  discardedAction: 'has discarded the purchase order in the system'
});
