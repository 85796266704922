import { IFormatData, DataCsat, ModulesCsat } from 'types/Csat';
import { FacesList } from './constants';

export const formatData = (data: DataCsat[]): IFormatData[] => {
  const formatData: IFormatData[] = [];
  data.forEach(item => {
    const { moduleId, state, questions, idSurvey } = item;
    return formatData.push({
      module: Object.values({ 0: ModulesCsat.GENERAL, ...ModulesCsat })[moduleId],
      state,
      question: questions[0]?.contentQuestion[0]?.value,
      idQuestion: questions[0]?.idQuestion,
      idSurvey
    });
  });
  return formatData;
};

export const resetFaces = () => {
  FacesList.forEach(item => (item.isSelected = false));
};
