type Storage = { [index: string]: string | undefined };

const getEncodedFieldName = (key: string): string =>
  window.btoa(`@@${'cmpc-boxboard'.replace(/-/g, '_').toUpperCase()}:${key}`);

class StorageService {
  private storage: globalThis.Storage;

  private tempStorage: Storage = {};

  constructor(storage = window.localStorage) {
    this.storage = storage;
  }

  getValue(key: string): any {
    const encodedKey = getEncodedFieldName(key);
    let encodedValue = undefined;

    try {
      encodedValue = this.storage.getItem(encodedKey);
    } catch (e) {
      encodedValue = this.tempStorage[encodedKey];
    }
    const stringValue = encodedValue && window.atob(encodedValue);

    return stringValue && JSON.parse(stringValue);
  }

  setValue(key: string, value: any): void {
    const encodedKey = getEncodedFieldName(key);
    const stringValue = JSON.stringify(value);
    const encodedValue = window.btoa(stringValue);

    try {
      this.storage.setItem(encodedKey, encodedValue);
    } catch (e) {
      this.tempStorage[encodedKey] = encodedValue;
    }
  }

  removeValue(key: string): void {
    const encodedKey = getEncodedFieldName(key);
    try {
      this.storage.removeItem(encodedKey);
    } catch (e) {
      this.tempStorage[encodedKey] = undefined;
    }
  }

  handleMissingKey = (key: string) => () => !this.getValue(key) && location.reload();

  reloadOnMissingKey(key: string): void {
    window.addEventListener('storage', this.handleMissingKey(key), false);
  }

  removeMissingKeyListener(key: string): void {
    window.removeEventListener('storage', this.handleMissingKey(key), false);
  }
}

const LocalStorageService = new StorageService(window.localStorage);
const SessionStorageService = new StorageService(window.sessionStorage);

export { LocalStorageService, SessionStorageService, getEncodedFieldName };
