import i18next from 'i18next';

i18next.addResources('es', 'TrackingScreen', {
  title: 'Tracking',
  titleDetail: 'Detalle embarque',
  back: 'Volver',
  home: 'Inicio',
  trackingList: 'Listado de tracking',
  inProcess: 'En preparación',
  departure: 'Zarpe',
  inTransit: 'En tránsito a destino',
  tansshipmentPort: 'Transbordo',
  portOfDestination: 'Puerto de destino',
  containerDischange: 'Descarga de nave',
  delivery: 'Entregado',
  emptyEquipmentReturned: 'Devolución de cont.',
  completed: 'Completado',
  customers: 'Cliente',
  shipment: 'Embarque',
  container: 'N° Contenedores',
  departureDate: 'Fecha de zarpe',
  eta: 'ETA',
  planned: 'Previsto',
  viewDetails: 'Ver detalle',
  shipments: 'embarques',
  day: 'día',
  days: 'días',
  statusT: 'Estado',
  order: 'Pedido',
  dateRanges: 'Fecha de llegada',
  searchFilter: 'Filtro de búsqueda',
  clientCountry: 'País',
  market: 'Mercado',
  booking: 'Booking',
  orderBuy: 'OC referencia',
  indicateClientCountry: 'Indique país',
  indicateMarket: 'Indique mercado',
  indicateBooking: 'Indique booking',
  indicateOcReference: 'Indique OC referencia',
  indicateState: 'Indique estado',
  applyFilter: 'Aplicar filtro',
  download: 'Descargar plan de embarque'
});

i18next.addResources('en', 'TrackingScreen', {
  title: 'Tracking',
  titleDetail: 'Shipment detail',
  back: 'Return',
  home: 'Home',
  trackingList: 'Tracking List',
  inProcess: 'In process',
  departure: 'Departure',
  inTransit: 'In Transit',
  tansshipmentPort: 'Transshipment port',
  portOfDestination: 'Port of destination',
  containerDischange: 'Container dischange',
  delivery: 'Delivery',
  emptyEquipmentReturned: 'Empty equipment returned',
  completed: 'Completed',
  customers: 'Customer',
  shipment: 'Shipment',
  container: 'Container N°',
  departureDate: 'Departure Date',
  eta: 'ETA',
  planned: 'Planned',
  viewDetails: 'View details',
  shipments: 'shipment',
  day: 'day',
  days: 'days',
  statusT: 'Status',
  order: 'Order',
  dateRanges: 'Date range',
  searchFilter: 'Search filters',
  clientCountry: 'Country',
  market: 'Market',
  booking: 'Booking',
  orderBuy: 'Purchase order-invoice',
  indicateClientCountry: 'Indicate country',
  indicateMarket: 'Indicate market',
  indicateBooking: 'Indicate booking',
  indicateOcReference: 'Indicate PO-invoice',
  indicateState: 'Indicate state',
  applyFilter: 'Apply filter',
  download: 'Download shipment plan'
});

i18next.addResources('es', 'TrackingExcel', {
  unknownError: 'Ocurrió un error al descargar reporte de embarques.',
  emptyError: 'Estos embarques actualmente no tienen un despacho asociados.',
  limitError:
    'No se pueden descargar mas de 2000 embarques en formato excel, intente descargar menos embarques.',
  headers: [
    [
      'País Cliente',
      'Cod.Cliente',
      'Cliente',
      'Destinatario Mercadería',
      'Incoterms 1',
      'Incoterms 2',
      'O/Compra',
      'Pedido Origen',
      'Cantidad de pedido',
      'Entrega',
      'Pedido',
      'Posición',
      'Producto',
      'Cantidad Entrega',
      'N° de Transporte',
      'ID TrackTransp',
      'Nombre de la Nave',
      'Nombre de Transportista',
      'Fecha Ini Transporte',
      'Fecha factura',
      'Fact SII',
      'Factura SAP',
      'TN Neta Desp',
      'Departure Date',
      'ETA',
      'Arrival',
      'Planned days'
    ]
  ]
});

i18next.addResources('en', 'TrackingExcel', {
  unknownError: 'An error occurred while downloading the shipping report.',
  emptyError: 'These shipments currently do not have an associated clearance',
  limitError: 'You cannot download more than 2000 shipments in Excel format, try downloading fewer shipments',
  headers: [
    [
      'País Cliente',
      'Cod.Cliente',
      'Cliente',
      'Destinatario Mercadería',
      'Incoterms 1',
      'Incoterms 2',
      'O/Compra',
      'Pedido Origen',
      'Cantidad de pedido',
      'Entrega',
      'Pedido',
      'Posición',
      'Producto',
      'Cantidad Entrega',
      'N° de Transporte',
      'ID TrackTransp',
      'Nombre de la Nave',
      'Nombre de Transportista',
      'Fecha Ini Transporte',
      'Fecha factura',
      'Fact SII',
      'Factura SAP',
      'TN Neta Desp',
      'Departure Date',
      'ETA',
      'Arrival',
      'Planned days'
    ]
  ]
});
