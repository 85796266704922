import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions, PopupPosition } from 'reactjs-popup/dist/types';

import styles from './styles.module.scss';

interface Props {
  children: JSX.Element;
  className?: string;
  classNameContent?: string;
  content: React.ReactNode | string;
  position: PopupPosition | PopupPosition[];
  showArrow?: boolean;
}

function Tooltip({
  children,
  className = '',
  classNameContent = '',
  content,
  position,
  showArrow = false
}: Props) {
  const ref = useRef<PopupActions>(null);
  const [open, setOpen] = useState(false);
  const closeTooltip = useCallback(() => {
    ref?.current?.close();
  }, [ref]);

  useEffect(() => {
    if (open) {
      window.addEventListener('scroll', closeTooltip, true);
    }
    return () => {
      window.removeEventListener('scroll', closeTooltip, true);
    };
  }, [closeTooltip, open]);

  return (
    <Popup
      arrow={showArrow}
      ref={ref}
      className={className}
      closeOnDocumentClick
      keepTooltipInside
      on={['hover', 'click', 'focus']}
      position={position}
      trigger={() => children}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <div className={`${classNameContent} ${styles.tooltipText} messages`}>{content}</div>
    </Popup>
  );
}

export default Tooltip;
