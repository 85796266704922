import i18next from 'i18next';

i18next.addResources('es', 'Orders', {
  orders: 'Pedidos',
  ordersList: 'Listado de pedidos',
  orderDetail: 'Detalle de pedido',
  purchaseOrder: 'Nueva OC',
  warnPartialContent:
    'No se pueden descargar más de 2000 pedidos en formato excel. Intente descargar menos pedidos.',
  errorReport: 'Ha ocurrido un error al generar el archivo. Intente de nuevo más tarde',
  downloadOptions: 'Opciones de descarga',
  itemDownload: 'Descargar por item',
  orderDownload: 'Descargar por pedido'
});

i18next.addResources('en', 'Orders', {
  orders: 'Orders',
  ordersList: 'Orders List',
  orderDetail: 'Order detail',
  purchaseOrder: 'New PO',
  warnPartialContent:
    'You cannot download more than 2000 orders in excel format. Try to download fewer orders.',
  errorReport: 'An error occurred while generating the file. Please try again later',
  downloadOptions: 'Download options',
  itemDownload: 'Download by item',
  orderDownload: 'Download by order'
});

i18next.addResources('es', 'Excel', {
  headers: [
    [
      'Cliente',
      'Consignatario',
      'Orden ID',
      'Pais',
      'Ref Client',
      'Semana despacho',
      'Año despacho',
      'Fecha despacho desde',
      'Fecha despacho hasta',
      'ETA',
      'Semana ETA',
      'Año ETA',
      'Semana ETA desde',
      'Semana ETA hasta',
      'Items',
      'Unidades',
      'Peso',
      'Requerimiento',
      'Fecha Ingreso',
      'Ciclo',
      'Consignatario ID',
      'Origen',
      'Destino',
      'Mercado',
      'Estatus',
      'Negocio',
      'Agente OC',
      'Rollos',
      'Pilas',
      'Fecha Ready'
    ]
  ]
});

i18next.addResources('en', 'Excel', {
  headers: [
    [
      'Client',
      'Consignee',
      'Order ID',
      'Country Name',
      'Ref Client',
      'Dispatch Week',
      'Dispatch Year',
      'Dispatch Date From',
      'Dispatch Date To',
      'ETA',
      'ETA Week',
      'ETA Year',
      'ETA Week From',
      'Eta Week To',
      'Items',
      'Units',
      'Weight',
      'Requirement',
      'Entry date',
      'Cycle',
      'Consignee ID',
      'Origin',
      'Destination',
      'Continent Market',
      'Status',
      'Negocio',
      'OC Agent',
      'Rolls',
      'Stacks',
      'Ready Date'
    ]
  ]
});

i18next.addResources('es', 'ModalOver2k', {
  title: 'Confirmación de descarga',
  desc: 'Este informe contiene mas de 2.000 registros.',
  subtitle: 'Para descargar el listado de pedidos, presiona confirmar y ',
  boldText: 'te lo enviaremos por correo en formato excel.',
  delayText: 'Esto puede tardar unos unos minutos.',
  wishConfirm: '¿Deseas confirmar?',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  snackSuccess: '¡Informe excel enviado con éxito!'
});
i18next.addResources('en', 'ModalOver2k', {
  title: 'Download confirmation',
  desc: 'This report contains more than 2.000 records.',
  subtitle: 'To download the list of orders, press confirm and ',
  boldText: 'we will send it to you by mail as an Excel file.',
  delayText: 'This may take a couple of minutes.',
  wishConfirm: 'Do you wish to confirm?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  snackSuccess: 'Excel report successfully sent!'
});
