import Content from '../assets/content.svg';
import ContentSelected from '../assets/content-selected.svg';
import VeryUnsatisfied from '../assets/very-unsatisfied.svg';
import VeryUnsatisfiedSelected from '../assets/very-unsatisfied-selected.svg';
import Unsatisfied from '../assets/unsatisfied.svg';
import UnsatisfiesSelected from '../assets/unsatisfied-selected.svg';
import Satisfied from '../assets/satisfied.svg';
import SatisfiedSelected from '../assets/satisfied-selected.svg';
import VerySatisfied from '../assets/very-satisfied.svg';
import VerySatisfiedSelected from '../assets/very-satisfied-selected.svg';

interface IFaceList {
  face: string;
  selected: string;
  isSelected: boolean;
}

export const FacesList: IFaceList[] = [
  { face: VeryUnsatisfied, selected: VeryUnsatisfiedSelected, isSelected: false },
  { face: Unsatisfied, selected: UnsatisfiesSelected, isSelected: false },
  { face: Content, selected: ContentSelected, isSelected: false },
  { face: Satisfied, selected: SatisfiedSelected, isSelected: false },
  { face: VerySatisfied, selected: VerySatisfiedSelected, isSelected: false }
];

export const INIT_STATE = [VeryUnsatisfied, Unsatisfied, Content, Satisfied, VerySatisfied];
