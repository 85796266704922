import React from 'react';
import cn from 'classnames';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';

import styles from './styles.module.scss';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  showComplete: boolean;
  sidebarIsOpen: boolean;
  sidebarMobileIsOpen: boolean;
  username: string;
}

function SidebarArrow({ onClick, showComplete, sidebarIsOpen, sidebarMobileIsOpen, username }: Props) {
  const sidebarArrowComplete = showComplete || sidebarIsOpen || sidebarMobileIsOpen;
  return (
    <div
      className={cn('row space-between middle', {
        [styles.sidebarArrow]: sidebarArrowComplete
      })}
    >
      <p
        className={cn('text bold', [styles.arrowText], {
          [styles.arrowTextHover]: sidebarArrowComplete
        })}
      >
        {username}
      </p>
      {!sidebarMobileIsOpen && (
        <button
          type="button"
          onClick={onClick}
          data-testid="sidebar-arrow"
          className={cn([styles.arrow], { [styles.arrowHover]: showComplete || sidebarIsOpen })}
        >
          <Arrow className={cn([styles.arrowIcon], { [styles.arrowClicked]: sidebarIsOpen })} />
        </button>
      )}
    </div>
  );
}

export default SidebarArrow;
