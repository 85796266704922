import i18next from 'i18next';

i18next.addResources('es', 'Pagination', {
  showing: 'Mostrando',
  of: 'de',
  items: 'items',
  orders: 'pedidos'
});

i18next.addResources('en', 'Pagination', {
  showing: 'Showing',
  of: 'of',
  items: 'items',
  orders: 'orders'
});
