import i18next from 'i18next';

i18next.addResources('es', 'UserList', {
  users: 'Usuarios',
  listOfUsers: 'Listado de usuarios',
  createUser: 'Crear usuario',
  placeholderSearch: 'Buscar por cliente, agente, nombre de usuario o correo'
});

i18next.addResources('en', 'UserList', {
  users: 'Users',
  listOfUsers: 'List of users',
  createUser: 'Create user',
  placeholderSearch: 'Search by client, agent, username or mail'
});
